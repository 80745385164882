import React from 'react';
import UserTeamSelector from '../../../../components/Common/UserTeamSelector';
import { useCollaborations } from '../../../../components/hooks/collaborator';
import { useLoadingStatus } from '../../../../components/hooks/loading';
import {
  ICollaboratorContext,
  ICollaboratorScopeType
} from '../../../clientSideServices/collaboration';

const CollaboratorSelector = ({
  itemId,
  owner,
  context,
  sidePanelMode,
  readOnly
}: {
  itemId: string;
  owner: string;
  context: ICollaboratorContext;
  sidePanelMode?: boolean;
  readOnly?: boolean;
}) => {
  const { isLoading } = useLoadingStatus();
  const {
    collaborators,
    getCollaborationsInfo,
    addCollaborator,
    removeCollaborator,
    getAllUsersAndTeams,
    viewOnly
  } = useCollaborations({ itemId, context, owner });

  const collaborationsInfo = getCollaborationsInfo();
  const allUsersAndTeams = getAllUsersAndTeams();

  const loading = isLoading(`usersTeams-${itemId}`);
  return (
    <div className="w-100 CollaboratorSelector">
      {sidePanelMode ? (
        <p>Collaborators</p>
      ) : (
        <h2>
          Collaborators
          {collaborators.length ? ` (${collaborators.length})` : ''}
        </h2>
      )}

      <UserTeamSelector
        fontSize={sidePanelMode ? '12px' : '14px'}
        loading={loading}
        excludeOptions={[owner]}
        allUsersTeams={allUsersAndTeams}
        usersTeams={collaborationsInfo}
        viewOnly={viewOnly || readOnly}
        onSelect={(item) =>
          addCollaborator(item.context as ICollaboratorScopeType, item.id)
        }
        onDelete={(item) =>
          removeCollaborator(item.context as ICollaboratorScopeType, [item.id])
        }
        maxHeight={sidePanelMode ? undefined : '318px'}
        height={sidePanelMode ? undefined : '318px'}
        emptyPlaceHolder={sidePanelMode ? '-' : undefined}
      />
      <style jsx>{`
        h2 {
          text-align: center;
          padding: 0.8em 0;
        }
        .CollaboratorSelector p{
          font-size: 12px;
          margin-bottom: 9px;
          margin-top: 20px;
        }
      `}</style>
    </div>
  );
};

export default CollaboratorSelector;
