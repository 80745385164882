import React from 'react';
import { useSelector } from 'react-redux';
import { isUserOnMobile } from '../../utils/deviceDetector';
import { PageView } from '../../utils/pageView';
import { isEmbeddedInStreamingStudio } from '../../utils/streamingstudio';
import PopupPage from './../../components/PopupPage';
import { rduBrandId } from './../../config/index';
import {
  AssetLibraryPopupState,
  IMainState,
  IPopupState
} from './../../interfaces/index';
import { AssetLibrarySelectPopup } from './AssetLibrary/AssetLibrarySelectPopup';
import { mobileBreakPoint } from './BrandStyle';
import { HubBrandStyle } from './BrandStyle/hubBrandStyle';
import CollaboratorPopup from './Common/Collaborators/CollaboratorPopup';
import Header from './Header';
import { HubContext } from './HubContext';
interface ILayoutProps {
  children: React.ReactNode;
  className?: string;
  theme?: 'dark' | 'light' | 'transparent' | 'dark-light';
  showNav?: boolean;
  hideMenu?: boolean;
}

const Layout: React.FC<ILayoutProps> = ({
  children,
  className = '',
  theme = 'dark',
  showNav = true,
  hideMenu = false
}: ILayoutProps) => {
  const { brandId, color, page, isEmbeddedInSsp, brandSetup } =
    React.useContext(HubContext);
  const popupState: IPopupState | undefined = useSelector(
    (state: IMainState) => state.clientState.vb?.popup
  );
  const [isOnMobile, setIsOnMobile] = React.useState(false);
  const [keyboardActive, setKeyBoardActive] = React.useState(false);
  const isContactPage =
    page === PageView.HUB_CONTACT_CENTER || page === PageView.HUB_CHAT_DETAILS;
  const isChatDetailPage = page === PageView.HUB_CHAT_DETAILS;
  const assetLibPopupState: AssetLibraryPopupState = useSelector(
    (state: IMainState) => state.clientState.hub?.assetLibrary?.popup
  );
  React.useEffect(() => {
    let checkKeyboard;
    setIsOnMobile(isUserOnMobile());
    if ((isUserOnMobile() || isEmbeddedInStreamingStudio()) && !isContactPage) {
      checkKeyboard = setInterval(() => {
        const activeElement = document.activeElement.tagName?.toLowerCase();
        if (
          activeElement === 'input' ||
          activeElement === 'select' ||
          activeElement === 'textarea'
        ) {
          setKeyBoardActive(true);
        } else {
          setKeyBoardActive(false);
        }
      }, 300);
    }
    () => {
      if (checkKeyboard) {
        clearInterval(checkKeyboard);
        setKeyBoardActive(false);
      }
    };
  }, []);

  return (
    <div
      id="hub-layout"
      className={`advisor-hub layout ${className} ${
        isOnMobile ? 'touch' : ''
      } ${isEmbeddedInSsp ? 'ssp' : ''} ${keyboardActive ? 'kbActive' : ''}`}
    >
      <Header
        theme={theme === 'dark-light' ? 'dark' : theme}
        brandId={brandId}
        showNav={showNav}
        hideMenu={hideMenu}
        brandSetup={brandSetup}
      />
      <div className={`content-wrapper ${isContactPage && 'd-flex'}`}>
        {children}
        <CollaboratorPopup />
        {!!assetLibPopupState && <AssetLibrarySelectPopup />}
      </div>
      {popupState?.open && <PopupPage />}
      <style jsx>{`
        .layout {
          width: 100%;
          min-height:100%;
          height:100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background:${theme === 'dark' ? color.dark : color.light};
          color:${theme === 'dark' ? color.light : color.dark};
        }
        .ssp{
          font-size:20px;
        }

        .layout :global(::-webkit-scrollbar){width: 3px;background:transparent}
        .layout :global(::-webkit-scrollbar-track){background:transparent}
        .layout :global(::-webkit-scrollbar-thumb ){background: ${
          theme === 'dark' ? '#444' : '#ccc'
        };
        }


        :global(.btn-accent){
          background:${color.btnPrimary};
          color:${color.btnPrimaryText};
        }
        .content-wrapper {
          padding:0;
          background:transparent;
          width: 100%;
          height:100%;
          overflow:visible;
          position: relative;
          overflow:auto;
        }
        .layout :global(.header),
        .layout :global(.footer) {
          width: 100%;
        }
        .layout :global(h1) {
          font-size: 1.5em;
          margin-bottom: 30px;
          text-align: center;
        }


        .layout :global(.shadow-box){
          box-shadow: 0 0 5px rgba(0,0,0,0.2);
        }

        .layout :global(.light-border){
          border:1px solid #ebebeb;
        }

        .layout :global(.text-semibold){
          font-weight:500;
        }
        
      

        .layout :global(p a) {
          text-decoration:underline;
          color:inherit;
        }

        .layout :global(.text-accent){
          text-transform:uppercase;
          color ${color.accent}
        }

        .layout :global(h1 span) {
          display: block;
        }

        .layout :global(h1 .separator) {
          height: 2px;
          width: 150px;
          background: ${color.accent};
          display: block;
          margin: 10px auto;
        }

        .content-wrapper{
          padding-top: 20px;
        }

        @media (orientation: landscape) and (max-height: 376px) {
          .layout > :global(.header) {
            display: ${isChatDetailPage ? 'none' : 'flex'}
          }

          .layout > .content-wrapper {
            min-height: 450px;
          }
        }

        .kbActive .content-wrapper{
          padding-top:0;
        }
        .layout :global(.page-body) {
          padding: 0 20px 50px;
          width: 100%;
          text-align:center;
          margin:auto;
        }

        .layout :global(.btn-dark) {
          border: 1px solid rgba(255,255,255,.5);
          background: ${color.dark};
        }

        .layout :global(.btn-dark.active) {
          border: 1px solid rgba(255,255,255,.5);
          background:#666;
        }

        .layout :global(td .btn) {
          height: 100%;
        }

        .layout :global(td) {
          padding: 10px;
          text-align: left;
          border: none;
          background: ${color.grey};
          vertical-align: middle;
        }
        .layout :global(td:last-child) {
          text-align: right;
        }

        .layout :global(.td-btn) {
          padding: 0;
          white-space: nowrap;
          width: 1%;
          position: relative;
        }

        .layout :global(.td-btn .btn-group) {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }

        .layout :global(.td-dark) {
          background: ${color.dark};
          color: #fff;
          padding: 0 10px;
          text-align: center;
          width: auto;
          white-space: nowrap;
          width: 1%;
        }

        .layout :global(.td-main) {
          font-weight: normal;
        }
        .layout :global(.text-grey) {
          font-style: italic;
          color: #777;
          font-weight: 300;
        }
        .layout :global(.td-space) {
          height: 10px;
        }
        .layout :global(tr:nth-child(2n + 2) td) {
          background: ${color.light};
        }
        .layout :global(tr:nth-child(4n - 1) td) {
          background: ${color.light};
        }

        .layout :global(.hide-m) {
          display: none !important;
        }

        .layout :global(.btn-control) {
          position: fixed;
          bottom: 30px;
          left: 0;
          right: 0;
          text-align: center;
        }

        .layout :global(.btn-control .btn) {
          letter-spacing: 0.4px;
        }

        .layout :global(.btn-group .btn-round) {
          border-radius: 0;
        }



        .layout :global(.btn-group .btn-round:first-child) {
          border-radius: 100px 0 0 100px;
        }

        .layout :global(.btn-group .btn-round:last-child) {
          border-radius: 0 100px 100px 0;
        }

        .layout :global(.btn-round) {
          border-radius: 100px;
          border: 1px solid rgba(0, 0, 0, 0.5);
          font-weight:500;
        }

        .layout :global(.input-round){
          border-radius:100px;
        }

        .layout :global(.btn-success){
          border-color:#28a746;
        }
        .layout :global(.text-success){
          color:#28a746;
        }
        .layout :global(.btn-danger){
          border-color:#bc2433;
        }
        .layout :global(.text-danger){
          color:#bc2433;
        }

        .layout:global(.btn-shadow){
          box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
        }

        .layout :global(.tr-item td) {
          border-color: ${color.grey};
          border-width:1px 0;
          border-style:solid;
        }

    

        .layout :global(.tr-item td:first-child) {
          border-width:1px 0 1px 1px;
        }

        .layout :global(.tr-item td:last-child) {
          border-width:1px 1px 1px 0;
        }

        @media (min-width: ${mobileBreakPoint}px) {
          .layout :global(h1) {
            font-weight: bold;
            font-size: 1.8em;
            letter-spacing: 2px;
            position: relative;
            margin-bottom: 50px;
          }
      
          .layout :global(h1 span) {
            display: inline-block;
          }
          .layout :global(.show-m) {
            display: none !important;
          }

          .layout :global(.hide-m) {
            display: block !important;
          }
          .layout :global(td.hide-m) {
            display: table-cell !important;
          }

          .layout :global(.btn-control) {
            bottom: 100px;
          }

          .layout :global(.btn-control .btn) {
            letter-spacing: 1px;
          }

          .layout :global(.page-body) {
            padding-top:50px;
          }
          .ssp :global(.page-body) {
            padding-top:80px;
          }
          .kbActive :global(.page-body) {
            padding-top:0;
          }

          .ssp :global(input:not(.ssp-ignore)),
          .ssp :global(textarea:not(.ssp-ignore)),
          .ssp :global(select:not(.ssp-ignore)),
          .ssp :global(.btn-control .btn:not(.ssp-ignore)){
            font-size:1.4em;
          }
          .ssp :global(.btn-sm:not(.ssp-ignore)){
            font-size:1.2em;
          }

          .ssp :global(.btn-lg:not(.ssp-ignore)){
            font-size:1.5em;
          }
        }
      `}</style>
      <style jsx global>{`
        html,
        body,
        #__next,
        #__next > div {
          height: 100vh;
        }
        .react-datepicker-popper {
          z-index: 5;
        }
        .form-control,
        .form-control:focus,
        .form-control:active,
        .btn,
        .btn:focus,
        .btn:active {
          outline: none;
          box-shadow: none;
        }
        .form-control:focus {
          border-color: inherit;
        }
        @media (max-width: 769px) {
          .popup-page-content .close.dark {
            color: ${brandId === rduBrandId ? '#eaeaea' : '#000'} !important;
          }
        }
      `}</style>
      <HubBrandStyle brandId={brandId} />
    </div>
  );
};

export default Layout;
