import React, { useEffect, useState } from 'react';
import PopupContainer from '../Common/PopupContainer';
import { SelectedAsset } from './SelectedAsset';
import { IAsset, IMainState } from '../../../interfaces';
import {
  ArrowPreviousIcon,
  ArrowNextIcon,
  IconOutlineArrow,
  CloseIcon
} from '../Common/HubIcons';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { debounce } from 'lodash';
import hotkeys from 'hotkeys-js';
import ImageWithWebp from '../Common/ImageWithWebp';

export const AssetPopupPreview: React.FC<{
  open: boolean;
  updatable?: boolean;
  onRequestClose?: () => void;
  selectedAssets?: string[];
  screen?: {
    screenSize: 'sm' | 'xs' | 'md' | 'lg' | 'xl';
    screenSizeLevel: number;
  };
  selectAsset?: (asset: IAsset, forceAdd?: boolean, multiple?: boolean) => void;
}> = ({
  open,
  onRequestClose,
  selectAsset,
  screen,
  selectedAssets,
  updatable = true
}) => {
  const assets = useSelector(
    (state: IMainState) => state.clientState.hub?.assetLibrary?.assets
  );
  const [previewAsset, setPreviewAsset] = useState<IAsset>();
  const [indexPreviewAsset, setIndexPreviewAsset] = useState<number>();
  const [showRightSidebar, setShowRightSidebar] = useState(true);
  const [wheel, setWheel] = useState(false);
  const [scale, setScale] = useState<number>(0);
  const [position, setPosition] = useState<{
    positionX: number;
    positionY: number;
  }>({ positionX: 0, positionY: 0 });

  hotkeys.setScope('asset-preview-popup');

  // loading font
  useEffect(() => {
    const loadFont = (name: string, url: string) => {
      const newStyle = document.createElement('style');
      newStyle.appendChild(
        document.createTextNode(
          '@font-face{font-family: "' + name + '"; src: url(' + url + ');}'
        )
      );
      document.body.appendChild(newStyle);
    };
    if (!previewAsset?.assetType?.includes('font')) return;
    loadFont(
      previewAsset?.title,
      previewAsset?.convertedURL
    );
  }, [previewAsset]);

  useEffect(() => {
    if (!selectedAssets?.length) return;
    setPreviewAsset(
      assets?.data.find(
        (asset) => asset.id === selectedAssets[selectedAssets.length - 1]
      )
    );
    setIndexPreviewAsset(
      assets?.data?.findIndex(
        (asset) => asset.id === selectedAssets[selectedAssets.length - 1]
      )
    );
    setScale(1);
    setPosition({ positionX: 0, positionY: 0 });
    setWheel(false);
  }, [selectedAssets]);

  useEffect(() => {
    if (screen?.screenSizeLevel <= 1) {
      setShowRightSidebar(false);
    } else {
      setShowRightSidebar(true);
    }
  }, [screen?.screenSizeLevel]);

  const onZoomChange = (e: any) => {
    if (e.scale === 1) setWheel(false);
    debounce(() => {
      setScale(e.scale);
      setPosition({ positionX: e.positionX, positionY: e.positionY });
    }, 20);
  };

  useEffect(() => {
    hotkeys('left,right','asset-preview-popup',  (e, handler) => {
      switch (handler.key) {
        case 'right': {
          e.preventDefault();
          if (indexPreviewAsset + 1 < assets.data.length) onNextAsset();
          break;
        }
        case 'left': {
          e.preventDefault();
          if (indexPreviewAsset > 0) onPreviousAsset();
          break;
        }
      }
    });
   
    return () => {
      hotkeys.unbind('left,right');
    }; 
  }, [indexPreviewAsset, assets?.data?.length])

  const onPreviousAsset = () => {
    setPosition({ positionX: 0, positionY: 0 });
    setScale(1);
    if (indexPreviewAsset > 0) {
      setIndexPreviewAsset((prev) => prev - 1);
      const previous = assets.data?.[indexPreviewAsset - 1];
      selectAsset(previous, true);
      setPreviewAsset(assets.data?.[indexPreviewAsset - 1]);
    }
  }

  const onNextAsset = () => {
    setPosition({ positionX: 0, positionY: 0 });
    setScale(1);
    if (indexPreviewAsset < assets.data.length - 1) {
      setIndexPreviewAsset((prev) => prev + 1);
      const nextAsset = assets.data?.[indexPreviewAsset + 1];
      selectAsset(nextAsset, true);
      setPreviewAsset(assets.data?.[indexPreviewAsset + 1]);
    }
  }

  if (!open) {
    return null;
  }

  return (
    <PopupContainer zIndex={9999} className="overlay-popup">
      <div
        className={`content h-100 position-relative ${
          !showRightSidebar ? 'content-medium-screen' : 'content-full-screen'
        }`}
      >
        <div className="close-icon position-absolute" onClick={onRequestClose}>
          <CloseIcon />
        </div>
        <div
          className={`position-absolute btn btn-previous-asset px-2 py-2 ${
            !indexPreviewAsset ? 'd-none' : ''
          }`}
          onClick={onPreviousAsset}
        >
          <ArrowPreviousIcon />
        </div>
        <div
          className={`position-absolute btn btn-next-asset px-2 py-2 ${
            indexPreviewAsset === assets.data.length - 1 ? 'd-none' : ''
          }`}
          onClick={onNextAsset}
        >
          <ArrowNextIcon />
        </div>
        <div
          className="block__cover-asset d-flex justify-content-center align-items-center h-100 fade-in"
          key={previewAsset?.id}
        >
          {previewAsset?.assetType.includes('font') && (
            <div
              style={{
                fontFamily: `"${previewAsset?.title}"`
              }}
              className="wrapper-font-text d-flex justify-content-center w-100"
            >
              <span className="w-100">
                ABCDEFGHIJKLMNOPQRSTUVWXYZ<br></br>abcdefghijklmnopqrstuvwxyz
                <br></br>1234567890
              </span>
            </div>
          )}
          {previewAsset?.assetType.includes('image') && (
            <div className="img-preview">
              <TransformWrapper
                doubleClick={{ disabled: true }}
                wheel={{ step: 2 }}
                onZoomChange={onZoomChange}
                zoomIn={{ animation: true }}
                positionX={position.positionX}
                positionY={position.positionY}
                scale={scale}
                onWheel={() => {
                  setWheel(true);
                }}
              >
                <TransformComponent>
                  <ImageWithWebp
                    src={previewAsset?.assetPreviewURL}
                    alt="asset image"
                    style={{
                      maxHeight: '100%',
                      maxWidth: '100%',
                      width: 'auto',
                      height: 'auto',
                      margin: 'auto'
                    }}
                  />
                </TransformComponent>
              </TransformWrapper>
            </div>
          )}

          {previewAsset?.assetType.includes('video') && (
            <ReactPlayer
              controls
              url={previewAsset?.convertedURL}
              muted={false}
              width="100%"
              height="auto"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '100%',
                maxHeight: '100%'
              }}
              className="react-player"
            />
          )}
        </div>
      </div>
      {updatable &&
        (showRightSidebar ? (
          <div
            className={`controls position-relative ${
              screen?.screenSizeLevel <= 1 ? 'pl-3' : ''
            }`}
          >
            {screen?.screenSizeLevel <= 1 && (
              <div
                className="position-absolute icon-close-sidebar-file-info d-flex justify-content-center align-items-center"
                style={{ left: 6 }}
                onClick={() => {
                  setShowRightSidebar(false);
                }}
              >
                <IconOutlineArrow direction="right" />
              </div>
            )}
            <SelectedAsset
              isPreview
              previewAsset={previewAsset}
              setPreviewAsset={setPreviewAsset}
              onRequestClose={onRequestClose}
            />
          </div>
        ) : (
          <div className="controls position-relative" style={{ width: 35 }}>
            <div
              className="position-absolute icon-close-sidebar-file-info d-flex justify-content-center align-items-center"
              style={{ left: 6 }}
              onClick={() => {
                setShowRightSidebar(true);
              }}
            >
              <IconOutlineArrow direction="left" />
            </div>
          </div>
        ))}

      <style jsx global>{`
        .overlay-popup.PopupContainer {
          padding-top: 0 !important;
        }
        .header {
          z-index: 1 !important;
        }
        .overlay-popup.PopupContainer .content-container {
          background: unset;
          width: 100vw;
          height: 100vh;
          padding: 0;
          max-width: unset;
        }
        .overlay-popup.PopupContainer .content-main {
          height: 100vh;
          max-height: 100vh;
          display: flex;
          padding: 0;
        }
      `}</style>
      <style jsx>{`
        .content {
          width: ${updatable ? 'calc(100% - 400px)' : '100%'};
        }
        .controls {
          background: #ffffff;
          width: 400px;
          height: 100%;
        }
        .close-icon {
          cursor: pointer;
          right: 20px;
          top: 20px;
        }
        .btn {
          top: 50%;
          bottom: 50%;
          width: 60px;
          height: 60px;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          transition: 0.6s;
        }
        .btn:hover {
          background: #9a9292;
        }
        .btn img {
          width: 34px;
          height: 34px;
        }
        .btn-previous-asset {
          left: 40px;
        }
        .btn-next-asset {
          right: -20px;
        }
        .close-icon :global(svg) {
          width: 32px;
          height: 32px;
        }
        .block__cover-asset {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: ${wheel ? '0 70px' : '0 200px'};
          padding: 100px 0px;
          overflow: hidden;
        }
        :global(.block__cover-asset img) {
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
        .content .wrapper-font-text span {
          font-weight: 700;
          font-size: 50px;
          line-height: 61px;
          color: #ffffff;
          word-wrap: break-word;
        }
        .img-preview,
        .img-preview :global(.react-transform-component),
        .img-preview :global(.react-transform-element) {
          width: 100%;
          height: 100%;
        }
        @media screen and (max-width: 1250px) {
          .block__cover-asset {
            margin: 0 80px;
          }
        }
        @media screen and (max-width: 900px) {
          .block__cover-asset {
            margin: 0 70px;
          }
          .btn-previous-asset {
            left: 30px;
          }
          .btn-next-asset {
            right: -30px;
          }
          .content .wrapper-font-text span {
            font-size: 30px;
            line-height: 40px;
          }
        }
        @media screen and (max-width: 768px) {
          .content-medium-screen {
            width: calc(100% - 35px);
          }
          .content-full-screen {
            width: 100%;
          }
          .controls {
            position: absolute !important;
            top: 0;
            right: 0;
          }
        }
        @media screen and (max-width: 576px) {
          .controls {
            width: 100%;
          }
          .block__cover-asset {
            margin: 0 45px;
          }
          .content .wrapper-font-text span {
            font-size: 25px;
            line-height: 35px;
          }
          .close-icon {
            right: 15px;
            top: 30%;
          }
        }
        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        .fade-in {
          animation-name: fade-in;
          animation-duration: 0.5s;
          animation-iteration-count: 1;
        }
        .icon-open-sidebar-file-info,
        .icon-close-sidebar-file-info {
          width: 25px;
          height: 25px;
          top: 15px;
        }
        .block__cover-asset .overlay-preview-image > div {
          overflow: auto !important;
        }
      `}</style>
    </PopupContainer>
  );
};
