import { storybookAnalyticsBaseUrl } from './../../config';
import { uniqBy } from 'lodash';
import httpClient from '../../../clientSideServices/http';
import {
  IStorybook,
  IStorybookAnalyticsUserViewsData,
  IStorybookPageAnalyticsData,
  ITagsVisibilityPayload
} from '../../../interfaces';
import {
  IStorybookPayload,
  mapLatestSceneToStorybookPage,
  mapResponseToStorybook,
  mapStorybookFilesResponseToStorybook
} from '../../../mappers/storybook';
import {
  getAccessTypeFromStorybookStatus,
  skipGetStorybookEditor,
  StorybookAccessType
} from '../../utils/storybook';
import { apigClient } from '../aws';
import { getEditorJsonAsync } from '../library';
import { appendPolotnoJsonIntoStorybook } from '../../../mappers/polotno';
import { getSceneByIdForCheck as getSceneByIdForCheckServerSide } from '../../../services/scene';
import { getSceneByIdForCheck as getSceneByIdForCheckClientSide } from '../../../clientSideServices/scene';

export const updateWithLatestScene = async (
  storybook: IStorybook,
  indexs?: number[]
): Promise<IStorybook> => {
  const storybookPages = storybook.pages;
  if (!storybookPages || !storybookPages.length) {
    return storybook;
  }

  const scenePages = storybookPages.filter((page) => page.pageType === 'scene');
  const pageIds = !indexs
    ? scenePages.map((page) => page.id)
    : scenePages
        .filter((_, idx) => indexs.includes(idx))
        .map((page) => page.id);
  const isOnServer = typeof window === 'undefined';
  let latestScenes = [];

  if (isOnServer) {
    latestScenes = await Promise.all(
      pageIds.map(async (id) => {
        try {
          const scene = await Promise.resolve(
            getSceneByIdForCheckServerSide(id)
          );
          return scene;
        } catch (error) {
          return null;
        }
      })
    );
  } else {
    const result = await Promise.all(
      pageIds.map(async (id) => {
        try {
          const scene = await Promise.resolve(
            getSceneByIdForCheckClientSide(id)
          );
          return scene;
        } catch (error) {
          return null;
        }
      })
    );
    latestScenes = result.map((res) => res?.data);
  }

  latestScenes
    .filter((scene) => !!scene)
    .forEach((scene) => {
      const updatedIndex = storybookPages.findIndex(
        (page) => page.id === scene.id
      );
      if (updatedIndex !== -1) {
        storybookPages[updatedIndex] = mapLatestSceneToStorybookPage(
          scene,
          storybookPages[updatedIndex]
        );
      }
    });

  return { ...storybook, pages: storybookPages };
};

export type StorybookResponse<T extends string | string[]> = T extends string
  ? IStorybook
  : IStorybook[];

export interface IStorybooksSearchParam {
  userId: string;
  brandId?: string;
  keywords?: string;
  startIndex?: number;
  teamIds?: string[];
  excludeMine?: boolean;
}

export const searchForActivatedAndPublishedStorybooks = async ({
  brandId,
  userId,
  keywords,
  startIndex,
  teamIds,
  excludeMine
}: IStorybooksSearchParam): Promise<{
  storybooks: IStorybook[];
  totalCount: number;
}> => {
  const client = await apigClient();
  const path = `/storybooksearch/v1/activated`;
  const hasTeam = teamIds?.length > 0;
  const teamsQuery = hasTeam
    ? ` (and visibility: 'team' (or ${teamIds
        .map((id) => `visibility_scope: '${id}'`)
        .join(' ')}))`
    : ` (and visibility: 'team' visibility_scope: 'none')`;

  const result = await client.invokeApi(
    {},
    path,
    'POST',
    {},
    {
      keywords: keywords || '',
      size: 10,
      startIndex: startIndex || 0,
      queryString: excludeMine
        ? `(and (not createdby: '${userId}') brand: '${brandId}'${teamsQuery})`
        : `(or (and createdby: '${userId}' brand: '${brandId}')${teamsQuery})`
    }
  );
  const storybooks = (result.data?.storybooks || []).map(
    mapResponseToStorybook
  );
  return {
    storybooks,
    totalCount: result.data?.totalCount
  };
};

export const searchForStorybooks = async ({
  brandId,
  userId,
  keywords,
  startIndex
}: IStorybooksSearchParam): Promise<{
  storybooks: IStorybook[];
  totalCount: number;
}> => {
  const client = await apigClient();
  const path = `/storybooksearch/v1`;
  const result = await client.invokeApi(
    {},
    path,
    'POST',
    {},
    {
      keywords: keywords || '',
      size: 10,
      startIndex: startIndex || 0,
      queryString: `(or (and createdby: '${userId}' brand: '${brandId}'))`
    }
  );
  const storybooks = (result.data?.storybooks || []).map(
    mapResponseToStorybook
  );
  return {
    storybooks,
    totalCount: result.data?.totalCount
  };
};

export const updateStorybookAttributes = async (
  body: ITagsVisibilityPayload
) => {
  const client = await apigClient();
  const path = `/storybooks/v2/attributes`;
  return await client.invokeApi({}, path, 'POST', {}, body);
};

export const transferStorybookOwnership = async (
  storybook: IStorybook,
  newOwnerId: string
) => {
  const client = await apigClient();
  const path = `/storybooks/v2/attributes`;
  const body = {
    id: storybook.id,
    tags: storybook.tags,
    visibility: storybook.visibility,
    visibilityScope: storybook.visibilityScope,
    createdBy: newOwnerId
  };
  return await client.invokeApi({}, path, 'POST', {}, body);
};

export const getActivatedStorybooksByUserId = async (
  userId: string
): Promise<IStorybook[]> => {
  const client = await apigClient();
  const path = `/storybooks/v1/activated/byUserId`;
  const result = await client.invokeApi({}, path, 'POST', {}, { userId });
  return uniqBy(
    (result.data || []).map(mapResponseToStorybook),
    'id'
  ) as IStorybook[];
};

const storybookById = async (
  id: string,
  latestActivated?: boolean,
  noEditor?: boolean
): Promise<StorybookResponse<string>> => {
  const client = await apigClient();

  const pathToAllVersion = `/storybooks/v1/${id}`;
  const pathToOnlyActivated = `/storybooks/v1/activated/${id}`;
  const path = latestActivated ? pathToOnlyActivated : pathToAllVersion;

  const result = await client.invokeApi({}, path, 'GET', {});

  const withUpdatedScenes = await updateWithLatestScene(result.data || {});

  const mapped = mapResponseToStorybook(withUpdatedScenes);

  if (skipGetStorybookEditor(mapped) || noEditor) {
    return mapped;
  }

  try {
    const editor = await getEditorJsonAsync({
      id: mapped.id,
      version: mapped.version
    });
    return appendPolotnoJsonIntoStorybook(mapped, editor);
  } catch (e) {
    return mapped;
  }
};

export const getStorybookById = async (id: string): Promise<IStorybook> =>
  await storybookById(id, false);

export const getActivatedStorybookById = async (
  id: string,
  noEditor?: boolean
): Promise<IStorybook> => await storybookById(id, true, noEditor);

export const getActivatedStorybookByIds = async (
  id: string[]
): Promise<IStorybook[]> => {
  const storybooks = [];
  for (const i of id) {
    try {
      const storybook = await storybookById(i, true);
      storybooks.push(storybook);
    } catch (e) {
      storybooks.push({ id: i, status: 'deleted' });
    }
  }
  return storybooks;
};

export const getStorybookByIdAndVersion = async (arg: {
  id: string;
  version: number;
}) => {
  const client = await apigClient();
  const path = `/storybooks/v1/${arg.id}`;
  const additionalParams = {
    queryParams: {
      version: arg.version
    }
  };
  const result = await client.invokeApi({}, path, 'GET', additionalParams);
  const mapped = mapResponseToStorybook(result.data || []);

  if (skipGetStorybookEditor(mapped)) {
    return mapped;
  }

  try {
    const editor = await getEditorJsonAsync({
      id: mapped.id,
      version: mapped.version
    });

    return appendPolotnoJsonIntoStorybook(mapped, editor);
  } catch (e) {
    return mapped;
  }
};

export const getStorybookFiles = async ({
  keyId
}: {
  keyId: string;
}): Promise<IStorybook[]> => {
  const client = await apigClient();
  const path = `/storybooks/v2/uploadedFiles`;
  const body = {
    keyId
  };
  try {
    const result = await client.invokeApi({}, path, 'POST', {}, body);
    return result.data
      .map(mapStorybookFilesResponseToStorybook)
      .filter((f: IStorybook) => !f.id.endsWith('/'));
  } catch (e) {
    console.log('error get file list', e);
    return [];
  }
};

export const deleteStorybookById = async (id: string) => {
  const client = await apigClient();
  const path = `/storybooks/v1/${id}`;
  const result = await client.invokeApi({}, path, 'DELETE', {});
  return result;
};

export const saveStorybook = async (storybook: IStorybookPayload) => {
  const client = await apigClient();
  const path = `/storybooks/v1/`;
  const result = await client.invokeApi({}, path, 'POST', {}, storybook);

  return result;
};

export const activateStorybook = async (body: {
  id: string;
  activatedBy: string;
}) => {
  const client = await apigClient();
  const path = `/storybooks/v1/activate`;
  const result = await client.invokeApi({}, path, 'POST', {}, body);

  return result;
};

export const publishStorybook = async (body: {
  id: string;
  publishedBy: string;
  passcode?: string;
}) => {
  const client = await apigClient();
  const path = `/storybooks/v2/publish`;
  const result = await client.invokeApi({}, path, 'POST', {}, body);

  return result;
};

export const unpublishStorybook = async (id) => {
  const client = await apigClient();
  const path = `/storybooks/v2/unpublish/${id}`;
  const result = await client.invokeApi({}, path, 'POST', {});

  return result;
};

export const getPublishedStorybookById = async (arg: {
  id: string;
  passcode?: string;
}) => {
  const client = await apigClient();
  const path = `/storybooks/v2/published/${arg.id}`;

  const additionalParams = arg.passcode
    ? {
        queryParams: {
          passcode: arg.passcode
        }
      }
    : {};

  try {
    const result = await client.invokeApi({}, path, 'GET', additionalParams);
    const status = getAccessTypeFromStorybookStatus(result.data.status);

    if (status === StorybookAccessType.APPROVED) {
      return mapResponseToStorybook(result.data || {});
    } else {
      return {
        id: arg.id,
        status
      };
    }
  } catch (e) {
    return {
      status: e.message.replace(/^\D+/g, '')
    };
  }
};

export const getUserViewsByScreen = async (
  storybookId: string,
  pageId: string
): Promise<IStorybookAnalyticsUserViewsData[]> => {
  const client = await apigClient(storybookAnalyticsBaseUrl);
  const path = `/storybookAnalytics/v2/userViewsByScreen`;
  const body = {
    storybookId,
    pageId
  };
  const result = await client.invokeApi({}, path, 'POST', {}, body);
  return result.data || [];
};

export const getStorybookViewsByStorybook = async (
  storybookId: string,
  pageId?: string
): Promise<IStorybookPageAnalyticsData> => {
  const client = await apigClient(storybookAnalyticsBaseUrl);
  const path = `/storybookAnalytics/v2/storybookViewsByStorybook/${storybookId}`;
  const result = await client.invokeApi({}, path, 'GET', {
    queryParams: { pageId }
  });
  return result.data || {};
};

export const getScreenViewsByStorybook = async (
  storybookId: string,
  pageId?: string
): Promise<IStorybookPageAnalyticsData[]> => {
  const client = await apigClient(storybookAnalyticsBaseUrl);
  const path = `/storybookAnalytics/v2/screenViewsByStorybook/${storybookId}`;
  const result = await client.invokeApi({}, path, 'GET', {
    queryParams: { pageId }
  });
  return result.data || [];
};

export const isSilentVideo = (file: File): Promise<boolean> => {
  const form = new FormData();
  form.append('file', file);
  return httpClient
    .post('/api/silenceDetect', form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((res) => res.data?.silent)
    .catch(() => false);
};
export type SBOverviewQuery = {
  startDate?: string;
  timeZone?: string;
  storybookId: string;
  endDate?: string;
  campaign?: string;
};
export const getStoryBookOverview = async ({
  startDate,
  endDate,
  storybookId,
  timeZone,
  campaign
}: SBOverviewQuery) => {
  const client = await apigClient(
    'https://rmfc1cv9eh.execute-api.ap-southeast-1.amazonaws.com/prod'
  );
  const path = `/storybookAnalytics/v3/getStorybookInsights`;
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    { queryParams: { startDate, endDate, storybookId, timeZone, campaign } },
    {}
  );
  return result;
};

export const getStorybookCampaigns = async (storybookId: string) => {
  const client = await apigClient(
    'https://rmfc1cv9eh.execute-api.ap-southeast-1.amazonaws.com/prod'
  );
  const path = '/storybookAnalytics/v3/getCampaignsFromStorybook';
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    { queryParams: { storybookId } },
    {}
  );
  return result;
};

export type SBInsightInteractionQuery = {
  startDate?: string;
  timeZone?: string;
  storybookId: string;
  endDate?: string;
  campaign?: string;
};
export const getSBInsightInteraction = async ({
  startDate,
  endDate,
  storybookId,
  timeZone,
  campaign
}: SBInsightInteractionQuery) => {
  const client = await apigClient(
    'https://rmfc1cv9eh.execute-api.ap-southeast-1.amazonaws.com/prod'
  );
  const path = `/storybookAnalytics/v3/getStorybookInsightsInteractionOverview`;
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    { queryParams: { startDate, endDate, storybookId, timeZone, campaign } },
    {}
  );
  return result;
};

export type SBInsightSceneOverviewQuery = {
  startDate?: string;
  timeZone?: string;
  storybookId: string;
  endDate?: string;
  campaign?: string;
};
export const getSBInsightSceneOverview = async ({
  startDate,
  endDate,
  storybookId,
  timeZone,
  campaign
}: SBInsightSceneOverviewQuery) => {
  const client = await apigClient(
    'https://rmfc1cv9eh.execute-api.ap-southeast-1.amazonaws.com/prod'
  );
  const path = `/storybookAnalytics/v3/getStorybookInsightsSceneOverview`;
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    { queryParams: { startDate, endDate, storybookId, timeZone, campaign } },
    {}
  );
  return result;
};

type SBSceneInsightQuery = {
  startDate?: string;
  timeZone?: string;
  storybookId: string;
  endDate?: string;
  campaign?: string;
  sceneId: string;
};
export const getSBSceneInsight = async ({
  startDate,
  endDate,
  storybookId,
  timeZone,
  campaign,
  sceneId
}: SBSceneInsightQuery) => {
  const client = await apigClient(
    'https://rmfc1cv9eh.execute-api.ap-southeast-1.amazonaws.com/prod'
  );
  const path = `/storybookAnalytics/v3/getStorybookSceneInsights`;
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    {
      queryParams: {
        startDate,
        endDate,
        storybookId,
        timeZone,
        campaign,
        sceneId
      }
    },
    {}
  );
  return result.data;
};
