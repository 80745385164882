import React from 'react';
import { Spinner } from 'react-bootstrap';
import { BsX } from 'react-icons/bs';
import { MdSearch } from 'react-icons/md';
import { logEvent } from '../../../analytics';
import { DID_KEY_PRESS } from '../../../utils/constants';

let timeOut;
const SearchInput = ({
  onSearch,
  searchOnTyping,
  searchOnTypingDelay = 500,
  loading,
  rounded,
  placeHolder,
  defaultKeyword,
  charLengthToSearch = 3,
  className,
  reset = false,
  clear = true
}: {
  onSearch: (keyword: string) => void;
  searchOnTyping?: boolean;
  searchOnTypingDelay?: number;
  loading?: boolean;
  rounded?: boolean;
  placeHolder?: string;
  defaultKeyword?: string;
  charLengthToSearch?: number;
  className?: string;
  reset?: boolean;
  clear?: boolean;
}) => {
  const [keyword, setKeyword] = React.useState(defaultKeyword || '');

  const handleChange = (value: string) => {
    setKeyword(value);

    if (timeOut) {
      clearTimeout(timeOut);
    }
    if (
      searchOnTyping &&
      (value?.length >= charLengthToSearch || !value?.length)
    ) {
      timeOut = setTimeout(() => {
        onSearch(value);
      }, searchOnTypingDelay);
    }
  };

  return (
    <div className="SearchInput d-flex align-items-center">
      <input
        type="text"
        value={keyword}
        className={`form-control ssp-ignore ${className ? className : ''}`}
        placeholder={placeHolder || 'Search'}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onSearch(keyword);
            logEvent(DID_KEY_PRESS, DID_KEY_PRESS, {
              action: 'search',
              key: 'Enter',
              keyword
            });
            if (reset) setKeyword('');
          }
        }}
      />
      {keyword && clear && (
        <div
          className="btn btn-sm btn-clear"
          onClick={() => {
            setKeyword('');
            onSearch('');
          }}
        >
          {loading ? <Spinner animation="border" size="sm" /> : <BsX />}
        </div>
      )}
      <div className="btn btn-search ssp-ignore">
        <MdSearch />
      </div>
      <style jsx>{`
        .SearchInput {
          position: relative;
        }
        .btn {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
        .btn-clear {
          right: 0;
        }
        .btn-search {
          left: 0;
        }
        input {
          padding-left: 35px;
          padding-right: 30px;
        }
        .form-control {
          border-radius: ${rounded ? '100px' : '0.25rem'};
        }
        .search-library-asset {
          height: 30px;
          font-weight: 400 !important;
          font-size: 12px !important;
          min-width: 240px;
        }
      `}</style>
    </div>
  );
};

export default SearchInput;
