import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IAsset } from '../../../interfaces';
import { Form, Spinner, Overlay, Tooltip } from 'react-bootstrap';
import {
  EditFileNameIcon,
  IConLookup,
  IconPlayVideo,
  PlayVideoIcon
} from '../Common/HubIcons';
import { capitalize, debounce, isEmpty, last } from 'lodash';
import ReactPlayer from 'react-player';
import { logEvent } from '../../../analytics';
import {
  DID_CLICK_PREVIEW_ASSET_THUMBNAIL,
  DID_UPDATE_TITLE_FAILED,
  DID_UPDATE_TITLE_SUCCESSFUL,
  HUB_ASSET_LIBRARY_EVENT
} from '../../../utils/constants';
import { handleRetryPlayVideo } from '../../../utils/video';
import ImageWithWebp from '../Common/ImageWithWebp';
import { FaFilePowerpoint } from 'react-icons/fa';

type ImageGenre = 'landscape' | 'portrait';

const VideoInlinePreview = ({
  url,
  playing,
  previewImage,
  onError
}: {
  url: string;
  playing: boolean;
  previewImage?: string;
  onError: (e?: React.SyntheticEvent) => void;
}) => {
  const [videoStarted, setVideoStarted] = useState(false);
  useEffect(() => {
    if (playing) {
      setVideoStarted(true);
    }
  }, [playing]);

  const playerRef = React.useRef<ReactPlayer>();

  return (
    <div className="inline-preview position-relative">
      {!playing && previewImage && !videoStarted ? (
        <ImageWithWebp
          src={previewImage}
          alt=""
          onError={onError}
          style={{ height: '100%', width: 'auto' }}
        />
      ) : (
        <ReactPlayer
          ref={playerRef}
          url={url}
          playing={playing}
          playsinline
          width={'auto'}
          height="100%"
          style={{ height: '100%' }}
          muted={true}
          onError={(e, data, hlsInstance, hlsGlobal) => {
            if (!e) {
              return;
            }
            if (e === 'hlsError') {
              handleRetryPlayVideo({
                error: e,
                data,
                hlsInstance,
                hlsGlobal,
                videoUrl: url,
                retryCount: 2,
                onRetry: () => {
                  console.log('Retry playing video');
                }
              });
              return;
            }

            if (e?.name === 'AbortError') {
              playerRef.current?.getInternalPlayer()?.pause();
              return;
            }
            onError();
          }}
        />
      )}
      <style jsx>{`
        .inline-preview {
          height: 100%;
        }
      `}</style>
    </div>
  );
};

const AssetThumbnail: React.FC<{
  item: IAsset;
  local?: boolean;
  onClick?: (
    item: IAsset,
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onClickView?: (item: IAsset) => void;
  onUpdateTitle?: (item: IAsset) => Promise<void>;
  onFix?: (item: IAsset) => Promise<IAsset>;
  selected?: boolean;
  status?:
    | 'uploading'
    | 'converting'
    | 'synchronizing'
    | 'finished'
    | 'converted'
    | 'uploaded'
    | 'upload_error'
    | 'converted_error';
  uploadedError?: string;
  viewable?: boolean;
  selectedAssets?: string[];
}> = ({
  item,
  onClick,
  onClickView,
  onUpdateTitle,
  onFix,
  local,
  selected,
  status,
  uploadedError,
  viewable = true,
  selectedAssets
}) => {
  const [hoverFileNameAsset, setHoverFileNameAsset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRename, setIsRename] = useState(false);
  const [assetTitle, setAssetTitle] = useState<string>();
  const [openTooltipInvalidTitle, setOpenTooltipInvalidTitle] = useState(false);
  const [hoverAssetThumbnail, setHoverAssetThumbnail] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(); // loading asset error
  const [imageGenre, setImageGenre] = useState<ImageGenre>('landscape');
  const refInputAssetTitle = useRef(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!onFix) {
      return;
    }

    const isVideoType = item.assetType.includes('video');
    const isImage = /\.(jpg|jpeg|png|svg)$/.test(item?.assetPreviewURL);
    const isFontType = item.assetType.includes('font');

    const isValidPreviewUrl = isFontType
      ? item.assetPreviewURL
      : isImage &&
        (isVideoType
          ? last(item.assetPreviewURL?.split('/'))?.includes('preview')
          : item.assetPreviewURL);

    if (!isValidPreviewUrl || !item.convertedURL) {
      onFix(item)
        .then(() => {
          setErrorMessage('');
        })
        .catch((error) => {
          console.log('Failed to fixed the error', error);
        });
    }
  }, [item]);

  const assetType = useMemo(() => {
    let type = 'image';
    if (item.assetType?.includes('video')) {
      type = 'video';
    }
    if (item.assetType?.includes('font')) {
      type = 'font';
    }
    if (item.assetType?.includes('ppt')) {
      type = 'ppt';
    }
    return type;
  }, [item?.assetType]);

  const loadFont = (name: string, url: string) => {
    const newStyle = document.createElement('style');
    newStyle.appendChild(
      document.createTextNode(
        '@font-face{font-family: "' + name + '"; src: url(' + url + ');}'
      )
    );
    document.body.appendChild(newStyle);
  };

  useEffect(() => {
    if (assetType !== 'font') return;
    try {
      loadFont(item.title, local ? item.assetPreviewURL : item.convertedURL);
    } catch (e) {
      setErrorMessage('Failed to load the asset.');
    }
  }, [item]);

  useEffect(() => {
    if (!assetTitle?.length) {
      setOpenTooltipInvalidTitle(false);
      return;
    }
    const invalidPattern = /^[^\\/:\*\?"<>\|]+$/;
    setOpenTooltipInvalidTitle(!invalidPattern.test(assetTitle));
  }, [assetTitle]);

  const onUpdateAssetTitle = async () => {
    if (!assetTitle?.length || openTooltipInvalidTitle) {
      setIsRename(false);
      return;
    } else {
      setLoading(true);
      try {
        if (assetTitle !== item.title) {
          await onUpdateTitle({
            ...item,
            title: assetTitle
          });
        }
        setIsRename(false);
        setLoading(false);
        logEvent(HUB_ASSET_LIBRARY_EVENT, DID_UPDATE_TITLE_SUCCESSFUL, {
          item: {
            id: item.id,
            originalURL: item.originalURL,
            assetType: item.assetType
          }
        });
      } catch {
        setIsRename(false);
        setLoading(false);
        logEvent(HUB_ASSET_LIBRARY_EVENT, DID_UPDATE_TITLE_FAILED, {
          item: {
            id: item.id,
            originalURL: item.originalURL,
            assetType: item.assetType
          }
        });
      }
    }
  };

  const renderPreview = () => {
    if (
      status === 'converting' ||
      status === 'uploading' ||
      status === 'synchronizing'
    ) {
      return (
        <div className="card-asset--preview d-flex flex-column justify-content-center align-items-center h-100 w-100 bg-uploading">
          <Spinner
            animation="border"
            size="sm"
            style={{ width: 40, height: 40 }}
          />
          <span className="text-status-uploading mt-1">
            {status && `${status} ...`}
          </span>
        </div>
      );
    }

    return (
      <div
        className={`card-asset--preview d-flex justify-content-center align-items-center h-100 w-100 position-relative ${
          assetType === 'font'
            ? 'bg-font-text'
            : assetType === 'image'
            ? 'image-preview'
            : ''
        } ${item.assetType?.includes('svg') ? 'px-5 py-5' : ''}`}
        style={{ overflow: 'hidden' }}
        id={`asset-${item.id}`}
      >
        {assetType === 'font' && (
          <div
            style={{
              fontFamily: `"${item.title}"`
            }}
            className="wrapper-font-text"
          >
            <span>
              ABCDEFGHIJKLMNOPQRSTUVWXYZ<br></br>abcdefghijklmnopqrstuvwxyz
              <br></br>1234567890
            </span>
          </div>
        )}
        {assetType === 'image' && (
          <ImageWithWebp
            src={item.assetPreviewURL || item.originalURL}
            alt={item.title || 'asset image'}
            onLoad={async (e) => {
              const image = e.target as HTMLImageElement;
              const isLandscape = image.naturalWidth > image.naturalHeight;
              setImageGenre(() => (isLandscape ? 'landscape' : 'portrait'));
            }}
            onError={() => {
              setErrorMessage('Failed to load the asset.');
            }}
            className={imageGenre}
          />
        )}

        {assetType === 'video' && (
          <>
            <VideoInlinePreview
              url={local ? item.assetPreviewURL : item.convertedURL}
              previewImage={!local && item.assetPreviewURL}
              playing={playing}
              onError={(e) => {
                console.log('Loading video error', e);
                setErrorMessage('Failed to load the asset');
              }}
            />
            {!playing && (
              <div className="d-flex position-absolute icon-play rounded-circle">
                <PlayVideoIcon />
              </div>
            )}
          </>
        )}

        {assetType === 'ppt' && (
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <FaFilePowerpoint size={35} color="#dd4423" />
          </div>
        )}

        {hoverAssetThumbnail && !local && viewable && (
          <>
            <div className="overlay-asset w-100 h-100 position-absolute"></div>
            <div
              className="btn-view d-flex justify-content-center align-items-center shadow-sm"
              onClick={(e) => {
                onClickView(item);
                logEvent(
                  HUB_ASSET_LIBRARY_EVENT,
                  DID_CLICK_PREVIEW_ASSET_THUMBNAIL,
                  {
                    item: {
                      id: item.id,
                      originalURL: item.originalURL,
                      assetType: item.assetType
                    }
                  }
                );
                e.stopPropagation();
              }}
            >
              {assetType === 'video' ? <IconPlayVideo /> : <IConLookup />}
            </div>
          </>
        )}
      </div>
    );
  };

  const normalizeStatus = (status: IAsset) => {
    if (
      status.status === 'deleted' ||
      status.status === 'permanently_deleted'
    ) {
      return 'deleted';
    }
    if (status.visibility === 'brand') {
      return 'activated';
    }
    return 'private';
  };

  const itemStatus = normalizeStatus(item);

  const renderBackgroundExtensionName = () => {
    if (assetType === 'image') {
      if (item.assetType?.includes('svg')) return 'bg-filename-extension-svg';
      else return 'bg-filename-extension-image';
    }
    if (assetType === 'video') return 'bg-filename-extension-video';
    if (assetType === 'font') return 'bg-filename-extension-font';
    if (assetType === 'ppt') return 'bg-filename-extension-ppt';
  };

  const externalAssets = selectedAssets?.filter((asset) => asset !== item.id);
  const isInSelectedList = selectedAssets?.some((aId) => item.id === aId);
  const extension = item?.originalURL?.split('.').pop().toUpperCase();

  return (
    <>
      <div
        className={`card-asset ${assetType} ${selected ? 'selected' : ''}`}
        id={`asset-${item.id}`}
        onClick={(e) => {
          e.stopPropagation();
          onClick?.(item, e);
        }}
        ref={containerRef}
        draggable={!isRename}
        onDragStart={(e) => {
          containerRef.current?.classList?.add('drag-active');
          e.dataTransfer.setData(
            'assetIds',
            isInSelectedList ? selectedAssets.join(',') : item.id
          );
          e.dataTransfer.dropEffect = 'move';

          if (isInSelectedList) {
            externalAssets.forEach((aId) => {
              document
                .getElementById(`asset-${aId}`)
                .classList.add('drag-active');
            });
          }
          const ghostElement = document.getElementById(`ghost-ele-${item.id}`);
          e.dataTransfer.setDragImage(ghostElement, 120, 20);
        }}
        onDragEnd={() => {
          containerRef.current?.classList?.remove('drag-active');
          if (isInSelectedList) {
            externalAssets.forEach((aId) => {
              document
                .getElementById(`asset-${aId}`)
                ?.classList?.remove('drag-active');
            });
          }
        }}
        onMouseEnter={() => {
          setHoverAssetThumbnail(true);
          setPlaying(true);
        }}
        onMouseLeave={debounce(() => {
          setHoverAssetThumbnail(false);
          setPlaying(false);
        }, 200)}
      >
        <div className="position-absolute">
          <div
            className="dragging-elements position-relative"
            id={`ghost-ele-${item.id}`}
          >
            <div className="dragging-elements-item dragging-element position-absolute shadow-sm">
              <div className="d-flex justify-content-center align-items-center">
                <span
                  className={`file-extension mr-2 ${renderBackgroundExtensionName()}`}
                >
                  {extension}
                </span>
                <span className="file-name">${item.title}</span>
              </div>
              <span className="number">
                {isInSelectedList ? selectedAssets?.length || 1 : 1}
              </span>
            </div>
            {!isEmpty(externalAssets) && isInSelectedList && (
              <div className="dragging-elements-item additional-storybook position-absolute"></div>
            )}
          </div>
        </div>
        {!local && itemStatus !== 'activated' && itemStatus !== 'deleted' && (
          <div
            className={`block-status-asset d-flex justify-content-center align-items-center ${
              normalizeStatus(item) === 'private' ? 'bg-private' : ''
            }`}
          >
            <span className="text-white">{capitalize(itemStatus)}</span>
          </div>
        )}
        {renderPreview()}
        <div className="block-info-asset d-flex justify-content-between align-items-center">
          <div
            className={`${
              isRename ? 'w-100' : 'w-75'
            } d-flex align-items-center`}
          >
            <div className={`block-left ${renderBackgroundExtensionName()}`}>
              <span>{item?.originalURL?.split('.').pop().toUpperCase()}</span>
            </div>
            {isRename ? (
              <div className="d-flex w-100" style={{ position: 'relative' }}>
                <Form.Control
                  type="text"
                  autoFocus
                  id="input-rename-asset"
                  ref={refInputAssetTitle}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    if (e.key === 'Escape') {
                      setIsRename(false);
                      return;
                    }

                    if (e.key === 'Enter') {
                      onUpdateAssetTitle();
                    }
                  }}
                  onBlur={onUpdateAssetTitle}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    setAssetTitle(e.target.value);
                    e.stopPropagation();
                  }}
                  value={assetTitle}
                  style={{
                    height: 22,
                    fontSize: 10,
                    padding: '3px 10px',
                    border: openTooltipInvalidTitle && '1px solid #960B0B'
                  }}
                />
                <Overlay
                  placement="top"
                  show={openTooltipInvalidTitle}
                  target={refInputAssetTitle.current}
                >
                  {(props) => (
                    <Tooltip
                      id="tooltip"
                      {...props}
                      style={{
                        color: '#fff',
                        fontSize: 12,
                        borderRadius: 5,
                        textAlign: 'center',
                        ...props.style
                      }}
                    >
                      {
                        'a file name can’t contain any of the following characters:  / \\ : * ? “ < > |'
                      }
                    </Tooltip>
                  )}
                </Overlay>
                {loading && (
                  <span className="ml-1">
                    <Spinner variant="secondary" size="sm" animation="border" />{' '}
                  </span>
                )}{' '}
              </div>
            ) : (
              <div
                className="block-right h-100 d-flex align-items-center"
                onMouseMove={() => setHoverFileNameAsset(true)}
                onMouseLeave={() => setHoverFileNameAsset(false)}
              >
                <span
                  className={`${
                    hoverFileNameAsset && !local && viewable
                      ? 'text-underline'
                      : ''
                  }`}
                >
                  {item?.title}
                </span>
                {!local && hoverFileNameAsset && viewable && (
                  <div
                    className="d-flex align-items-center justify-content-center ml-1"
                    style={{ width: 20, height: 20, cursor: 'pointer' }}
                    onClick={(e) => {
                      setIsRename(true);
                      setAssetTitle(item?.title);
                      e.stopPropagation();
                    }}
                  >
                    <EditFileNameIcon />
                  </div>
                )}
              </div>
            )}
          </div>
          {!isRename && (
            <div className="block-size-image h-100 d-flex justify-content-between align-items-center">
              {item?.sizeKB && (
                <span>{Number(item?.sizeKB / 1000 / 1000).toFixed(2)}MB</span>
              )}
            </div>
          )}
        </div>
        {errorMessage && (
          <p className="text-danger mb-0" style={{ fontSize: 12 }}>
            {errorMessage}
          </p>
        )}
        {uploadedError && (
          <div>
            <span className="text-status-uploading mt-1 text-danger">
              {uploadedError}
            </span>
          </div>
        )}
      </div>
      <style jsx global>{`
        .icon-play {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        }
        .card-asset {
          background: #f9f9f9;
          border-radius: 3px;
          display: flex;
          flex-direction: column;
          position: relative;
          width: 249px;
          height: 249px;
          padding: 7px;
          margin: 5px;
          cursor: pointer;
          overflow: hidden;
          box-sizing: border-box;
          border: 2px solid rgba(0, 0, 0, 0);
          transition: all 0.2s ease-out;
        }
        .card-asset.drag-active {
          opacity: 0.4;
        }
        .card-asset.selected {
          border: 2px solid #8f6599;
          border-radius: 5px;
        }
        .card-asset.selected {
          background-color: rgba(143, 101, 153, 0.1);
        }
        .card-asset.selected .block-info-asset {
          background-color: transparent;
        }
        .block-status-asset {
          position: absolute;
          top: 18px;
          right: 18px;
          padding: 4px 8px;
          background: #008800;
          border-radius: 2px;
        }
        .block-status-asset span {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
        }
        .bg-activated {
          background: #008800;
        }
        .bg-private {
          background: #1f1f1f;
          z-index: 1;
        }
        .bg-font-text {
          background: #f6f6f6;
          z-index: 1;
        }
        .card-asset .card-asset--preview {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 200px;
        }
        .image-preview {
          background: url('/asset/advisor/chessboard.svg');
          background-size: 15px 15px;
        }
        :global(.card-asset--preview img.landscape) {
          height: 100%;
          width: auto;
        }
        :global(.card-asset--preview img.portrait) {
          height: auto;
          width: 100%;
        }
        .block-info-asset {
          margin-top: 10px;
          padding: 2px;
        }
        .block-left {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 22px;
          height: 22px;
          padding: 8px 4px;
          border-radius: 3px;
          margin-right: 10px;
        }
        .bg-filename-extension-image {
          background: #7ba97b;
        }
        .bg-filename-extension-font {
          background: #a8a8a8;
        }
        .bg-filename-extension-ppt {
          background: #dd4423;
        }
        .bg-filename-extension-svg {
          background: #4d94ff;
        }
        .bg-filename-extension-video {
          background: #000000;
        }
        .block-left span {
          font-weight: 600;
          font-size: 5px;
          line-height: 8px;
          color: #ffffff;
        }
        .block-right {
          width: calc(100% - 32px);
        }
        .block-right span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .block-right,
        .block-size-image span {
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
        }
        .block-size-image span {
          color: #606060;
        }
        .card-asset--preview .wrapper-font-text {
          padding: 0 20px;
          width: 100%;
        }
        .card-asset--preview .wrapper-font-text span {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          word-wrap: break-word;
        }
        .text-underline {
          text-decoration: underline;
        }
        .text-status-uploading {
          font-weight: 400;
          font-size: 10px;
          text-align: center;
          color: #626262;
          text-transform: capitalize;
        }
        .bg-uploading {
          background: #f6f6f6;
        }
        .card-asset :global(video) {
          max-height: 100%;
        }
        .card-asset .overlay-asset {
          top: 0;
          left: 0;
          opacity: 0.5;
          background: #000000;
          z-index: 1;
        }
        .card-asset .btn-view {
          position: absolute;
          bottom: -3px;
          right: -3px;
          transform: translate(-50%, -50%);
          z-index: 2;
          transition: 0.3s;
          box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        }
        .card-asset .overlay-asset span {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          color: #ffffff;
        }
        .dragging-elements {
          width: 249px;
          height: 60px;
          overflow: hidden;
          transform: translateX(calc(-100% - 15px));
        }
        .dragging-element {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: white;
          padding: 8px;
          border-radius: 10px;
          width: 100%;
          overflow: hidden;
          z-index: 2;
        }
        .dragging-element .file-extension {
          border-radius: 4px;
          color: white;
          padding: 7px 4px;
          text-align: center;
          width: 22px;
          height: 22px;
          font-size: 6px;
        }
        .dragging-element .file-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 140px;
          font-size: 10px;
          display: inline-block;
        }
        .dragging-element .number {
          background: #000000;
          padding: 4px;
          border-radius: 50%;
          color: #fff;
          width: 30px;
          height: 30px;
          text-align: center;
        }
        .dragging-elements-item {
          height: 46px;
        }
        .additional-asset {
          border-radius: 10px;
          background: white;
          transform: translateX(-50%);
          left: 50%;
          top: 6px;
          width: calc(100% - 8px);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.23);
        }
      `}</style>
    </>
  );
};

export default AssetThumbnail;
