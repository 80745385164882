import { capitalize, isEmpty, last, orderBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IFolderKey } from '../../../interfaces';
import { DeleteIcon } from '../Common/HubIcons';
import { Form, Button } from 'react-bootstrap';
import { logClickButton, logEvent } from '../../../analytics';
import {
  HUB_ASSET_LIBRARY_EVENT,
  DID_CLICK_CREATE_CATEGORY,
  DID_CLICK_UPDATE_CATEGORY,
  DID_CLICK_BUTTON,
  DID_CREATE_CATEGORY,
  DID_UPDATE_CATEGORY,
  DID_CLICK_DELETE_CATEGORY,
  DID_CLICK_SELECT_PARENT_CATEGORY,
  DID_CLICK_DELETED_MENU,
  DID_CLICK_ADD_CATEGORY
} from '../../../utils/constants';
import { useAssetLibraryFolder } from './hook';
import { HubContext } from '../HubContext';
import { generateV4UUID } from '../../../utils/identityGenerator';
import { InsSelect } from '../Common/Select';
import {
  sortFolderKeys,
  TRASH_FOLDER,
  UNCATEGORIZED_FOLDER
} from '../../utils/folders';
import { PopupConfirmDelete } from './PopupConfirmDelete';
import { CategoryTreeMenu } from './CategoryTreeMenu';
import {
  CategorySingleSelectOption,
  CategorySingleSelectValue
} from './SelectComponents';

export const AssetCategories: React.FC<{
  activeKey: string[];
  showButtonCreateCategory: boolean;
  onClickMenu: (item: IFolderKey, e?: React.MouseEvent<HTMLDivElement, MouseEvent> ) => void;
  onDropToItem?: (item: IFolderKey, transferData: DataTransfer) => void;
  editable?: boolean;
  showTrashBin?: boolean;
  isIntegrationModal?: boolean;
  onClick?: () => void;
}> = ({
  activeKey,
  showButtonCreateCategory,
  onClickMenu,
  onDropToItem,
  editable = true,
  showTrashBin = true,
  isIntegrationModal = false,
  onClick
}) => {
  const { brandId, user, guidedTour } = useContext(HubContext);
  const { folder: assetLibraryCategoriesFolder, updateFolder } =
    useAssetLibraryFolder(brandId, user?.id, false);
  const [openFormEditCategory, setOpenFormEditCategory] = useState(false);
  const [openFormCreateCategory, setOpenFormCreateCategory] = useState(false);
  const [openPopupConfirm, setOpenPopupConfirm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = useState<IFolderKey>();
  const [categoryName, setCategoryName] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [parentCategory, setParentCategory] = useState<
    IFolderKey | undefined
  >();

  useEffect(() => {
    if (!activeKey.length || activeKey.length === 0)
      setParentCategory({ id: '', key: '' });
  }, [activeKey]);

  useEffect(() => {
    if (!openFormCreateCategory && !openFormEditCategory) return;

    const invalidPattern = /^[^/]+$/;
    if (categoryName?.length && !invalidPattern.test(categoryName))
      return setErrorMessage('Category name must not contain "/"');
    return setErrorMessage('');
  }, [categoryName]);

  const createNewCategory = useCallback(() => {
    if (loading) {
      return;
    }
    if (assetLibraryCategoriesFolder?.keys && categoryName) {
      const parentIndex = assetLibraryCategoriesFolder?.keys.findIndex(
        (k) => k.id === parentCategory?.id
      );
      const parent = assetLibraryCategoriesFolder?.keys?.[parentIndex];
      const newCateFolderKeyPath = `${
        parent?.key.replace(/\/$/, '') || ''
      }/${categoryName}`;

      if (
        assetLibraryCategoriesFolder?.keys?.findIndex(
          (fKey) => fKey.key === newCateFolderKeyPath
        ) === -1
      ) {
        const newCateFolderKey = {
          id: generateV4UUID(),
          key: newCateFolderKeyPath
        };
        assetLibraryCategoriesFolder.keys.push(newCateFolderKey);
        assetLibraryCategoriesFolder.keys = sortFolderKeys(
          assetLibraryCategoriesFolder.keys
        );
        setLoading(true);
        updateFolder(assetLibraryCategoriesFolder, user.id, 'add', () => {
          setLoading(false);
        });
        setCategoryName('');
        setErrorMessage('');
        setOpenFormCreateCategory(false);
        setTimeout(() => {
          const parentCategories = assetLibraryCategoriesFolder.keys?.filter(
            (k) =>
              newCateFolderKey.key.includes(k.key) &&
              newCateFolderKey.key !== k.key
          );
          parentCategories.forEach((cate) => {
            const expandIcon = document
              .getElementById(cate.id)
              ?.querySelector<HTMLElement>('.controls.expand-btn');
            expandIcon?.click();
          });
          onClickMenu(newCateFolderKey);
        }, 200);
        return;
      }
      setErrorMessage('Existed category.');
    }
  }, [assetLibraryCategoriesFolder, parentCategory, categoryName]);

  const updateCategory = useCallback(() => {
    if (assetLibraryCategoriesFolder?.keys && categoryName) {
      const childrenOfEditCategory = assetLibraryCategoriesFolder.keys.filter(
        (k) =>
          k.key !== selectedCategory.key && k.key.includes(selectedCategory.key)
      );

      const newKey = `${parentCategory.key.replace(
        /\/$/,
        ''
      )}/${categoryName}/`;
      const currentKey = selectedCategory.key;
      childrenOfEditCategory.forEach(
        (k) =>
          (k.key = k.key.replace(`${currentKey.replace(/\/$/, '')}/`, newKey))
      );
      selectedCategory.key = newKey;
      updateFolder(assetLibraryCategoriesFolder, user.id, 'edit');
      setOpenFormEditCategory(false);
      setErrorMessage('');
    }
  }, [assetLibraryCategoriesFolder, parentCategory, categoryName]);

  const onEdit = (item: IFolderKey, parent: IFolderKey) => {
    logEvent(DID_CLICK_BUTTON, DID_CLICK_UPDATE_CATEGORY, {
      category: item
    });
    setSelectedCategory(item);
    setCategoryName(last(item.key.replace(/\/$/, '').split('/')));
    setOpenFormEditCategory(true);
    setOpenFormCreateCategory(false);
    setParentCategory(parent);
  };

  const onAdd = (item: IFolderKey) => {
    logEvent(DID_CLICK_BUTTON, DID_CLICK_ADD_CATEGORY, {
      category: item
    });
    setCategoryName('');
    setParentCategory(item);
    setOpenFormCreateCategory(true);
    setOpenFormEditCategory(false);
  };

  const onDelete = (item: IFolderKey) => {
    setOpenPopupConfirm(true);
    setSelectedCategory(item);
  };

  const handleDeleteCategory = (item: IFolderKey) => {
    const newCategoriesFolder = assetLibraryCategoriesFolder?.keys.filter(
      (cat) => !cat.key?.includes(item?.key)
    );
    updateFolder(
      { ...assetLibraryCategoriesFolder, keys: newCategoriesFolder },
      user.id,
      'delete'
    );
    if (parentCategory.id === item.id) {
      setParentCategory({id: '', key: ''})
    }
    setOpenPopupConfirm(false);
    logEvent(DID_CLICK_BUTTON, DID_CLICK_DELETE_CATEGORY, { folderKey: item });
  };

  const categoryOptions = sortFolderKeys(
    assetLibraryCategoriesFolder?.keys?.filter(
      (fKey) => ![TRASH_FOLDER, UNCATEGORIZED_FOLDER].includes(fKey.key)
    )
  );

  const categories = [
    { key: UNCATEGORIZED_FOLDER, id: UNCATEGORIZED_FOLDER }
  ].concat(categoryOptions);

  let baseFolders = [];
  if (showTrashBin) {
    baseFolders = [
      {
        id: TRASH_FOLDER,
        key: TRASH_FOLDER,
        label: 'Deleted Files',
        icon: <DeleteIcon />
      }
    ];
  }

  return (
    <>
      <div
        className="categories-container flex flex-column mt-1 w-100"
        onClick={() => {
          if (openFormCreateCategory || openFormCreateCategory) {
            return;
          }
          onClick?.();
        }}
      >
        {!isEmpty(baseFolders) && (
          <div className="w-100">
            <div className="category-overview-header mt-3 mb-2 pl-2">
              <span>Overview</span>
            </div>
            {baseFolders.map((item) => {
              return (
                <div
                  key={item.label}
                  className={`d-flex align-items-center menu-overview-item pl-2 ${
                    activeKey.includes(item.id) ? 'active' : ''
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    logClickButton(DID_CLICK_DELETED_MENU);
                    onClickMenu(item);
                  }}
                >
                  {item.icon}
                  <div className="ml-2">
                    <span className="menu-item--label">{item.label}</span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="category-header ml-2 mt-2">Assets</div>
        <div>
          <CategoryTreeMenu
            items={categories}
            activeKey={activeKey}
            onItemClick={(item, e) => {
              onClickMenu(item, e);
              setParentCategory(item);
            }}
            onEdit={editable ? onEdit : undefined}
            onAdd={editable ? onAdd : undefined}
            onDelete={onDelete}
            isItemDisable={(item) => item.key === UNCATEGORIZED_FOLDER}
            onDrop={onDropToItem}
            sort={(items) =>
              orderBy(items, (item) => {
                if (item.key === UNCATEGORIZED_FOLDER) {
                  return true;
                }
                return item.key.toLowerCase();
              })
            }
          />
        </div>
        <PopupConfirmDelete
          open={openPopupConfirm}
          onRequestClose={() => setOpenPopupConfirm(false)}
          label={`Are you sure you want to delete ${capitalize(
            last(selectedCategory?.key.replace(/\/$/, '').split('/'))
          )} from your Categories?`}
          itemDelete={selectedCategory}
          handleDelete={handleDeleteCategory}
        />
        {showButtonCreateCategory && (
          <div className="block__create-category d-flex align-items-center">
            <Button
              className="rounded-pill"
              variant="dark"
              style={{
                fontSize: 12,
                fontWeight: 600,
                width: '100%'
              }}
              onClick={(e) => {
                e.stopPropagation();
                logClickButton(DID_CLICK_CREATE_CATEGORY);
                setOpenFormCreateCategory(true);
                setOpenFormEditCategory(false);
              }}
            >
              Create Category
            </Button>
          </div>
        )}
      </div>
      {openFormEditCategory && (
        <div className="form__edit-category mt-2">
          <span className="mb-3">Edit Category</span>
          <Form className="mt-3">
            <Form.Group controlId="categoryName">
              <Form.Label className="input-label">Category Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                style={{ height: 34, fontSize: 12 }}
              />
              {errorMessage && (
                <p className="text-danger mt-1 mb-0" style={{ fontSize: 12 }}>
                  {errorMessage}
                </p>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="teams" className="input-label">
                Parent Category
              </Form.Label>
              <InsSelect<IFolderKey>
                id="parentCategory"
                name="parentCategory"
                placeholder="Select Category"
                isMulti={false}
                isClearable={false}
                options={categoryOptions}
                value={parentCategory}
                getOptionLabel={(op) => {
                  return last(op.key.replace(/\/$/, '').split('/'));
                }}
                isOptionDisabled={(op) => {
                  return op.key.includes(selectedCategory?.key);
                }}
                isOptionSelected={(op, selected) => {
                  const selectedValue = selected?.[0];
                  return selectedValue.id && op.id === selectedValue?.id;
                }}
                onChange={(option) => {
                  logEvent(
                    HUB_ASSET_LIBRARY_EVENT,
                    DID_CLICK_SELECT_PARENT_CATEGORY,
                    {
                      parentCategory: option
                    }
                  );
                  setParentCategory(option);
                }}
                components={{
                  Option: CategorySingleSelectOption,
                  SingleValue: CategorySingleSelectValue
                }}
              />
            </Form.Group>
            <div className="d-flex mt-3 justify-content-between w-100">
              <Button
                className="rounded-pill"
                variant="outline-dark"
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  width: 'calc((100% - 5px) / 2)'
                }}
                onClick={() => setOpenFormEditCategory(false)}
              >
                Cancel
              </Button>
              <Button
                className="rounded-pill"
                variant="dark"
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  width: 'calc((100% - 5px) / 2)'
                }}
                onClick={() => {
                  if (errorMessage?.length) return;
                  logEvent(HUB_ASSET_LIBRARY_EVENT, DID_UPDATE_CATEGORY, {
                    category: {
                      categoryName: categoryName,
                      parentCategory: parentCategory
                    }
                  });
                  updateCategory();
                }}
              >
                Confirm
              </Button>
            </div>
          </Form>
        </div>
      )}
      {openFormCreateCategory && (
        <div className="form__edit-category mt-2">
          <span className="mb-3">Create Category</span>
          <Form className="mt-3">
            <Form.Group controlId="categoryName">
              <Form.Label className="input-label">Category Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter New Category Name"
                onChange={(e) => setCategoryName(e.target.value)}
                style={{ height: 34, fontSize: 12 }}
              />
              {errorMessage && (
                <p className="text-danger mt-1 mb-0" style={{ fontSize: 12 }}>
                  {errorMessage}
                </p>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="teams" className="input-label">
                Parent Category
              </Form.Label>
              <InsSelect<IFolderKey>
                menuIsOpen={!isEmpty(guidedTour)? true : undefined}
                id="parentCategory"
                name="parentCategory"
                placeholder="Select Category"
                isMulti={false}
                isClearable={false}
                options={categoryOptions}
                value={parentCategory}
                getOptionLabel={(op) => {
                  return last(op?.key?.replace(/\/$/, '').split('/'));
                }}
                isOptionSelected={(op, selected) => {
                  const selectedValue = selected?.[0];
                  return selectedValue.id && op.id === selectedValue?.id;
                }}
                onChange={(option) => {
                  logEvent(
                    HUB_ASSET_LIBRARY_EVENT,
                    DID_CLICK_SELECT_PARENT_CATEGORY,
                    {
                      parentCategory: option
                    }
                  );
                  setParentCategory(option);
                }}
                components={{
                  Option: CategorySingleSelectOption,
                  SingleValue: CategorySingleSelectValue
                }}
              />
            </Form.Group>
            <div className="d-flex mt-3 justify-content-between w-100">
              <Button
                className="rounded-pill"
                variant="outline-dark"
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  width: 'calc((100% - 5px) / 2)'
                }}
                onClick={() => {
                  setOpenFormCreateCategory(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="rounded-pill"
                variant="dark"
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  width: 'calc((100% - 5px) / 2)'
                }}
                disabled={loading}
                onClick={() => {
                  logEvent(HUB_ASSET_LIBRARY_EVENT, DID_CREATE_CATEGORY, {
                    newCategory: {
                      categoryName: categoryName,
                      parentCategory: parentCategory
                    }
                  });
                  createNewCategory();
                }}
              >
                Confirm
              </Button>
            </div>
          </Form>
        </div>
      )}
      <style jsx>{`
        .category-header {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #606060;
        }
        .categories-container {
          height: ${openFormCreateCategory || openFormEditCategory
            ? 'calc(100% - 420px)'
            : isIntegrationModal
            ? 'calc(100% - 20px)'
            : 'calc(100% - 80px)'};
          overflow: auto;
          overscroll-behavior: none;
        }
        .form__edit-category {
          padding: 20px;
          background: #f6f6f6;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          min-height: 400px;
        }
        .form__edit-category > span {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
        }
        .form__edit-category .input-field {
          padding: 6px 10px !important;
          height: 30px;
        }
        .block__create-category {
          width: 300px;
          height: 70px;
          background: #f6f6f6;
          border-right: 1px solid #ccc;
          position: fixed;
          bottom: 0;
          padding: 20px 30px;
          margin-left: -20px;
        }
        .category-overview-header > span {
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: #000000;
        }
        .menu-overview-item {
          cursor: pointer;
          height: 31px;
        }
        .menu-overview-item .menu-item--label {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
        }
        .menu-overview-item:hover {
          background-color: #f5f5f5;
        }
        .menu-overview-item.active {
          background-color: #d8d8d8;
        }

        .form__edit-category :global(#parentCategory) {
          border: 1px solid #ced4da;
          border-radius: 3px;
          background: #fff;
        }

        .form__edit-category
          :global(#parentCategory .ins-select-control > div) {
          border-bottom: none;
        }

        .form__edit-category
          :global(#parentCategory .ins-select-control > div input) {
          margin-left: 5px !important;
        }
      `}</style>
    </>
  );
};
