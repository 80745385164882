import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IFolder, IMainState, LoginStatus } from '../../../interfaces';
import {
  getFolderAsync,
  initFolderAsync,
  searchLibAssetsAsync,
  updateFolderAsync
} from '../../../redux/advisorHubAsyncActions';
import {
  clearOutdatedCachedAsset,
  SearchAssetOptions
} from '../../clientSideServices/assetLibrary';
import { DEFAULT_ASSET_LIBRARY_FOLDERS } from '../../utils/folders';

export const useAssetLibraryFolder = (
  brandId: string,
  userId: string,
  fetch = true
) => {
  const dispatch = useDispatch();

  const folders = useSelector(
    (state: IMainState) => state.clientState?.hub?.folders?.asset_library
  );

  const loginStatus = useSelector(
    (state: IMainState) => state.clientState?.hub?.loginStatus
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      !brandId ||
      !userId ||
      !fetch ||
      loginStatus !== LoginStatus.LOGGED_IN
    ) {
      return;
    }
    setLoading(true);
    dispatch(
      getFolderAsync(brandId, 'asset_library', (error, folders) => {
        setLoading(false);
        if (error) {
          console.log('Failed to load asset categories', error);
          return;
        }
        const folder = folders?.[0];
        if (isEmpty(folder)) {
          dispatch(
            initFolderAsync({
              userId,
              uuid: brandId,
              context: 'asset_library',
              keys: DEFAULT_ASSET_LIBRARY_FOLDERS
            })
          );
        }
      })
    );
  }, [userId, brandId, loginStatus]);
  const updateFolder = useCallback(
    (
      categoriesFolder: IFolder,
      userId: string,
      action: string,
      onComplete?: (e?: Error, data?: IFolder) => void
    ) => {
      dispatch(updateFolderAsync(categoriesFolder, userId, action, onComplete));
    },
    []
  );

  return {
    loading,
    folder: folders?.[0],
    updateFolder
  };
};

export const useAssetLibrary = (
  brandId: string,
  userId: string,
  fetch = true,
  searchOptions?: Partial<SearchAssetOptions>
) => {
  const dispatch = useDispatch();
  const { assetLibrary, popup } = useSelector((state: IMainState) => {
    return {
      assetLibrary: state.clientState?.hub?.assetLibrary?.assets,
      popup: state.clientState?.hub?.assetLibrary?.popup
    };
  });

  const searchLibAssets = useCallback(
    (
      searchOptions: Omit<SearchAssetOptions, 'userId'>,
      loadMore?: 'end' | 'start',
      onComplete?: () => void
    ) => {
      clearOutdatedCachedAsset();
      dispatch(
        searchLibAssetsAsync({ ...searchOptions, userId }, loadMore, onComplete)
      );
    },
    [userId]
  );

  useEffect(() => {
    if (!fetch || !userId) {
      return;
    }
    searchLibAssets(searchOptions || {});
  }, [userId, brandId]);

  return {
    assetLibrary,
    searchLibAssets,
    popup
  };
};


