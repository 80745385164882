import React from 'react';
import { logEvent } from '../../../../analytics';
import {
  DID_CHOOSE_LOCAL_ITEM,
  DID_CHOOSE_REMOTE_ITEM
} from '../../../../components/hooks/collaborator';
import { ICollaborationConflictPopup } from '../../../../interfaces';
import FormattedDate from '../FormattedDate';
import UserLabel from '../UserLabel';

interface Props extends ICollaborationConflictPopup {
  onClose: () => void;
}

const CollaborationConflict = ({
  onClose,
  onApproveLocalItem,
  onApproveRemoteItem,
  remoteItem,
  context
}: Props) => {
  const message = (
    <>
      This {context === 'scene' ? 'Scene' : 'Storybook'} has been modified
      <br />
      by <UserLabel
        id={remoteItem.modifiedBy}
        emptyName="a collaborator"
      /> at <FormattedDate dateString={remoteItem.modifiedAt} />
      <br />
      What you would like to do?
    </>
  );
  return (
    <div className="CollaborationConflict">
      <h5 className="text-center">{message}</h5>
      <div
        className="btn btn-dark w-100"
        onClick={() => {
          onApproveRemoteItem();
          onClose();
          logEvent(DID_CHOOSE_REMOTE_ITEM, DID_CHOOSE_REMOTE_ITEM);
        }}
      >
        Abort my current update and
        <br />
        load the update from the collaborator
      </div>
      <div
        className="btn btn-outline-dark  w-100"
        onClick={() => {
          onApproveLocalItem();
          onClose();
          logEvent(DID_CHOOSE_LOCAL_ITEM, DID_CHOOSE_LOCAL_ITEM);
        }}
      >
        Save my current update and overwrite
        <br />
        the update from the collaborator
      </div>
      <style jsx>{`
        h5 {
          margin: 20px 0;
        }
        .btn {
          margin-top: 10px;
        }
      `}</style>
    </div>
  );
};

export default CollaborationConflict;
