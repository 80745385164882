import { components } from 'react-select';
import { IFolderKey } from '../../../interfaces';
import { getLevelOfFolderKey } from '../../utils/folders';
import CheckBox from '../Common/Checkbox';
import { createSelectOption } from '../Common/Select';

export const CategorySingleSelectValue = (props) => {
  const level = getLevelOfFolderKey(props.data.key);
  const levelIndicator = level >= 1 ? new Array(level - 1).fill('-') : [];
  return (
    <components.SingleValue {...props}>
      <div className="flex">
        {levelIndicator.map((separator, index) => (
          <span className="level-indicator" key={index}>
            {separator}
          </span>
        ))}
        <span className="ml-1">{level < 1 ? '-' : props.children}</span>
      </div>
      <style jsx global>{`
        .level-indicator {
          transform: scaleX(2.5);
          display: inline-block;
          color: #a8a8a8;
        }
      `}</style>
    </components.SingleValue>
  );
};

export const CategorySingleSelectOption = createSelectOption<IFolderKey, false>(
  (props) => {
    const isDisabled = props.isDisabled;
    const level = getLevelOfFolderKey(props.data.key);
    const levelIndicator = level > 1 ? new Array(level - 1).fill('-') : [];

    return (
      <components.Option {...props}>
        <div className={`option ${isDisabled ? 'disabled' : ''}`}>
          <span className="level-label">
            {levelIndicator.map((separator, index) => (
              <span className="level-indicator" key={index}>
                {separator}
              </span>
            ))}
            <span className={`${level > 1 ? 'ml-2' : ''}`}>{props.label}</span>
          </span>
        </div>
        <style jsx global>{`
          .level-indicator {
            transform: scaleX(2.5);
            display: inline-block;
            color: #a8a8a8;
          }
          .option.disabled {
            color: #ccc;
          }
        `}</style>
      </components.Option>
    );
  }
);

export const CategoryMultiSelectLevelOption = createSelectOption<
  {
    id: string;
    key: string;
    originalState?: {
      isAllSelected?: boolean;
      selected?: boolean;
    };
    isAllSelected?: boolean;
    selected?: boolean;
  },
  true
>((props) => {
  const level = getLevelOfFolderKey(props.data.key);
  const levelIndicator = new Array(level - 1).fill('-');
  const values = props.getValue();

  const isSelected = values.some(
    (val) =>
      val.key === props.data.key &&
      (val as any).selected &&
      (val as any).isAllSelected === false
  );
  const isAllSelected = values.some(
    (val) =>
      val.key === props.data.key &&
      (val as any).selected &&
      (val as any).isAllSelected
  );

  return (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps
      }}
      isSelected={isAllSelected}
    >
      <CheckBox
        type="checkbox"
        checked={isSelected || isAllSelected}
        className={`level-option ${isSelected ? 'selected' : ''} ${
          isAllSelected ? 'all-selected' : ''
        }`}
        label={
          <span className="level-label">
            {levelIndicator.map((separator, index) => (
              <span className="level-indicator" key={index}>
                {separator}
              </span>
            ))}
            <span
              style={{ marginLeft: 4, paddingLeft: (level - 1) * 2 }}
              className={`${level === 1 ? 'font-weight-bold' : ''}`}
            >
              {props.label}
            </span>
          </span>
        }
      />
      <style jsx global>{`
        .level-option .level-label {
        }
        .level-option.selected .checkmark:after {
          width: 5px !important;
          border: none !important;
        }
        .level-option.selected .checkmark {
          background: #a8a8a8;
          border: 1px solid #8f6599 !important;
        }
        .level-option .level-indicator {
          transform: scaleX(2.5);
          display: inline-block;
          color: #a8a8a8;
        }
      `}</style>
    </components.Option>
  );
});

export const TagOption = createSelectOption<
  {
    label: string;
    value: string;
  },
  true
>((props) => {
  
  const values = props.getValue();
  const isSelected = values.some(
    (val) =>
      val.value === props.data.value &&
      (val as any).selected &&
      (val as any).isAllSelected === false
  );
  const isAllSelected = values.some(
    (val) =>
      val.value === props.data.value &&
      (val as any).selected &&
      (val as any).isAllSelected
  );

  return (
    <components.Option {...props} isSelected={isAllSelected}>
      <CheckBox
        type="checkbox"
        id={props.label}
        checked={isSelected || isAllSelected}
        className={`${props.label} level-option ${
          isSelected ? 'selected' : ''
        } ${isAllSelected ? 'all-selected' : ''} ${
          props.isFocused ? 'focused' : ''
        }`}
        label={
          <span className="level-label">
            <span style={{ marginLeft: "4px", whiteSpace: "pre" }}>{props.label}</span>
          </span>
        }
      />
      <style jsx global>{`
        .level-option .level-label {
          font-weight: 400;
        }
        .level-option.selected .checkmark:after {
          width: 5px !important;
          border: none !important;
        }
        .level-option.selected .checkmark {
          background: #ece0ef;
          border: 1px solid #8f6599 !important;
        }
        .level-option .level-indicator {
          transform: scaleX(2.5);
          display: inline-block;
          color: #a8a8a8;
        }
      `}</style>
    </components.Option>
  );
});
