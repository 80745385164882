import {
  ICollaborationState,
  ILibraryScene,
  IMainState,
  IStorybook,
  LoadingStatus
} from './../../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCollaborators,
  getCollaborationsByUser,
  getCollaborators,
  ICollaboratorContext,
  ICollaboratorScopeType,
  removeCollaborators
} from '../../advisorHub/clientSideServices/collaboration';
import { actionHubSetCollaborationState } from '../../redux/actions';
import React from 'react';
import { useLoadingStatus } from './loading';
import { HubUserContext } from '../../advisorHub/components/UserManagement/HubUserContext';
import { useAllTeams } from '../../advisorHub/components/hooks/teams';
import { IUserTeamItem } from '../../advisorHub/components/Common/Collaborators/Collaborator';
import { isImage } from '../../utils/file';
import { sortBy } from 'lodash';
import { HubContext } from '../../advisorHub/components/HubContext';
import { getIdentityId } from '../../utils/identity';
import { logEvent } from '../../analytics';
import { useQuery } from 'react-query';

export const DID_ADD_COLLABORATOR = 'DID_ADD_COLLABORATOR';
export const DID_REMOVE_COLLABORATOR = 'DID_REMOVE_COLLABORATOR';
export const ADD_COLLABORATOR_FAILED = 'ADD_COLLABORATOR_FAILED';
export const REMOVE_COLLABORATOR_FAILED = 'REMOVE_COLLABORATOR_FAILED';
export const DID_SHOW_COLLABORATION_CONFLICT_POPUP =
  'DID_SHOW_COLLABORATION_CONFLICT_POPUP';
export const DID_CHECK_FOR_COLLABORATION_CONFLICT =
  'DID_CHECK_FOR_COLLABORATION_CONFLICT';
export const DID_CHOOSE_LOCAL_ITEM = 'DID_CHOOSE_LOCAL_ITEM';
export const DID_CHOOSE_REMOTE_ITEM = 'DID_CHOOSE_REMOTE_ITEM';

type CollaborationItemType<T extends ICollaboratorContext> = T extends 'scene'
  ? ILibraryScene
  : IStorybook;

export const useCollaborationPopup = () => {
  const dispatch = useDispatch();

  const state = useSelector(
    (state: IMainState) => state.clientState?.hub?.collaboration
  );

  const updateCollaborationState = (payload: ICollaborationState) => {
    dispatch(actionHubSetCollaborationState(payload));
  };

  const updatePopupState = (payload?: {
    itemId: string;
    context: ICollaboratorContext;
    owner: string;
  }) => {
    updateCollaborationState({ popup: payload });
  };

  const openPopup = (
    itemId: string,
    context: ICollaboratorContext,
    owner: string
  ) => {
    updatePopupState({ itemId, context, owner });
  };
  const closePopup = () => {
    updatePopupState();
  };

  const conflictPopup = state?.conflictPopup;

  return {
    openPopup,
    closePopup,
    collaborationPopup: state?.popup,
    conflictPopup
  };
};

export const useCollaborations = <T extends ICollaboratorContext>({
  itemId,
  context,
  owner
}: {
  itemId: string;
  context: T;
  owner: string;
}) => {
  const dispatch = useDispatch();
  const { setLoadingStatus } = useLoadingStatus();
  const { user } = React.useContext(HubContext);
  const userId = getIdentityId() || user?.id;
  const { fetchUsersByBrand, usersByBrand } = React.useContext(HubUserContext);

  const [collaboratorIds, setCollaboratorIds] = React.useState([]);
  const shouldIncludeOwner = !collaboratorIds?.includes(owner);

  const state = useSelector(
    (state: IMainState) => state.clientState?.hub?.collaboration
  );

  const isOwner = (id: string) => id === owner;

  const viewOnly = state && userId !== owner;

  const users: IUserTeamItem[] = (usersByBrand || []).map((user) => ({
    id: user.id,
    name: `${user.first_name} ${user.last_name}`.trim(),
    avatar: isImage(user.avatar_picture) ? user.avatar_picture : undefined,
    note: user.email,
    context: 'user' as ICollaboratorScopeType,
    owner: isOwner(user.id)
  }));

  const getAllUsersAndTeams = () => {
    const { data: allTeams } = useAllTeams();

    const teams: IUserTeamItem[] = (allTeams || []).map((team) => ({
      id: team.id,
      name: team.name,
      context: 'team'
    }));
    return sortBy([...users, ...teams], 'name');
  }

  const getCollaborationsInfo = () :IUserTeamItem[] => {
    const allUsersAndTeams = getAllUsersAndTeams();
    return sortBy(
      collaboratorIds
        .filter((c) => allUsersAndTeams.some((ut) => ut.id === c))
        .map((id) => {
          const c2 = allUsersAndTeams.find((c) => c.id === id);
          return {
            id,
            name: c2?.name,
            avatar: c2?.avatar,
            context: c2?.context,
            note: c2?.note,
            owner: c2?.owner
          };
        }),
      'name'
    );
  }

  const loadCollaboratorsById = ({
    id,
    silent,
    onSuccess
  }: {
    id: string;
    silent?: boolean;
    onSuccess?: (res?: { teams?: string[]; users?: string[] }) => void;
  }) => {
    if (!silent) {
      setLoadingStatus(`usersTeams-${id}`, LoadingStatus.LOADING);
    }

    getCollaborators(context, id)
      .then((res) => {
        setCollaboratorIds([...res.teams, ...res.users]);
        onSuccess?.(res);
      })
      .catch(() => {
        console.log('getCollaborators error');
      })
      .finally(() =>
        setLoadingStatus(`usersTeams-${id}`, LoadingStatus.LOADED)
      );
  };

  const handleAddUserCollaborator = (id: string) => {
    const body = {
      itemId: itemId,
      scope: 'user' as ICollaboratorScopeType,
      scopeDetails: shouldIncludeOwner ? [owner, id] : [id]
    };

    addCollaborators(context, body)
      .then(() => {
        logEvent(DID_ADD_COLLABORATOR, DID_ADD_COLLABORATOR, {
          ...body,
          context
        });
        loadCollaboratorsById({ id: itemId, silent: true });
      })
      .catch(() => {
        console.log('add collaborator error');
      })
      .finally(() =>
        setLoadingStatus(`userTeamItem-${id}`, LoadingStatus.LOADED)
      );
  };

  const handleAddTeamCollaborator = (id: string) => {
    const teamBody = {
      itemId: itemId,
      scope: 'team' as ICollaboratorScopeType,
      scopeDetails: [id]
    };

    const userBody = {
      itemId: itemId,
      scope: 'user' as ICollaboratorScopeType,
      scopeDetails: [owner]
    };

    Promise.all(
      shouldIncludeOwner
        ? [
            addCollaborators(context, teamBody),
            addCollaborators(context, userBody)
          ]
        : [addCollaborators(context, teamBody)]
    )
      .then(() => {
        logEvent(DID_ADD_COLLABORATOR, DID_ADD_COLLABORATOR, {
          itemId,
          scope: shouldIncludeOwner ? 'team and user' : 'team',
          context,
          scopeDetails: shouldIncludeOwner ? [owner, id] : [id]
        });
        loadCollaboratorsById({ id: itemId, silent: true });
      })
      .catch(() => {
        console.log('add collaborator error');
      })
      .finally(() =>
        setLoadingStatus(`userTeamItem-${id}`, LoadingStatus.LOADED)
      );
  };

  const handleAddCollaborator = (scope: ICollaboratorScopeType, id: string) => {
    setLoadingStatus(`userTeamItem-${id}`, LoadingStatus.LOADING);
    if (scope === 'user') {
      handleAddUserCollaborator(id);
    } else {
      handleAddTeamCollaborator(id);
    }
  };

  const handleRemoveUserCollaborators = (
    ids: string[],
    isTheLastCollaborators?: boolean
  ) => {
    const scopeDetails = isTheLastCollaborators ? [owner, ...ids] : ids;
    removeCollaborators(context, {
      itemId: itemId,
      scope: 'user',
      scopeDetails
    })
      .then(() => {
        logEvent(DID_REMOVE_COLLABORATOR, DID_REMOVE_COLLABORATOR, {
          itemId,
          scopeDetails,
          context,
          scope: 'user'
        });
        loadCollaboratorsById({ id: itemId, silent: true });
      })
      .catch(() => {
        console.log('remove collaborator error');
      })
      .finally(() =>
        setLoadingStatus(`userTeamItem-${ids[0]}`, LoadingStatus.LOADED)
      );
  };

  const handleRemoveTeamCollaborators = (
    ids: string[],
    isTheLastCollaborators?: boolean
  ) => {
    Promise.all(
      isTheLastCollaborators
        ? [
            removeCollaborators(context, {
              itemId: itemId,
              scope: 'user',
              scopeDetails: [owner]
            }),
            removeCollaborators(context, {
              itemId: itemId,
              scope: 'team',
              scopeDetails: ids
            })
          ]
        : [
            removeCollaborators(context, {
              itemId: itemId,
              scope: 'team',
              scopeDetails: ids
            })
          ]
    )
      .then(() => {
        logEvent(DID_REMOVE_COLLABORATOR, DID_REMOVE_COLLABORATOR, {
          itemId,
          scopeDetails: shouldIncludeOwner ? [owner, ...ids] : ids,
          scope: shouldIncludeOwner ? 'team and user' : 'team',
          context
        });
        loadCollaboratorsById({ id: itemId, silent: true });
      })
      .catch(() => {
        console.log('remove collaborator error');
      })
      .finally(() =>
        setLoadingStatus(`userTeamItem-${ids[0]}`, LoadingStatus.LOADED)
      );
  };

  const handleRemoveCollaborator = (
    scope: ICollaboratorScopeType,
    ids: string[]
  ) => {
    setLoadingStatus(`userTeamItem-${ids[0]}`, LoadingStatus.LOADING);
    const isTheLastCollaborators = collaboratorIds?.length === 2;

    if (scope === 'user') {
      handleRemoveUserCollaborators(ids, isTheLastCollaborators);
    } else {
      handleRemoveTeamCollaborators(ids, isTheLastCollaborators);
    }
  };

  const checkForConflict = ({
    remoteItem,
    localItem,
    onApproveRemoteItem,
    onApproveLocalItem
  }: {
    remoteItem: CollaborationItemType<T>;
    localItem: CollaborationItemType<T>;
    onApproveRemoteItem?: () => void;
    onApproveLocalItem: () => void;
  }) => {
    if (!collaboratorIds?.length) {
      return;
    }

    const remoteModifiedAt = remoteItem.modifiedAt;
    const localModifiedAt = localItem.modifiedAt;

    const remoteIsNewer =
      new Date(remoteModifiedAt) > new Date(localModifiedAt);
    const isSameModifier =
      remoteItem.modifiedBy === userId || !remoteItem.modifiedBy;

    const isConflict = remoteIsNewer && !isSameModifier;

    if (isConflict) {
      dispatch(
        actionHubSetCollaborationState({
          conflictPopup: {
            context,
            remoteItem,
            onApproveLocalItem: onApproveLocalItem,
            onApproveRemoteItem: onApproveRemoteItem
          }
        })
      );
      logEvent(
        DID_SHOW_COLLABORATION_CONFLICT_POPUP,
        DID_SHOW_COLLABORATION_CONFLICT_POPUP,
        {
          itemId,
          localItemModifiedAt: localModifiedAt,
          remoteItemModifiedAt: remoteModifiedAt,
          context
        }
      );
    } else {
      onApproveLocalItem();
    }
    logEvent(
      DID_CHECK_FOR_COLLABORATION_CONFLICT,
      DID_CHECK_FOR_COLLABORATION_CONFLICT,
      {
        itemId,
        isConflict,
        context
      }
    );
  };

  const handleRemoveAllCollaborators = ({
    onSuccess,
    onFailed,
    itemId
  }: {
    onSuccess: () => void;
    onFailed?: (e?: any) => void;
    itemId: string;
  }) => {
    loadCollaboratorsById({
      id: itemId,
      silent: true,
      onSuccess: ({ teams, users }) => {
        const hasTeams = teams?.length > 0;
        const hasUsers = users?.length > 0;

        let queue = [];

        if (hasTeams && hasUsers) {
          queue = [
            removeCollaborators(context, {
              itemId,
              scope: 'team',
              scopeDetails: teams
            }),
            removeCollaborators(context, {
              itemId,
              scope: 'user',
              scopeDetails: users
            })
          ];
        } else if (hasTeams) {
          queue = [
            removeCollaborators(context, {
              itemId,
              scope: 'team',
              scopeDetails: teams
            })
          ];
        } else {
          queue = [
            removeCollaborators(context, {
              itemId,
              scope: 'user',
              scopeDetails: users
            })
          ];
        }

        Promise.all(queue)
          .then(() => {
            onSuccess();
          })
          .catch((e) => onFailed?.(e));
      }
    });
  };

  React.useEffect(() => {
    setCollaboratorIds([]);
    if (itemId) {
      loadCollaboratorsById({ id: itemId });
    }
  }, [itemId]);

  React.useEffect(() => {
    if (!usersByBrand) {
      fetchUsersByBrand();
    }
  }, []);

  return {
    collaborators: collaboratorIds,
    loadCollaboratorsById,
    addCollaborator: handleAddCollaborator,
    removeCollaborator: handleRemoveCollaborator,
    removeAllCollaborators: handleRemoveAllCollaborators,
    getAllUsersAndTeams,
    getCollaborationsInfo,
    viewOnly,
    checkForConflict
  };
};

export const useCollaborationItems = ({
  context,
  keywords
}: {
  context: ICollaboratorContext;
  keywords: string;
}) => {
  const { user } = React.useContext(HubContext);
  const userId = getIdentityId() || user?.id;

  const { data, isLoading, refetch } = useQuery(
    ['collaborations', context, userId],
    () => getCollaborationsByUser({ userId, context })
  );

  const filteredData = data?.filter((item) =>
    JSON.stringify(item).includes(keywords)
  );

  return {
    data: filteredData,
    isLoading,
    reload: refetch
  };
};
