import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useUploadFile } from '../../../../components/hooks/fileUpload';
import { IFileToUpload } from '../../../../interfaces';
import Uploader from './Uploader';

const UploadFilesProgress = ({
  uploadFileList,
  onDelete,
  onCompleted
}: {
  uploadFileList: IFileToUpload[];
  onDelete: (key: string) => void;
  onCompleted: (key: string) => void;
}) => {
  const [show, setShow] = React.useState(true);
  const length = uploadFileList.length;
  const isSingleFile = length === 1;
  return (
    <div className={`FilesUpload ${show ? 'show' : ''}`}>
      <div className="heading">
        <div className="text-heading">
          <Spinner animation="border" size="sm" />
          <div>
            Uploading {length} file{!isSingleFile && 's'}...
          </div>
        </div>
        <button
          className={`btn ${show ? 'btn-dark' : 'btn-outline-light'}`}
          onClick={() => setShow(!show)}
        >
          {show ? 'minimize' : 'show'}
        </button>
      </div>
      <div className="files">
        {!!uploadFileList?.length && (
          <Uploader
            files={uploadFileList}
            onDelete={onDelete}
            onCompleted={onCompleted}
          />
        )}
      </div>
      <style jsx>{`
        .FilesUpload {
          position: fixed;
          bottom: 10px;
          left: 10px;
          width: 300px;
          background: red;
          z-index: 10;
          border-radius: 10px;
          background: ${show ? '#fff' : '#000'};
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          color: ${show ? '#000' : '#fff'};
        }
        .heading {
          position: relative;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 10px;
          justify-content: space-between;
        }
        .files {
          height: ${show ? `${length * 58 + 20}px` : `0px`};
          max-height: 500px;
          overflow: auto;
          transition: height 0.3s ease-in-out;
          padding: ${show ? 10 : 0}px;
          border-top: 1px solid ${show ? '#ccc' : 'transparent'};
        }
        .text-heading {
          display: flex;
          align-items: center;
        }
        .text-heading div {
          margin-left: 10px;
          position: relative;
          font-weight: 500;
        }
        .btn {
          padding: 0;
          font-size: 0.8em;
          width: 80px;
          border-radius: 100px;
        }
      `}</style>
    </div>
  );
};

const FileUploadProgressPanel = () => {
  const { uploadFileList, onUploadCompleted, onAbortUpload } = useUploadFile();

  const onCompleted = (key: string) => {
    onUploadCompleted(key);
  };

  const onDelete = (key: string) => {
    onAbortUpload(key);
  };

  if (!uploadFileList.length) return null;

  return <UploadFilesProgress {...{ uploadFileList, onCompleted, onDelete }} />;
};

export default FileUploadProgressPanel;
