import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AssetLibraryContext } from '.';
import AssetThumbnail from './AssetThumbnail';
import { EmptyPageIcon, FolderIcon, UploadIcon } from '../Common/HubIcons';
import { cloneDeep, debounce, isEmpty, last } from 'lodash';
import { IAsset } from '../../../interfaces';
import { logEvent } from '../../../analytics';
import {
  DID_CLICK_ASSET_THUMBNAIL,
  HUB_ASSET_LIBRARY_EVENT
} from '../../../utils/constants';
import { HubContext } from '../HubContext';
import { useAssetLibrary, useAssetLibraryFolder } from './hook';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { Button, Spinner } from 'react-bootstrap';
import {
  cacheUpdatedAsset,
  clearOutdatedCachedAsset,
  getConvertingStatus,
  saveAsset
} from '../../clientSideServices/assetLibrary';
import {
  actionHubAlertError,
  actionUpdateLibraryAsset
} from '../../../redux/actions';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import {
  FAVORITE_FOLDER,
  getLabelOfFolderKey,
  getLevelOfFolderKey,
  TRASH_FOLDER
} from '../../utils/folders';
import { assetBaseUrl, videoBaseUrl } from '../../../config';
export const ListAssets: React.FC<{
  onClickItem?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}> = ({ onClickItem }) => {
  const { brandId, user } = useContext(HubContext);

  const dispatch = useDispatch();
  const { folder: assetLibraryCategoriesFolder } = useAssetLibraryFolder(
    brandId,
    user?.id,
    false
  );
  const {
    selectedAssets,
    selectAsset,
    deselectAssets,
    setUploadPopupVisibility,
    setPopupPreviewVisibility,
    changeSearchOptions,
    openUploadPopup,
    searchOptions,
    activeCategory
  } = useContext(AssetLibraryContext);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files: File[], fileRejection?: any[]) => {
      if (fileRejection && !isEmpty(fileRejection)) {
        const err = fileRejection[0].errors?.[0];
        if (err) dispatch(actionHubAlertError(`Error: ${err.message}`));
      }
      if (!isEmpty(files)) setUploadPopupVisibility(true, files);
    },
    accept:
      '.png, .jpg, .jpeg , video/mp4, video/quicktime, image/svg+xml, .ttf,.woff,.woff2, .pptx, .pptm, .ppt'
  });
  useEffect(() => {
    return () => {
      clearOutdatedCachedAsset();
    };
  }, []);
  const debouncedSearch = useCallback(
    debounce(
      () => {
        changeSearchOptions({}, 'end');
      },
      500,
      { trailing: true }
    ),
    [user?.id, searchOptions]
  );
  const { assetLibrary } = useAssetLibrary(brandId, user?.id, false);

  const scrollContainerRef = useInfiniteScroll<HTMLDivElement>({
    loading: assetLibrary?.loading,
    hasNextPage: assetLibrary?.hasMore,
    onLoadMore: () => {
      debouncedSearch();
    }
  });

  const [listFolderMenuItemActive, setListFolderMenuItemActive] =
    useState<{ label: string; id: string; key: string }[]>();

  useEffect(() => {
    const isInTrashFolder = activeCategory?.[0] === TRASH_FOLDER;
    const isFavoriteFolder = activeCategory?.[0] === FAVORITE_FOLDER;
    if (isEmpty(activeCategory) || isInTrashFolder || isFavoriteFolder) {
      setListFolderMenuItemActive([]);
      return;
    }

    let listChildFolder = [];

    activeCategory.forEach((activeCate) => {
      // use key as id for FAVORITE_FOLDER, UNCATEGORIZED
      const activeKey = assetLibraryCategoriesFolder?.keys.find(
        (key) => key.id === activeCate || key.key === activeCate
      );

      const directChildren = assetLibraryCategoriesFolder?.keys?.filter(
        (item) =>
          item.key.startsWith(activeKey.key) &&
          item.id !== activeKey.id &&
          getLevelOfFolderKey(item.key) - getLevelOfFolderKey(activeKey.key) ===
            1
      );

      listChildFolder = listChildFolder.concat(directChildren);
    });

    setListFolderMenuItemActive(listChildFolder);
  }, [activeCategory]);

  const handleUpdateAssetTitle = (
    updateAsset: IAsset,
    beforeUpdateAsset: IAsset
  ) => {
    cacheUpdatedAsset(updateAsset, beforeUpdateAsset);
    return saveAsset(updateAsset).then(() => {
      dispatch(actionUpdateLibraryAsset(updateAsset));
    });
  };

  return (
    <div
      {...getRootProps()}
      className="d-flex w-100 h-100 position-relative drop-zone"
    >
      <div className="flex-column align-items-center justify-content-center w-100 h-100 position-absolute overlay-drag-content">
        <div className="mb-4">
          <UploadIcon />
        </div>
        <span>Drop files to upload</span>
      </div>
      {isDragActive && (
        <div className="d-flex w-100 h-100 position-absolute drag-active"></div>
      )}
      <div
        className="list-assets h-100 w-100 pb-4 h-100"
        onClick={(e) => {
          deselectAssets();
          e.stopPropagation();
        }}
      >
        {!isEmpty(listFolderMenuItemActive) && (
          <div className="list-assets-header d-flex flex-wrap">
            {listFolderMenuItemActive?.map((folder) => {
              return (
                <div
                  key={folder.id}
                  className="block-folder-item mt-2 d-flex align-items-center"
                  onClick={() => {
                    changeSearchOptions({ categories: [folder.id] });
                  }}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <FolderIcon />
                  </div>
                  <span className="ml-2">
                    {getLabelOfFolderKey(folder?.key)}
                  </span>
                </div>
              );
            })}
          </div>
        )}

        {!assetLibrary?.loading && assetLibrary?.total === 0 && (
          <div className="empty-results">
            <div
              className="d-flex justify-content-center mx-auto mb-2"
              style={{ width: 300, height: 250, paddingLeft: 40 }}
            >
              <EmptyPageIcon />
            </div>
            <div className="text-center title">
              {(searchOptions.keywords || []).length === 0
                ? 'This category is empty'
                : 'Sorry, there are no results match your search criteria'}
            </div>
            {(searchOptions.keywords || []).length === 0 && (
              <span className="mb-2">
                Start to upload assets to this category
              </span>
            )}
            <div className="d-flex align-items-center justify-content-center mt-4">
              <Button
                variant="dark"
                className="rounded-pill d-inline-block"
                onClick={() => {
                  setUploadPopupVisibility(true);
                }}
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  height: 40,
                  padding: '0 20px'
                }}
              >
                Upload
              </Button>
            </div>
          </div>
        )}
        <div className="d-flex w-100 flex-wrap" ref={scrollContainerRef}>
          {assetLibrary?.data?.map((assetItem) => {
            const originalAsset = cloneDeep(assetItem);
            return (
              <AssetThumbnail
                key={assetItem.id}
                item={assetItem}
                onClick={(asset, e) => {
                  onClickItem?.(e);
                  logEvent(HUB_ASSET_LIBRARY_EVENT, DID_CLICK_ASSET_THUMBNAIL, {
                    item: {
                      id: asset.id,
                      originalURL: asset.originalURL,
                      assetType: asset.assetType
                    }
                  });
                  selectAsset(asset);
                }}
                selected={selectedAssets?.includes(assetItem.id)}
                onUpdateTitle={(updateAsset) =>
                  handleUpdateAssetTitle(updateAsset, originalAsset)
                }
                selectedAssets={selectedAssets}
                onFix={(item) => {
                  const isVideo = item.assetType.includes('video');
                  const baseCNDUrl = isVideo ? videoBaseUrl : assetBaseUrl;
                  return getConvertingStatus(brandId, item.id).then(
                    async (result) => {
                      const convertedData = result?.[0];

                      if (
                        !isEmpty(convertedData) &&
                        convertedData.metadata.converted
                      ) {
                        const previewKey = convertedData.pages.find(
                          (k) =>
                            /\.(jpg|jpeg|png|svg)$/.test(k) &&
                            last(k.split('/')).includes('preview')
                        );
                        const convertedKey = convertedData.pages.find((k) =>
                          k.includes(
                            !isVideo ? convertedData?.pages[0] : 'index.m3u8'
                          )
                        );
                        item.assetPreviewURL = `${baseCNDUrl}/${
                          previewKey || convertedKey
                        }`;
                        item.convertedURL = `${baseCNDUrl}/${convertedKey}`;
                        return saveAsset(item).then(() => item);
                      }
                    }
                  );
                }}
                onClickView={(item) => {
                  selectAsset(item, true);
                  setPopupPreviewVisibility(true);
                }}
              />
            );
          })}
        </div>
        {assetLibrary?.loading && (
          <div className="d-flex justify-content-center w-100 p-2">
            <Spinner animation="border" variant="secondary" />
          </div>
        )}
        <div>
          <input {...getInputProps()} disabled={openUploadPopup} />
        </div>
      </div>
      <style jsx>{`
        .list-assets {
          position: relative;
          overflow: auto;
          padding: 0 4px;
          overscroll-behavior: none;
        }
        .list-assets .empty-results {
          width: 100%;
          font-weight: 600;
          font-size: 20px;
          margin: auto;
          text-align: center;
          margin-top: 13vh;
        }
        .empty-results span {
          white-space: nowrap;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #d9d9d9;
        }
        .empty-results .title {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #bebebe;
        }
        .drop-zone:focus-visible {
          outline: none;
        }
        .list-assets-header {
          margin-left: 14px;
          margin-bottom: 30px;
        }
        .list-assets-header .block-folder-item {
          margin-top: -8px;
        }
        .list-assets-header span {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          color: #000000;
        }
        .block-folder-item {
          width: 180px;
          padding: 10px 11px;
          background: #f6f6f6;
          border-radius: 3px;
          cursor: pointer;
        }
        .block-folder-item:not(:last-child) {
          margin-right: 16px;
        }
        .block-folder-item > div {
          width: 22px;
          height: 22px;
          background: #d8d8d8;
          border-radius: 3px;
        }
        .drop-zone .drag-active {
          top: 0;
          right: 0;
          background: #eaeaea;
          opacity: 0.6;
          z-index: 10;
        }
        .overlay-drag-content {
          top: 0;
          right: 0;
          z-index: 11;
          display: ${isDragActive ? 'flex' : 'none'};
        }
        .overlay-drag-content span {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          color: #000000;
        }
        @media screen and (min-width: 768px) {
          .block-folder-item {
            width: 200px;
          }
        }
        @media screen and (min-width: 992px) {
          .block-folder-item {
            width: 232px;
          }
        }
      `}</style>
    </div>
  );
};

export default ListAssets;
