import { format } from 'date-fns';
import React from 'react';

const FormattedDate = ({
  dateString,
  emptyDateLabel = '',
  label,
  dateFormat = 'MMM dd, yyyy - p'
}: {
  dateString?: string;
  emptyDateLabel?: string;
  label?: string;
  dateFormat?: string;
}) => {
  return (
    <>
      {label}
      {dateString ? format(new Date(dateString), dateFormat) : emptyDateLabel}
    </>
  );
};

export default FormattedDate;
