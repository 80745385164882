import React from 'react';
import { Spinner } from 'react-bootstrap';
import { logEvent } from '../../../../analytics';
import { SBMetadata } from '../../../../interfaces';
import {
  DID_FAIL_TO_UPLOAD_FILE,
  DID_UPLOAD_FILE
} from '../../../../utils/constants';
import { uploadToS3 } from '../../../clientSideServices/session';
import { isSilentVideo } from '../../../clientSideServices/storybook';
import CloseButton from './../../Common/CloseButton';

export interface UploadProgress {
  progressPercentage: number;
  error?: boolean;
}

const UploaderItem = ({
  s3Key,
  file,
  name,
  onDelete,
  bucket,
  metadata,
  onCompleted
}: {
  s3Key: string;
  name: string;
  file: File;
  onDelete?: () => void;
  bucket: string;
  metadata: SBMetadata;
  onCompleted?: () => void;
}) => {
  const [progress, setProgress] = React.useState<UploadProgress>();
  const [confirmAbort, setConfirmAbort] = React.useState(false);

  const uploadFilesTos3 = async () => {
    if (!s3Key) return;
    setProgress({ ...(progress || {}), progressPercentage: 1 });
    const isVideo = /video.*/.test(file?.type);
    let isSilent;
    if (isVideo) {
      try {
        isSilent = (await isSilentVideo(file)).toString();
      } catch (error) {
        console.error(error);
      }
    }
    uploadToS3(
      file,
      bucket,
      s3Key,
      (progressPercentage) => {
        setProgress({ ...(progress || {}), progressPercentage });
      },
      undefined,
      undefined,
      isSilent ? { silence: isSilent } : undefined
    )
      .then(() => {
        logEvent(DID_UPLOAD_FILE, DID_UPLOAD_FILE, {
          key: s3Key,
          bucket
        });
        onCompleted?.();
        return;
      })
      .catch((error) => {
        console.error(error);
        logEvent(DID_FAIL_TO_UPLOAD_FILE, DID_FAIL_TO_UPLOAD_FILE, {
          key: s3Key,
          bucket,
          error
        });
        setProgress({ ...progress, error: true });
      });
  };

  React.useEffect(() => {
    uploadFilesTos3();
  }, []);

  const converting = progress?.progressPercentage === 100;

  return (
    <div className="UploaderItem">
      <div
        className={`progress-container ${progress?.error ? 'error' : ''} ${
          converting ? 'converting' : ''
        }`}
      >
        <div className="label-container">
          <label>{name}</label>
          {converting && (
            <span>{metadata?.conversionerror ? 'ERROR' : 'Converting...'}</span>
          )}
        </div>
        {converting && !progress?.error && !metadata?.conversionerror && (
          <div className="spinner-container">
            <Spinner animation="border" size="sm" />
          </div>
        )}
        {!converting && !progress?.error && (
          <div
            className="progress"
            style={{ height: '10px', background: '#efefef' }}
          >
            <div
              className={`progress-bar ${
                progress?.error ? 'bg-danger' : 'bg-dark'
              }`}
              role="progressbar"
              style={{ width: `${progress?.progressPercentage}%` }}
            />
          </div>
        )}
        {metadata?.conversionerror && (
          <>
            <div className="close-wrapper">
              <CloseButton onClick={onDelete} rounded={true} />
            </div>
          </>
        )}
        {progress?.error && (
          <>
            <label>
              <span className="text-danger">
                Upload failed.
              </span>
            </label>
          </>
        )}
      </div>

      {progress?.error && (
        <div className="abort-button retry-button">
          <div>
            <button
              className="btn btn-round btn-sm btn-dark"
              onClick={onDelete}
            >
              Close
            </button>
          </div>
        </div>
      )}
      {!progress?.error && !metadata?.conversionerror && (
        <div className="abort-button">
          {!confirmAbort && (
            <div>
              <button
                className="btn btn-round btn-abort btn-sm btn-dark"
                onClick={() => setConfirmAbort(true)}
              >
                Abort
              </button>
            </div>
          )}
          {confirmAbort && (
            <div>
              <button
                className="btn btn-round btn-sm btn-outline-dark"
                onClick={() => setConfirmAbort(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-round btn-sm btn-dark"
                onClick={onDelete}
              >
                OK
              </button>
            </div>
          )}
        </div>
      )}

      <style jsx>{`
        .UploaderItem {
          font-size: 16px;
          position: relative;
        }
        span {
          font-size: 0.8em;
        }
        .progress-container {
          padding: 10px;
          border-radius: 5px;
          background: #fff;
          position: relative;
          border-bottom: 1px solid #efefef;
        }
        .progress-container.error {
          background: #ffdede;
        }
        label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
        label:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          font-size: 0.8em;
        }
        .close-wrapper {
          position: absolute;
          right: 0;
          top: 0;
        }
        .close-wrapper :global(svg) {
          width: 24px;
          height: auto;
        }
        .converting {
          display: flex;
          padding-bottom: 4px;
          justify-content: space-between;
        }
        .converting .label-container {
          width: 80%;
        }
        .converting label {
          margin-bottom: 0;
          display: block;
          line-height: 1;
          color: ${metadata?.conversionerror ? '#f00' : '#000'};
        }
        .converting span {
          font-size: 12px;
        }
        .converting .spinner-container {
          margin-top: 2px;
        }
        .abort-button {
          position: absolute;
          z-index: 1;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background: ${confirmAbort
            ? `rgba(255, 255, 255, 0.5)`
            : 'transparent'};
          display: flex;
          align-items: center;
        }
        .btn-abort {
          display: none;
        }
        .abort-button:hover {
          background: rgba(255, 255, 255, 0.5);
        }
        .abort-button:hover .btn-abort {
          display: inline-block;
        }
        .abort-button button {
          width: 100px;
          margin: 0 5px;
        }
        .abort-button div {
          margin: auto;
        }
        .retry-button .btn {
          display: none;
        }
        .retry-button:hover .btn {
          display: inline-block;
        }
      `}</style>
    </div>
  );
};

export default UploaderItem;
