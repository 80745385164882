import React from 'react';

interface CheckBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
  invalidMessage?: string;
}

const CheckBox = React.forwardRef(
  (props: CheckBoxProps, ref: React.Ref<HTMLInputElement>) => {
    return (
      <div id={props.id} className={`${props.className || ''} py-1`}>
        <label
          className="checkbox-container d-flex align-items-center"
          htmlFor={props.id}
        >
          {props?.label && <span className="label">{props.label} </span>}
          <input {...props} ref={ref} disabled />
          <span className="checkmark"></span>
        </label>

        <style jsx>{`
          label {
            margin-bottom: 0;
          }
          .checkbox-container {
            display: block;
            position: relative;
            padding-left: 24px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            margin-bottom: 0
          }
          /* Hide the browser's default checkbox */
          .checkbox-container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
          .label {
            font-size: 12px;
            margin-bottom: 0px;
          }
          /* Create a custom checkbox */
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 15px;
            width: 15px;
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 4px;
          }
          .checkbox-container input:checked ~ .checkmark {
            border-color: #D8D8D8;
          }

          /* Create the checkmark/indicator (hidden when not checked) */
          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }

          /* Show the checkmark when checked */
          .checkbox-container input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the checkmark/indicator */
          .checkbox-container .checkmark:after {
            left: 4px;
            top: 0px;
            width: 6px;
            height: 10px;
            border: solid #000000;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        `}</style>
      </div>
    );
  }
);

CheckBox.displayName = 'CheckBox';

export default CheckBox;
