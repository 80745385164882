import React from 'react';
import { BsX } from 'react-icons/bs';

const CloseButton = ({
  size = 32,
  background = 'transparent',
  onClick,
  rounded,
  color = '#000000'
}: {
  onClick: () => void;
  rounded?: boolean;
  color?: string;
  background?: string;
  size?: number;
}) => {
  return (
    <button className="btn with-shadow" onClick={onClick}>
      <BsX size={size} color={color} />
      <style jsx>{`
        .btn {
          padding: 0;
          border: ${rounded ? `1px solid ${color}` : 'none'};
          border-radius: 50%;
          background-color: ${background};
        }
      `}</style>
    </button>
  );
};

export default CloseButton;
