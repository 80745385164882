import React from 'react';
import Dropzone from 'react-dropzone';
import DropInfo from './DropInfo';

const DropZone = ({
  onDrop,
  fileTypes,
  multiple,
  children,
  showDialog,
  onClose,
  dropZonePlaceholder,
  disabled,
  showFileListWhileDragging
}: {
  onDrop: (files: File[]) => void;
  fileTypes?: string;
  multiple?: boolean;
  children?: React.ReactNode;
  showDialog?: boolean;
  onClose?: () => void;
  dropZonePlaceholder?: React.ReactNode;
  disabled?: boolean;
  showFileListWhileDragging?: boolean;
}) => {
  const dropZoneRef = React.createRef<any>();

  const openDialog = () => {
    if (dropZoneRef.current) {
      dropZoneRef.current.open();
    }
  };

  React.useEffect(() => {
    if (showDialog) {
      openDialog();
    }
  }, [showDialog]);

  return (
    <Dropzone
      noClick
      ref={dropZoneRef}
      onDrop={onDrop}
      accept={fileTypes}
      multiple={multiple}
      disabled={disabled}
    >
      {({ getRootProps, isDragAccept, isDragActive, getInputProps }) => {
        return (
          <div
            className={`dropzone d-flex flex-column ${
              isDragAccept ? 'accepted' : ''
            } ${isDragActive ? 'active' : ''}`}
            {...getRootProps({ className: 'dropzone' })}
          >
            <input {...getInputProps()} className="drop-zone-input" />
            <div className="item-container d-flex flex-column h-100">
              {(!isDragActive || showFileListWhileDragging) && children}
              <DropInfo
                isDragActive={isDragActive}
                compactMode={!!children}
                multipleUpload={multiple}
                onClick={openDialog}
                onClose={onClose}
                dropZonePlaceholder={dropZonePlaceholder}
                acceptedTypes={fileTypes}
              />
            </div>
            <style jsx>{`
              .dropzone {
                border: 3px dashed #ddd;
                padding: 10px;
                border-radius: 10px;
                width: 100%;
                height: 100%;
                background: #efefef !important;
                position: relative;
                outline: none;
              }

              .dropzone.accepted {
                border-color: #28a746;
              }
              .dropzone.rejected {
                border-color: red;
              }

              .dropzone.active {
                border-color: #28a746;
                background: #edfff2 !important;
              }

              .upload-error-message {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
              }
              .item-container {
                margin: auto;
                width: 80%;
                max-width: 600px;
              }
            `}</style>
          </div>
        );
      }}
    </Dropzone>
  );
};

export default DropZone;
