import React from 'react';
import Select, {
  components,
  ControlProps,
  IndicatorsContainerProps,
  OptionProps
} from 'react-select';
import CreatableSelect from 'react-select/creatable';

export type BaseOption = {
  value: string;
  label: string;
};

export function createMultiSelectControl<T = BaseOption>(
  renderLabel?: (props: ControlProps<T, true>) => React.ReactNode
) {
  if (!renderLabel) {
    return components.Control;
  }
  return (props: ControlProps<T, true>) => {
    return (
      <div>
        {renderLabel(props)}
        <components.Control {...props} />
      </div>
    );
  };
}

export function Control<T, isMulti extends boolean = boolean>(
  props: ControlProps<T, isMulti>
) {
  if (!props.isMulti) {
    <components.Control {...props} />;
  }
  return (
    <div className="ins-select-control">
      <label
        htmlFor={props.selectProps.inputId}
        style={{
          position: 'absolute',
          marginBottom: 4,
          top: 0
        }}
        className={`${props.isFocused ? 'd-none' : ''}`}
      >
        {`${props.selectProps['aria-label'] || ''} ${
          props.isMulti && Object.keys(props.selectProps.value || {})?.length
            ? `(${Object.keys(props.selectProps.value || {})?.length})`
            : ''
        }`}
      </label>
      <components.Control {...props} />
      <style jsx>{`
        .ins-select-control :global(.ins-select-indicator) {
          position: absolute;
          top: ${props.isFocused ? '-3px' : '-10px'};
          right: ${props.isFocused ? '-5px' : '-2px'};
        }
      `}</style>
    </div>
  );
}

export function CreatableControl<T, isMulti extends boolean = boolean>(
  props: ControlProps<T, isMulti>
) {
  if (!props.isMulti) {
    <components.Control {...props} />;
  }
  return (
    <div className="ins-select-control">
      <label
        htmlFor={props.selectProps.inputId}
        style={{
          position: !props.isFocused ? 'absolute' : 'static',
          marginBottom: 4,
          top: 0
        }}
        className={`${props.isFocused ? 'd-none' : ''}`}
      >
        {props.selectProps['aria-label']}
      </label>
      <components.Control {...props} />
      <style jsx>{`
        .ins-select-control :global(.ins-select-indicator) {
          position: absolute;
          top: ${props.isFocused ? '-3px' : '-10px'};
          right: ${props.isFocused ? '0px' : '-2px'};
        }
      `}</style>
    </div>
  );
}

export function IndicatorsContainer<T, isMulti extends boolean = boolean>(
  props: IndicatorsContainerProps<T, isMulti>
) {
  if (!props.isMulti) {
    return <components.IndicatorsContainer {...props} />;
  }
  return (
    <div className="ins-select-indicator">
      <components.IndicatorsContainer {...props} />
    </div>
  );
}

export function createSelectOption<
  T = BaseOption,
  isMulti extends boolean = boolean
>(renderOptionLabel?: (props: OptionProps<T, isMulti>) => React.ReactNode) {
  if (!renderOptionLabel) {
    return components.Option;
  }

  return (props: OptionProps<T, isMulti>) => {
    return (
      <components.Option {...props}>
        {renderOptionLabel(props)}
      </components.Option>
    );
  };
}

export const InsSelect: typeof Select = (props) => {
  return (
    <Select
      {...props}
      theme={(theme) => {
        theme.colors.primary = '#8F6599';
        return theme;
      }}
      menuPlacement="auto"
      openMenuOnFocus
      components={{
        Control,
        IndicatorsContainer,
        ...(props.components || {})
      }}
      styles={{
        control: (style) => {
          style.border = 'none';
          style.boxShadow = 'none';
          style.borderBottom = `1px solid ${style.borderColor}`;
          style.backgroundColor = 'unset';
          style.borderRadius = 0;
          style.height = 34;
          style.minHeight = 34;
          return {
            ...style
          };
        },
        option: (provided, state) => {
          delete provided.backgroundColor;
          return {
            ...provided,
            color: state.isDisabled ? '#ccc' : '#000000',
            fontWeight: state.isSelected ? 'bold' : 'normal',
            padding: state.isMulti ? '0 8px' : '4px 8px',
            ':active': {
              backgroundColor: '#8a8888',
              color: '#ffffff'
            }
          };
        },
        valueContainer: (styles) => {
          return {
            ...styles,
            maxHeight: 100,
            overflowY: 'auto',
            padding: '0'
          };
        },
        multiValueRemove: (styles) => {
          return {
            ...styles,
            backgroundColor: '#8A8888',
            color: '#ffffff',
            ':hover': {
              backgroundColor: 'unset',
              cursor: 'pointer'
            }
          };
        },
        input: (styles) => {
          return {
            ...styles,
            margin: 0,
            marginRight: 30,
            overflow: 'hidden'
          };
        },
        multiValue: (styles) => {
          return {
            ...styles,
            display: 'none'
          };
        },
        dropdownIndicator: (style, state) => {
          return {
            ...style,
            paddingRight: 0,
            marginRight: state.isFocused && '-5px',
            transform: state.isFocused && 'rotate(180deg)'
          };
        },
        indicatorSeparator: () => {
          return {
            display: 'none'
          };
        },

        ...(props.styles || {})
      }}
    />
  );
};

export const InsCreatableSelect: typeof CreatableSelect = (props) => {
  return (
    <CreatableSelect
      {...props}
      theme={(theme) => {
        theme.colors.primary = '#8F6599';
        return theme;
      }}
      components={{
        Control: CreatableControl,
        IndicatorsContainer,
        ...(props.components || {})
      }}
      styles={{
        dropdownIndicator: (style, state) => {
          return {
            ...style,
            paddingRight: 0,
            marginRight: state.isFocused && '-10px',
            transform: state.isFocused && 'rotate(180deg)'
          };
        },

        option: (provided, state) => {
          delete provided.backgroundColor;
          return {
            ...provided,
            color: state.isDisabled ? '#ccc' : '#000000',
            fontWeight: state.isSelected ? 'bold' : 'normal',
            padding: state.isMulti ? '0 8px' : '4px 8px',
            backgroundColor: state.isFocused ? '#f0f0f0' : 'white',
            ':active': {
              backgroundColor: '#8a8888',
              color: '#ffffff'
            }
          };
        },
        indicatorSeparator: () => {
          return {
            display: 'none'
          };
        },
        multiValue: (styles) => {
          return {
            ...styles,
            display: 'none'
          };
        },
        valueContainer: (styles) => {
          return {
            ...styles,
            maxHeight: 100,
            overflowY: 'auto',
            padding: '0'
          };
        },
        input: (styles) => {
          return {
            ...styles,
            margin: 0,
            marginRight: 30,
            overflow: 'hidden'
          };
        },
        multiValueRemove: (styles) => {
          return {
            ...styles,
            backgroundColor: '#8A8888',
            color: '#ffffff',
            ':hover': {
              backgroundColor: 'unset',
              cursor: 'pointer'
            }
          };
        },
        control: (style) => {
          style.border = 'none';
          style.boxShadow = 'none';
          style.borderBottom = `1px solid ${style.borderColor}`;
          style.backgroundColor = 'unset';
          style.borderRadius = 0;
          style.height = 34;
          style.minHeight = 34;

          return {
            ...style
          };
        },
        ...(props.styles || {})
      }}
    />
  );
};

// eslint-disable-next-line unused-imports/no-unused-vars
export const SingleSelect: typeof Select = ({ isMulti, ...props }) => {
  return (
    <Select
      {...props}
      theme={(theme) => {
        theme.colors.primary = '#8F6599';
        return theme;
      }}
      openMenuOnFocus
      components={{
        Control: components.Control,
        ...(props.components || {})
      }}
      styles={{
        container: (provided) => ({
          ...provided,
          minWidth: 110,
          marginLeft: 5
        }),
        control: (provided, state) => ({
          ...provided,
          border: `solid 1px ${
            state.isFocused && state.menuIsOpen ? '#000000' : '#A8A8A8'
          }`,
          borderBottom: state.isFocused && state.menuIsOpen ? 'none' : '',
          borderRadius: 14,
          borderBottomLeftRadius: state.isFocused && state.menuIsOpen ? 0 : 14,
          borderBottomRightRadius: state.isFocused && state.menuIsOpen ? 0 : 14,
          height: 30,
          fontSize: 12,
          minHeight: 30,
          transition: 'none',
          boxShadow: 'none',
          ':hover': {
            borderColor: '#000000'
          }
        }),
        menu: (provided) => {
          return {
            ...provided,
            marginTop: 0,
            zIndex: 10,
            border: 'solid 1px #000000',
            borderTop: 'none',
            borderRadius: 0,
            borderBottomLeftRadius: 14,
            borderBottomRightRadius: 14,
            boxShadow: 'none'
          };
        },
        indicatorSeparator: () => {
          return {
            display: 'none'
          };
        },
        indicatorsContainer: (base) => ({
          ...base,
          marginTop: '-4px',
          padding: 0
        }),
        dropdownIndicator: (base) => ({
          ...base,
          paddingLeft: 4
        }),
        valueContainer: (styles) => {
          return {
            ...styles,
            maxHeight: 100,
            overflowY: 'auto',
            padding: '2px 0px 2px 10px',
            fontWeight: 400,
            marginTop: '-4px',
            color: '#A8A8A8'
          };
        },
        singleValue: (styles) => {
          return {
            ...styles,
            color: '#A8A8A8'
          };
        },
        option: (provided, state) => {
          delete provided.backgroundColor;
          return {
            ...provided,
            color: '#000000',
            fontSize: 12,
            fontWeight: state.isSelected ? 'bold' : 'normal',
            padding: '4px 10px;',
            height: 25,
            ':active': {
              backgroundColor: '#8a8888',
              color: '#ffffff'
            }
          };
        },
        ...(props.styles || {})
      }}
    />
  );
};
