import { capitalize, isEmpty, last } from 'lodash';
import React, { useRef, useState } from 'react';
import { IFolderKey } from '../../../interfaces';
import { BsChevronRight, BsChevronDown } from 'react-icons/bs';
import { CircleIcon, EmptySquare, FilledCircle, FilledSquare, MenuMoreIcon } from '../Common/HubIcons';
import ToolTipWrapper from '../../../components/Common/ToolTipWrapper';
import { logEvent } from '../../../analytics';
import {
  HUB_ASSET_LIBRARY_EVENT,
  DID_CLICK_CATEGORY
} from '../../../utils/constants';
import { UNCATEGORIZED_FOLDER } from '../../utils/folders';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const LEVEL_MENU_ICON = [
  <FilledCircle />,
  <CircleIcon />,
  <FilledSquare />,
  <EmptySquare />
];

export const AssetCategoriesUpdateMenu = ({
  onDelete,
  onEdit,
  onAdd,
  onClose
}: {
  onDelete: () => void;
  onEdit: () => void;
  onAdd: () => void;
  onClose: () => void;
}) => {
  const handleEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onEdit();
    onClose();
  };

  const handleAdd = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onAdd();
    onClose();
  };

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onDelete();
    onClose();
  };

  return (
    <div className="control-menu box-menu-asset text-left">
      <button
        className="list-group-item list-group-item-action"
        onClick={handleAdd}
      >
        Add
      </button>
      <button
        className="list-group-item list-group-item-action"
        onClick={handleEdit}
      >
        Edit
      </button>
      <button
        className="list-group-item list-group-item-action"
        onClick={handleDelete}
      >
        Delete
      </button>
      <style>{`
        .box-menu-asset {
          padding: 6px 17px;
        }
        .box-menu-asset button {
          padding: 6px 0px 8px 0px !important;
        }
      `}</style>
    </div>
  );
};

export const TreeMenuItem: React.FC<{
  label: string;
  level: number;
  item: IFolderKey;
  parent: IFolderKey;
  childrenItems: IFolderKey[];
  activeKey: string[];
  onClick: (item: IFolderKey, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onEdit?: (item: IFolderKey, parent: IFolderKey) => void;
  onAdd?: (item: IFolderKey) => void;
  onDelete: (item: IFolderKey) => void;
  onDrop?: (item: IFolderKey, transferData: DataTransfer) => void;
  isItemDisable?: (item: IFolderKey) => boolean;
  sort?: (items: IFolderKey[]) => IFolderKey[];
}> = ({
  label,
  childrenItems,
  level,
  item,
  parent,
  activeKey,
  onClick,
  onEdit,
  onAdd,
  onDelete,
  isItemDisable,
  onDrop,
  sort
}) => {
  const [{ expanded }, setItemState] = useState<{ expanded }>({
    expanded: false
  });
  const containerRef = useRef<HTMLDivElement>();

  const levelIcon = LEVEL_MENU_ICON[level - 1] || (level%2 === 1 ? LEVEL_MENU_ICON[2] : LEVEL_MENU_ICON[3]);
  const isActive = activeKey.includes(item.id);
  let directChildren = childrenItems.filter((c) => {
    const childLevel = c.key.split('/').filter((path) => !!path).length;
    return childLevel === level + 1;
  });
  if (sort) {
    directChildren = sort(directChildren);
  }
  const hasChildren = !isEmpty(directChildren);
  const [isShowMenuIcon, setIsShowMenuIcon] = useState(false);
  const [openTooltipMenu, setOpenTooltipMenu] = useState(false);
  const isDisabled = isItemDisable?.(item) || !onEdit;
  return (
    <div className={`menu-item ${hasChildren? 'parent-category' : ''} ${activeKey} ${isActive ? 'active' : ''}`}>
      <div
        className="menu-item--label"
        id={item.id}
        ref={containerRef}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.stopPropagation();
          setItemState((prev) => ({ ...prev, expanded: true }));
          logEvent(HUB_ASSET_LIBRARY_EVENT, DID_CLICK_CATEGORY, {
            category: item
          });
          onClick(item, e);
        }}
        style={{ paddingLeft: 8 * level }}
        onMouseEnter={() => {
          setIsShowMenuIcon(true);
        }}
        onMouseLeave={() => {
          setIsShowMenuIcon(false);
          setOpenTooltipMenu(false);
        }}
        onDragOver={(e) => {
          e.preventDefault();
          if (hasChildren) {
            setItemState((prev) => ({ ...prev, expanded: true }));
          }
          containerRef.current?.classList?.add('drag-active');
        }}
        onDragLeave={() => {
          containerRef.current?.classList?.remove('drag-active');
        }}
        onDrop={(e) => {
          onDrop?.(item, e.dataTransfer);
          containerRef.current?.classList?.remove('drag-active');
        }}
      >
        <span
          className={`controls ${expanded? 'collapse-btn': 'expand-btn'}`}
          onClick={(e) => {
            e.stopPropagation();
            setItemState((prev) => ({ ...prev, expanded: !prev.expanded }));
          }}
        >
          {hasChildren &&
            (expanded ? (
              <BsChevronDown className="collapse-icon" />
            ) : (
              <BsChevronRight className="expand-icon" />
            ))}
        </span>
        <span className="level-icon">{levelIcon} </span>
        <OverlayTrigger
          placement="top"
          delay={{ show: 200, hide: 200 }}
          overlay={<Tooltip id={label}>{label}</Tooltip>}
        >
          <span
            className={`pr-3 category-text ${
              level > 1 ? 'font-weight-normal' : ''
            }`}
          >
            {label}
          </span>
        </OverlayTrigger>

        {isShowMenuIcon && !isDisabled && (
          <div
            className="flex align-items-center px-2 py-2"
            id={`menu-item-${label?.toLowerCase()}`}
            onClick={(e) => {
              e.stopPropagation();
              setOpenTooltipMenu(true);
            }}
          >
            <MenuMoreIcon />
          </div>
        )}
        {openTooltipMenu && (
          <ToolTipWrapper
            onClose={() => setOpenTooltipMenu(false)}
            parentId={`menu-item-${label?.toLowerCase()}`}
            width={120}
            xPosition="right"
            hideCloseButton
          >
            <AssetCategoriesUpdateMenu
              onClose={() => {
                setOpenTooltipMenu(false);
              }}
              onDelete={() => onDelete(item)}
              onEdit={() => onEdit(item, parent)}
              onAdd={() => onAdd?.(item)}
            />
          </ToolTipWrapper>
        )}
      </div>
      {expanded && hasChildren && (
        <div className="menu-item-children">
          {directChildren.map((childItem) => {
            const childrenOfChildItem = childrenItems.filter((i) => {
              return i.key.includes(childItem?.key.replace(/\/$/, '') + '/');
            });

            const label = last(childItem.key.replace(/\/$/, '').split('/'));
            return (
              <TreeMenuItem
                key={childItem.key}
                item={childItem}
                label={label}
                parent={item}
                level={level + 1}
                activeKey={activeKey}
                onClick={onClick}
                childrenItems={childrenOfChildItem}
                onEdit={onEdit}
                onDelete={onDelete}
                onDrop={onDrop}
                onAdd={onAdd}
                isItemDisable={isItemDisable}
              />
            );
          })}
        </div>
      )}
      <style jsx>{`
        .menu-item--label {
          font-size: 12px;
          font-weight: 600;
          line-height: 14.63px;
          cursor: pointer;
          height: 31px;
          width: 100%;
          display: flex;
          align-items: center;
          border: 1px;
        }
        .menu-item--label:hover {
          background-color: #f5f5f5;
        }
        .menu-item.active > .menu-item--label {
          background-color: #d8d8d8;
        }
        .menu-item--label :global(.collapse-icon),
        .menu-item--label :global(.expand-icon) {
          cursor: pointer;
        }
        .menu-item--label .level-icon {
          margin-right: 4px;
          margin-left: 4px;
          min-width: 12px;
        }
        .menu-item--label .controls {
          min-width: 12px;
          margin-right: 4px;
        }
        .drag-active {
          border: 1px solid #8f6599;
          background-color: rgba(143, 101, 153, 0.2);
        }
        .category-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `}</style>
    </div>
  );
};

export const CategoryTreeMenu: React.FC<{
  items: IFolderKey[];
  activeKey?: string[];
  onItemClick: (item: IFolderKey, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onEdit?: (item: IFolderKey, parent: IFolderKey) => void;
  onDelete: (item: IFolderKey) => void;
  onAdd?: (item: IFolderKey) => void;
  isItemDisable?: (item: IFolderKey) => boolean;
  onDrop?: (item: IFolderKey, transferData: DataTransfer) => void;
  sort?: (items: IFolderKey[]) => IFolderKey[];
}> = ({
  items,
  activeKey,
  onItemClick,
  onEdit,
  onDelete,
  isItemDisable,
  onDrop,
  sort,
  onAdd
}) => {
  let rootItems = items?.filter(
    (i) => i?.key?.split('/').filter((path) => path).length === 1
  );
  if (sort) {
    rootItems = sort(rootItems);
  }
  return (
    <div>
      {rootItems?.map((item) => {
        const childrenItems = items?.filter(
          (i) =>
            i?.key !== item.key &&
            i?.key?.startsWith(item.key?.replace(/\/$/, '') + '/')
        );
        let label = last(item.key.replace(/\/$/, '').split('/'));
        if (item.key === UNCATEGORIZED_FOLDER) {
          label = capitalize(label);
        }
        return (
          <TreeMenuItem
            key={item.key}
            parent={{ id: '', key: '' }}
            item={item}
            label={label}
            level={1}
            activeKey={activeKey}
            onClick={onItemClick}
            childrenItems={childrenItems}
            onEdit={onEdit}
            onDelete={onDelete}
            onAdd={onAdd}
            isItemDisable={isItemDisable}
            onDrop={onDrop}
          />
        );
      })}
    </div>
  );
};
