import React from 'react';
import { Spinner } from 'react-bootstrap';
import { BsCheck } from 'react-icons/bs';
import { FiUsers } from 'react-icons/fi';
import { RiUser3Line } from 'react-icons/ri';
import { IUserTeamItem } from '../../advisorHub/components/Common/Collaborators/Collaborator';
import DeleteButton from '../../advisorHub/components/Common/DeleteButton';
import SearchInput from '../../advisorHub/components/Common/SearchInput';
import { useLoadingStatus } from '../hooks/loading';


const UserTeamItem = ({
  item,
  onSelect,
  onDelete,
  isSelected,
  isLoading,
  viewOnly,
}: {
  item: IUserTeamItem;
  onSelect?: (item: IUserTeamItem) => void;
  onDelete?: (item: IUserTeamItem) => void;
  isSelected?: boolean;
  isLoading?: boolean;
  viewOnly?: boolean;
}) => {
  if (!item) return null;
  const isTeam = item.context === 'team';
  const isOwner = item.owner
  return (
    <div
      className="item d-flex"
      onClick={isSelected || viewOnly ? undefined : () => onSelect?.(item)}
    >
      <div className="item-content d-flex">
        <div className="avatar-container">
          <div
            className={`avatar ${isTeam ? 'team' : ''}`}
            style={{ backgroundImage: `url(${item.avatar})` }}
          >
            {!item.avatar && isTeam && <FiUsers color="#fff" />}
            {!item.avatar && !isTeam && <RiUser3Line color="#666" />}
          </div>
        </div>
        <div className="label-container">
          <div className="text-medium">{item.name}{isOwner && ' (Owner)'}</div>
          <div className="note">{item.note || 'Team'}</div>
        </div>
      </div>
      <div className="checked">
        {isLoading && <Spinner animation="border" size="sm" />}
        {isSelected && !isLoading && <BsCheck color="green" />}
        {onDelete && !isLoading && !viewOnly && !isOwner && (
          <DeleteButton noConfirmation={true} onClick={() => onDelete(item)} />
        )}
      </div>
      <style jsx>{`
        .item {
          position: relative;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          border-bottom: 1px solid #efefef;
          cursor: ${onSelect && !isSelected ? 'pointer' : 'default'};
        }
        .item:hover {
          background: ${!isSelected && !viewOnly ? '#f5f5f5' : 'transparent'};
        }
        .item:last-child {
          border: none;
        }
        .note {
          font-size: 0.9em;
          color: #666;
        }
        .text-medium {
          font-weight: 500;
        }
        .avatar-container {
          padding-right: 10px;
          display: flex;
          align-items: center;
        }
        .avatar {
          width: 2.8em;
          height: 2.8em;
          border-radius: 100px;
          background-color: #f2f2f2;
          overflow: hidden;
          background-size: cover;
          display: flex;
          align-items: center;
          margin: auto;
        }

        .avatar :global(svg) {
          margin: auto;
          width: 1.5em;
          height: auto;
        }

        .avatar.team {
          background-color: #666;
        }
        .checked {
          position: absolute;
          right: 8px;
          overflow: hidden;
        }
        .checked :global(svg) {
          width: 20px;
          height: auto;
        }
      `}</style>
    </div>
  );
};

const UserTeamSelector = ({
  loading,
  excludeOptions = [],
  allUsersTeams,
  usersTeams,
  viewOnly,
  onSelect,
  onDelete,
  initItem,
  maxHeight,
  height,
  placeHolder = 'Add User or Team',
  emptyPlaceHolder = <FiUsers color="#ccc" />,
  fontSize = `1em`
}: {
  loading?: boolean;
  excludeOptions?: string[];
  allUsersTeams: IUserTeamItem[];
  usersTeams: IUserTeamItem[];
  viewOnly: boolean;
  onSelect: (item: IUserTeamItem) => void;
  onDelete: (item: IUserTeamItem) => void;
  initItem?: IUserTeamItem;
  maxHeight?: string;
  height?: string;
  placeHolder?: string;
  emptyPlaceHolder?: string | React.ReactNode;
  fontSize?: string;
}) => {
  const [keywords, setKeywords] = React.useState('');
  const { isLoading } = useLoadingStatus();

  const filteredItems = allUsersTeams
    .filter((item) =>
      `${item.name} ${item.note}`
        .toLowerCase()
        .includes(keywords?.toLowerCase())
    )
    .filter((user2) => !excludeOptions.includes(user2.id));

  return (
    <div className="w-100 UserTeamSelector">
      {!viewOnly && (
        <div className="search-container">
          <SearchInput
            onSearch={setKeywords}
            searchOnTyping={true}
            searchOnTypingDelay={0}
            placeHolder={placeHolder}
            charLengthToSearch={1}
          />

          {keywords && (
            <div className="search-results shadow-box">
              {!filteredItems?.length && (
                <div className="item item-not-found">
                  Item with keywords '{keywords}' not found
                </div>
              )}
              {filteredItems.map((item) => (
                <UserTeamItem
                  key={item.id}
                  item={item}
                  onSelect={onSelect}
                  isSelected={usersTeams.some((c) => c.id === item.id)}
                  isLoading={isLoading(`userTeamItem-${item.id}`)}
                />
              ))}
            </div>
          )}
        </div>
      )}
      <div className="collaborators">
        {initItem && <UserTeamItem item={initItem} />}
        {usersTeams.map((item) => (
          <UserTeamItem
            key={item.id}
            item={item}
            isLoading={isLoading(`userTeamItem-${item.id}`)}
            onDelete={onDelete}
            viewOnly={viewOnly}
          />
        ))}
        {!usersTeams?.length && !loading && (
          <div className="placeholder">
            <div className="empty">{emptyPlaceHolder}</div>
          </div>
        )}
        {loading && (
          <div className="placeholder">
            <div>
              <Spinner animation="border" size="sm" />
            </div>
          </div>
        )}
      </div>
      <style jsx>{`
        .UserTeamSelector {
          position: relative;
          z-index: ${keywords ? '1' : '0'};
          font-size: ${fontSize};
        }
        .search-container {
          position: relative;
        }
        .search-results {
          max-height: 300px;
          overflow: auto;
          border-radius: 4px;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          background: #fff;
          z-index: 1;
        }
        .collaborators {
          border-top: ${viewOnly ? '1px solid #ccc' : 'none'};
          height: ${height ? `calc(${height} + 1px)` : 'auto'};
          max-height: ${maxHeight ? `calc(${maxHeight} + 1px)` : 'none'};
          overflow: auto;
          margin-top: 10px;
        }
        .placeholder {
          width: 100%;
          display: flex;
          align-content: center;
          height: ${height || 'auto'};
          max-height: ${maxHeight || 'none'};
          text-align: center;
        }
        .placeholder div {
          margin: auto;
        }
        .item-not-found {
          padding: 10px 20px;
        }
        .empty :global(svg) {
          width: 100px;
          height: auto;
          color: #ccc;
        }
      `}</style>
    </div>
  );
};

export default UserTeamSelector;
