import { throttle } from 'lodash';
import { useEffect, useState } from 'react';

const isMobilePortrait = () => Math.max(window.innerWidth) < 576;
export const getScreenSize = () => {
  if (innerWidth >= 1200) {
    return 'xl';
  }
  if (innerWidth >= 992) {
    return 'lg';
  }
  if (innerWidth >= 768) {
    return 'md';
  }
  if (innerWidth >= 576) {
    return 'sm';
  }
  return 'xs';
};

export const SCREEN_SIZE_LEVEL = {
  xs: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4
};

export const useMobileViewportDetector = () => {
  const [isOnMobileViewport, setIsOnMobileViewport] = useState(
    isMobilePortrait()
  );
  useEffect(() => {
    const handleResize = () => {
      setIsOnMobileViewport((prev) => {
        if (prev !== isMobilePortrait()) {
          return !prev;
        }
        return prev;
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return {
    isOnMobileViewport
  };
};

export const useBreakPointDetector = () => {
  const [screenSize, setScreenSize] = useState<
    'xs' | 'sm' | 'md' | 'lg' | 'xl'
  >();
  useEffect(() => {
    setScreenSize(getScreenSize());
    const handleResize = throttle(() => {
      setScreenSize(getScreenSize);
    }, 500);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    screenSize,
    screenSizeLevel: SCREEN_SIZE_LEVEL[screenSize]
  };
};
