import { isUserOnMobile } from '../../../../utils/deviceDetector';

const acceptedLabel = {
  all: 'Only *.jpeg, *.png, *.gif, *.pdf, *.mp4, *.qt and *.mov supported. PDF limit is 400 pages.',
  image: 'Only *.jpeg, *.png, *.gif, and *.pdf supported. PDF limit is 400 pages.',
  video: 'Only *.mp4, *.qt and *.mov supported.'
};

const DropInfo = ({
  isDragActive,
  onClick,
  multipleUpload,
  compactMode,
  onClose,
  acceptedTypes,
  dropZonePlaceholder
}: {
  isDragActive?: boolean;
  onClick?: () => void;
  multipleUpload?: boolean;
  compactMode?: boolean;
  onClose?: () => void;
  dropZonePlaceholder?: React.ReactNode;
  acceptedTypes?: string;
}) => {
  const isOnMobile = isUserOnMobile();

  const videoOnly =
    acceptedTypes?.includes('video') && !acceptedTypes?.includes('jpeg');
  const all =
    acceptedTypes?.includes('jpeg') && acceptedTypes?.includes('video');

  const label = acceptedLabel[all ? 'all' : videoOnly ? 'video' : 'image'];

  return (
    <div className={`info`}>
      <div className={`${compactMode && !isDragActive ? 'compact' : ''}`}>
        <div className="inner" onClick={() => onClick?.()}>
          {isDragActive ? (
            <p>
              <strong>Release to drop</strong>
            </p>
          ) : (
            <p>
              {compactMode ? (
                multipleUpload && (
                  <strong className="round">
                    {isOnMobile ? (
                      'Tap here to select file'
                    ) : (
                      <>
                        Drag 'n' drop,
                        <br />
                        or click here to add more files
                      </>
                    )}
                  </strong>
                )
              ) : (
                <strong>
                  {isOnMobile ? (
                    'Tap here to select file'
                  ) : (
                    <>
                      {multipleUpload
                        ? `Drag 'n' drop, or click here to select files`
                        : `${
                            isOnMobile
                              ? 'Tap here to select file'
                              : `Drag 'n' drop, or click here to select file`
                          } `}
                    </>
                  )}
                </strong>
              )}
            </p>
          )}
          <p className="small">{dropZonePlaceholder || label}</p>
        </div>
      </div>

      {onClose && !compactMode && (
        <div className="text-center p3">
          <button
            className="btn btn-sm btn-round btn-outline-dark"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      )}

      <style jsx>{`
        .info {
          text-align: center;
          width: 100%;
          margin: auto;
          cursor: ${onClick ? 'pointer' : 'initial'};
        }
        .compact {
          margin: 0;
          margin-top: 20px;
        }
        p {
          font-size: 0.9em;
        }
        .small {
          font-size: 0.8em;
        }
        .compact span {
          text-decoration: underline;
        }
        .round {
          border-radius: 5px;
          display: inline-block;
          padding: 5px 20px;
          margin-bottom: 10px;
          border: 1px dashed #ccc;
          width: 100%;
        }
        .round:hover {
          background: #fff;
        }
      `}</style>
    </div>
  );
};

export default DropInfo;
