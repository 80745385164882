import React from 'react';
import { useSelector } from 'react-redux';
import { IMainState } from '../../../interfaces';

const UserLabel = ({
  id,
  emptyName = 'NA',
  label
}: {
  id: string;
  emptyName?: string;
  label?: string;
}) => {
  const hubLabelState = useSelector(
    (state: IMainState) => state.clientState?.hub?.userInfo || {}
  );
  const currentUser = hubLabelState[id];
  return (
    <>
      {label}
      {currentUser?.firstName
        ? `${currentUser.firstName} ${currentUser.lastName}`
        : currentUser?.alias ||
          (id.includes('ap-southeast-1:') ? '' : id) ||
          emptyName}
    </>
  );
};

export default UserLabel;
