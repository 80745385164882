import { ILibraryScene, IStorybook } from '../../../interfaces';
import { prodBaseUrlV2 } from '../../config';
import { apigClient } from '../aws';

export type ICollaboratorScopeType = 'team' | 'user';
export type ICollaboratorContext = 'scene' | 'storybook';

export interface ICollaboratorScope {
  itemId: string;
  scope: ICollaboratorScopeType;
  scopeDetails: string[];
}

export const addStorybookCollaborators = async (body: ICollaboratorScope) => {
  const client = await apigClient();
  const path = `/storybooks/v2/collaborators/add`;
  return await client.invokeApi({}, path, 'POST', {}, body);
};

const addSceneCollaborators = async (body: ICollaboratorScope) => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/libraries/v1/scenes/collaborators/add`;
  return await client.invokeApi({}, path, 'POST', {}, body);
};

const getStorybookCollaborators = async (itemId: string) => {
  const client = await apigClient();
  const path = `/storybooks/v2/collaborators`;
  const result = await client.invokeApi({}, path, 'POST', {}, { itemId });
  return result.data;
};

const getSceneCollaborators = async (itemId: string) => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/libraries/v1/scenes/collaborators`;
  const result = await client.invokeApi({}, path, 'POST', {}, { itemId });
  return result.data;
};
const removeStorybookCollaborators = async (body: ICollaboratorScope) => {
  const client = await apigClient();
  const path = `/storybooks/v2/collaborators/remove`;
  return await client.invokeApi({}, path, 'POST', {}, body);
};

const removeSceneCollaborators = async (body: ICollaboratorScope) => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/libraries/v1/scenes/collaborators/remove`;
  return await client.invokeApi({}, path, 'POST', {}, body);
};

export type CollaborationResult<T extends ICollaboratorContext> =
  T extends 'storybook' ? IStorybook : ILibraryScene;

export const getCollaborationsByUser = async <T extends ICollaboratorContext>({
  userId,
  context
}: {
  userId: string;
  context: T;
}): Promise<CollaborationResult<T>[]> => {
  const client = await apigClient(prodBaseUrlV2);
  const pathByContext = {
    scene: `/libraries/v1/scenes/collaborationsByUser`,
    storybook: `/libraries/v1/storybooks/collaborationsByUser`
  };

  const path = pathByContext[context];

  const dataContext = `${context}s`;

  const body = {
    userId
  };

  const result = await client.invokeApi({}, path, 'POST', {}, body);

  return result.data?.[dataContext]?.filter(
    (item) => item.createdBy !== userId
  );
};

export const addCollaborators = async (
  context: ICollaboratorContext,
  body: ICollaboratorScope
) => {
  if (context === 'storybook') {
    return await addStorybookCollaborators(body);
  }
  if (context === 'scene') {
    return await addSceneCollaborators(body);
  }
  return null;
};

export const getCollaborators = async (
  context: ICollaboratorContext,
  itemId: string
) => {
  if (context === 'storybook') {
    return await getStorybookCollaborators(itemId);
  }
  if (context === 'scene') {
    return await getSceneCollaborators(itemId);
  }
  return null;
};

export const removeCollaborators = async (
  context: ICollaboratorContext,
  body: ICollaboratorScope
) => {
  if (context === 'storybook') {
    return await removeStorybookCollaborators(body);
  }
  if (context === 'scene') {
    return await removeSceneCollaborators(body);
  }
  return null;
};
