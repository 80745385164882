import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form, Overlay, Spinner, Tooltip } from 'react-bootstrap';
import { AssetLibraryContext } from '.';
import { IconFolderTrash } from '../Common/HubIcons';
import { MultiValue } from 'react-select';
import {
  capitalize,
  cloneDeep,
  debounce,
  flatten,
  isEmpty,
  last,
  uniq
} from 'lodash';
import { EditFileNameIcon } from '../Common/HubIcons';
import moment from 'moment';
import { IAsset } from '../../../interfaces';
import { HubContext } from '../HubContext';
import { useAssetLibrary, useAssetLibraryFolder } from './hook';
import {
  cacheUpdatedAsset,
  deleteAssets,
  saveAsset
} from '../../clientSideServices/assetLibrary';
import { InsCreatableSelect, InsSelect } from '../Common/Select';
import {
  sortFolderKeys,
  TRASH_FOLDER,
  UNCATEGORIZED_FOLDER
} from '../../utils/folders';
import {
  actionDeleteAssetFromStore,
  actionUpdateLibraryAsset
} from '../../../redux/actions';
import { useDispatch } from 'react-redux';
import { PopupConfirmDelete } from './PopupConfirmDelete';
import { BsX, BsCheck } from 'react-icons/bs';
import { FiLink2 } from 'react-icons/fi';
import {
  decodeS3FileName,
  getObjectFromS3
} from '../../clientSideServices/aws';
import { CategoryMultiSelectLevelOption, TagOption } from './SelectComponents';
import { HubUserContext } from '../UserManagement/HubUserContext';
import { getFormattedFullName } from '../../../utils/shoppingCart';
import mime from 'mime';
import { removeExtraSpaces } from '../../../utils/string';
import { getCurrentHost } from '../../../utils/window';
import jwt from 'jsonwebtoken';
import { getSimpleShortenedUrl } from '../../../utils/shareUrl';
import {
  getFeaturedImageUrl,
  setImageAsFeaturedImage
} from '../../utils/assets';
import axios from 'axios';
import { logEvent } from '../../../analytics';
import {
  DID_CLICK_BUTTON,
  DID_SHARE_ASSET,
  SHARE_ASSET_FAILED
} from '../../../utils/constants';

export const SelectedAsset: React.FC<{
  isPreview: boolean;
  previewAsset?: IAsset;
  setPreviewAsset?: (IAsset) => void;
  onRequestClose?: () => void;
  activeKey?: string[];
}> = ({
  isPreview,
  previewAsset,
  onRequestClose,
  activeKey,
  setPreviewAsset
}) => {
  const dispatch = useDispatch();
  const { selectedAssets, deselectAssets } = useContext(AssetLibraryContext);
  const { brandId, user } = useContext(HubContext);

  const { usersByBrand } = useContext(HubUserContext);

  const { folder: assetLibraryCategoriesFolder } = useAssetLibraryFolder(
    brandId,
    user?.id,
    false
  );

  const { assetLibrary } = useAssetLibrary(brandId, user?.id, false);

  const categoriesOptions = useMemo(() => {
    const categories = assetLibraryCategoriesFolder?.keys?.filter(
      (fKey) => ![TRASH_FOLDER, UNCATEGORIZED_FOLDER].includes(fKey.key)
    );
    const categoryOptions = sortFolderKeys(categories);
    return categoryOptions;
  }, [assetLibraryCategoriesFolder]);

  const isCategoryExisting = (id) => {
    return assetLibraryCategoriesFolder?.keys?.some((k) => k.id === id);
  };

  const [
    { selectedCategories, selectedTags, loading, errorMessage, downloading },
    setFormValues
  ] = useState<{
    selectedCategories: MultiValue<{
      id: string;
      key: string;
      originalState?: {
        isAllSelected?: boolean;
        selected?: boolean;
      };
      isAllSelected?: boolean;
      selected?: boolean;
    }>;
    selectedTags: MultiValue<{
      label: string;
      value: string;
    }>;
    lastSelectedAsset?: IAsset;
    loading?: boolean;
    downloading?: boolean;
    deleting?: boolean;
    errorMessage?: string;
  }>({ selectedCategories: [], selectedTags: [] });
  const [openPopupConfirm, setOpenPopupConfirm] = React.useState(false);
  const [isRename, setIsRename] = useState(false);
  const [assetTitle, setAssetTitle] = useState<string>();
  const refInputAssetTitle = useRef(null);
  const [openTooltipInvalidTitle, setOpenTooltipInvalidTitle] = useState(false);
  const [tagInputVal, setTagInputVal] = useState<string>();
  const [sharing, setSharing] = useState<boolean>(false);
  const [copiedText, setCopiedText] = useState<boolean>(false);

  const activeAssets = useMemo(() => {
    return assetLibrary?.data?.filter((asset) =>
      selectedAssets?.includes(asset.id)
    );
  }, [selectedAssets, assetLibrary]);

  useEffect(() => {
    if (!isPreview) return;
    setAssetTitle(previewAsset?.title);
  }, [isPreview]);

  useEffect(() => {
    if (!assetTitle?.length) {
      setOpenTooltipInvalidTitle(false);
      return;
    }
    const invalidPattern = /^[^\\/:\*\?"<>\|]+$/;
    setOpenTooltipInvalidTitle(!invalidPattern.test(assetTitle));
  }, [assetTitle]);

  useEffect(() => {
    const handleClickOutsideInput = (event) => {
      if (
        refInputAssetTitle.current &&
        !refInputAssetTitle.current.contains(event.target)
      ) {
        if (!assetTitle?.length || openTooltipInvalidTitle) {
          setIsRename(false);
          return;
        } else {
          const updateAsset = {
            ...previewAsset,
            title: assetTitle
          };
          cacheUpdatedAsset(updateAsset, previewAsset);
          return handleUpdateAsset(updateAsset).then(() => {
            setIsRename(false);
          });
        }
      }
    };
    document.addEventListener('click', handleClickOutsideInput, true);
    return () => {
      document.removeEventListener('click', handleClickOutsideInput, true);
    };
  }, [isRename, assetTitle]);

  useEffect(() => {
    const selectedCategories = categoriesOptions
      .filter((op) =>
        activeAssets?.find((asset) => asset.categories.includes(op.id))
      )
      .map((op) => {
        const isAllSelected = activeAssets.every((asset) =>
          asset.categories.includes(op.id)
        );

        return {
          ...op,
          isAllSelected,
          selected: true
        };
      });

    const selectedTags = uniq(
      flatten(activeAssets?.map((asset) => asset.tags))
    ).map((tag) => ({
      value: tag,
      label: tag,
      selected: true,
      isAllSelected: activeAssets.every((asset) => asset.tags.includes(tag))
    }));

    setFormValues((prev) => ({
      ...prev,
      selectedCategories,
      lastSelectedAsset: last(activeAssets),
      selectedTags
    }));
  }, [activeAssets, categoriesOptions]);

  const handleUpdateAsset = (values: Partial<IAsset>, delay = 0) => {
    const activeAsset = activeAssets.find((asset) => asset.id === values.id);
    const updateAsset: IAsset = {
      ...activeAsset,
      modifiedAt: new Date().toISOString(),
      modifiedBy: user.id,
      ...values
    };
    setFormValues((prev) => ({
      ...prev,
      loading: true
    }));
    return saveAsset(updateAsset)
      .then(() => {
        setTimeout(() => {
          setFormValues((prev) => ({
            ...prev,
            loading: false,
            errorMessage: ''
          }));
        }, delay);
        if (isPreview) setPreviewAsset(updateAsset);
        dispatch(actionUpdateLibraryAsset(updateAsset));
      })
      .catch(() => {
        setFormValues((prev) => ({
          ...prev,
          loading: false,
          errorMessage: 'Failed to update asset.'
        }));
      });
  };

  useEffect(() => {
    //reset state
    setCopiedText(false);
  }, [selectedAssets]);

  const handleDownloadAsset = () => {
    if (downloading) {
      return;
    }
    setFormValues((prev) => ({ ...prev, downloading: true }));
    const downloadAssets = assetLibrary.data.filter((asset) =>
      selectedAssets.includes(asset.id)
    );
    Promise.all(
      downloadAssets.map((asset) => {
        const originBucket = 'inspify-data';
        const urlComponent = new URL(asset.originalURL);
        const assetKey = decodeS3FileName(
          asset.originalURL.replace(`${urlComponent.origin}/`, '')
        );
        const fileName = `${asset.title}.${mime.getExtension(
          mime.getType(assetKey)
        )}`;
        return getObjectFromS3(originBucket, assetKey).then((data) => {
          return new File([data.Body as Blob], fileName, {
            type: asset.assetType
          });
        });
      })
    )
      .then(async (results) => {
        setFormValues((prev) => ({
          ...prev,
          downloading: false,
          errorMessage: ''
        }));
        const link = document.createElement('a');
        if (downloadAssets?.length === 1) {
          const url = window.URL.createObjectURL(results[0]);
          link.href = url;
          link.setAttribute('download', results?.[0].name);
          link.click();
          link.remove();
        } else {
          const downloadZip = (await import('client-zip')).downloadZip;
          const blob = await downloadZip(results).blob();
          link.href = URL.createObjectURL(blob);
          link.setAttribute('download', 'assets.zip');
          link.click();
          link.remove();
        }
      })
      .catch((err) => {
        console.log(err);
        setFormValues((prev) => ({
          ...prev,
          errorMessage: 'Failed to download',
          downloading: false
        }));
      });
  };

  const createdBy = usersByBrand?.find(
    (user) => user.id === previewAsset?.createdBy
  );

  const handleShareAsset = async (asset: IAsset) => {
    if (sharing) {
      return;
    }
    setSharing(true);

    const data = {
      title: asset.title,
      originalURL: asset.originalURL,
      type: asset.assetType,
      assetBrand: asset.brand,
      id: asset.id,
      size: asset.sizeKB,
      owner:
        createdBy?.full_name ||
        getFormattedFullName(createdBy?.first_name, createdBy?.last_name),
      createdAt: asset.createdAt
    };

    try {
      const checkValidFeature = await axios.post('/api/checkUrl', {
        url: getFeaturedImageUrl(asset.brand, asset.id)
      });
      if (!checkValidFeature.data.accessible) {
        await setImageAsFeaturedImage(
          asset.assetPreviewURL,
          asset.brand,
          asset.id
        );
      }
      const token = jwt.sign(data, process.env.NEXT_PUBLIC_JWT_KEY, {
        expiresIn: '7d'
      });
      const assetViewUrl = `${getCurrentHost()}/shared-assets/${token}`;
      const shortened = await getSimpleShortenedUrl(
        assetViewUrl,
        getCurrentHost()
      );
      await navigator.clipboard.writeText(shortened);
      logEvent(DID_SHARE_ASSET, DID_SHARE_ASSET, {
        assetId: asset.id,
        shareUrl: shortened,
        message: asset.title
      });
    } catch (error) {
      logEvent(SHARE_ASSET_FAILED, SHARE_ASSET_FAILED, {
        error
      });
    }
    setSharing(false);
    setCopiedText(true);
    setTimeout(() => setCopiedText(false), 10000);
  };

  const isInTrash = activeKey?.[0] === TRASH_FOLDER;

  const handleDeleteAsset = async () => {
    setOpenPopupConfirm(false);
    setFormValues((prev) => ({ ...prev, deleting: true }));
    if (isInTrash) {
      return deleteAssets(selectedAssets)
        .then(() => {
          deselectAssets(selectedAssets);
          activeAssets.forEach((asset) => {
            const beforeUpdate = { ...asset };
            asset.status = 'permanently_deleted';
            cacheUpdatedAsset(asset, beforeUpdate);
          });
          setFormValues((prev) => ({
            ...prev,
            deleting: false,
            errorMessage: ''
          }));
          dispatch(actionDeleteAssetFromStore(selectedAssets));
        })
        .catch(() => {
          setFormValues((prev) => ({
            ...prev,
            deleting: false,
            errorMessage: 'Failed to delete.'
          }));
        });
    }
    Promise.all(
      activeAssets.map((asset) => {
        if (isPreview && asset.id !== previewAsset.id) return;
        const beforeUpdate = { ...asset };
        asset.status = 'deleted';
        cacheUpdatedAsset(asset, beforeUpdate);
        return handleUpdateAsset(asset);
      })
    ).then(() => {
      const deletedAssets = isPreview ? [previewAsset.id] : selectedAssets;
      deselectAssets(deletedAssets);
      onRequestClose?.();
      dispatch(actionDeleteAssetFromStore(deletedAssets));
    });
  };

  const normalizeStatus = (status: IAsset) => {
    if (
      status?.status === 'deleted' ||
      status?.status === 'permanently_deleted'
    ) {
      return 'deleted';
    }
    if (status?.visibility === 'brand') {
      return 'activated';
    }
    return 'private';
  };

  const isAllActivatedAssets = isPreview
    ? normalizeStatus(previewAsset) === 'activated'
    : activeAssets?.every((asset) => normalizeStatus(asset) === 'activated');
  const disabledUpdate = selectedAssets?.length === 0;

  const renderButtonStatus = () => {
    if (isAllActivatedAssets && !disabledUpdate) {
      return 'Set Private';
    }
    return 'Activate';
  };

  const handleRemoveTag = (removedOption) => {
    const updatePromises = activeAssets
      .filter((asset) => asset.tags.includes(removedOption.value))
      .map((asset) => {
        const beforeUpdate = { ...asset };
        asset.tags = (asset.tags || []).filter(
          (tag) => tag !== removedOption.value
        );
        asset.modifiedAt = new Date().toISOString();
        asset.modifiedBy = user?.id;
        cacheUpdatedAsset(asset, beforeUpdate);
        return saveAsset(asset).then(() => {
          dispatch(actionUpdateLibraryAsset(asset));
        });
      });
    Promise.all(updatePromises)
      .then(() =>
        setFormValues((prev) => ({
          ...prev,
          loading: false,
          errorMessage: ''
        }))
      )
      .catch(() => {
        setFormValues((prev) => ({
          ...prev,
          loading: false,
          errorMessage: 'Failed to update.'
        }));
      });
  };

  return (
    <div className="selected-asset-container">
      <div className="selected-info d-flex align-items-center w-100 justify-content-between mb-1">
        {isPreview ? (
          <div
            className="d-flex align-items-center"
            style={{ width: 'calc(100% - 40px)' }}
          >
            {isRename ? (
              <>
                <Form.Control
                  type="text"
                  autoFocus
                  ref={refInputAssetTitle}
                  onKeyDown={(e) => {
                    if (e.key === 'Escape') {
                      setIsRename(false);
                      return;
                    }

                    if (e.key === 'Enter') {
                      if (!assetTitle?.length || openTooltipInvalidTitle) {
                        setIsRename(false);
                        return;
                      }
                      const beforeUpdateAsset = { ...previewAsset };
                      previewAsset.title = assetTitle;
                      cacheUpdatedAsset(previewAsset, beforeUpdateAsset);
                      return handleUpdateAsset(previewAsset).then(() => {
                        setIsRename(false);
                      });
                    }
                  }}
                  onChange={(e) => {
                    setAssetTitle(e.target.value);
                  }}
                  value={assetTitle}
                  style={{
                    height: 30,
                    fontSize: 12,
                    width: '90%',
                    border: openTooltipInvalidTitle && '1px solid #960B0B'
                  }}
                />
                <Overlay
                  placement="bottom"
                  show={openTooltipInvalidTitle}
                  target={refInputAssetTitle.current}
                >
                  {(props) => (
                    <Tooltip
                      id="tooltip"
                      {...props}
                      style={{
                        color: '#fff',
                        fontSize: 12,
                        borderRadius: 5,
                        textAlign: 'center',
                        ...props.style
                      }}
                    >
                      {
                        'a file name can’t contain any of the following characters:  / \\ : * ? “ < > |'
                      }
                    </Tooltip>
                  )}
                </Overlay>
                {loading && (
                  <span className="ml-1">
                    <Spinner variant="secondary" size="sm" animation="border" />{' '}
                  </span>
                )}{' '}
              </>
            ) : (
              <>
                <span
                  className="selected-number mr-1"
                  style={{ maxWidth: '100%' }}
                >
                  {previewAsset?.title}
                </span>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ width: 20, height: 20, cursor: 'pointer' }}
                  onClick={() => {
                    setIsRename(true);
                    setAssetTitle(previewAsset?.title);
                  }}
                >
                  <EditFileNameIcon />
                </div>
              </>
            )}
          </div>
        ) : selectedAssets.length > 0 ? (
          <span className="selected-number">
            {selectedAssets.length} Items Selected
            {loading && (
              <span className="ml-1">
                <Spinner variant="secondary" size="sm" animation="border" />{' '}
              </span>
            )}{' '}
          </span>
        ) : (
          <span className="selected-number">
            {assetLibrary?.total || 0} Total Assets
          </span>
        )}
        {selectedAssets.length > 0 && (
          <span
            className="remove-icon"
            onClick={() => setOpenPopupConfirm(true)}
          >
            <IconFolderTrash />
          </span>
        )}

        <PopupConfirmDelete
          open={openPopupConfirm}
          onRequestClose={() => setOpenPopupConfirm(false)}
          label={`Are you sure you want to delete ${
            isPreview ? 'this asset?' : 'your selected assets?'
          }`}
          itemDelete={selectedAssets}
          handleDelete={handleDeleteAsset}
          isPreview={isPreview}
        />
      </div>
      {isPreview && (
        <div className="file-info d-flex flex-column">
          <div
            className={`d-flex align-items-center justify-content-center mb-1 block-extension-file ${
              previewAsset?.assetType.includes('font')
                ? 'bg-filename-extension-font'
                : previewAsset?.assetType.includes('video')
                ? 'bg-filename-extension-video'
                : ''
            }`}
          >
            <span>
              {previewAsset?.originalURL.split('.').pop().toUpperCase()}
            </span>
          </div>
          <span>
            File size:{' '}
            <span className="text-dark">
              {Number(previewAsset?.sizeKB / 1000 / 1000).toFixed(2)}MB
            </span>
          </span>
          <span>
            By:{' '}
            <span>
              {createdBy?.full_name ||
                getFormattedFullName(
                  createdBy?.first_name,
                  createdBy?.last_name
                )}
            </span>
          </span>
          <span>
            Created:{' '}
            <span>
              {moment(previewAsset?.createdAt).format('ll')}
              {' - '}
              {moment(previewAsset?.createdAt).format('LT')}
            </span>
          </span>
          <span>
            Status:{' '}
            <span className={`asset-status ${normalizeStatus(previewAsset)}`}>
              {capitalize(normalizeStatus(previewAsset))}
            </span>
          </span>
        </div>
      )}
      <div
        className={`update-form ${
          selectedAssets?.length === 0 ? 'disabled' : ''
        } `}
      >
        <div className="update-form__actions mt-2">
          {isInTrash && (
            <Button
              className="rounded-pill"
              variant="dark"
              style={{
                fontSize: 12,
                fontWeight: 600,
                marginRight: 10
              }}
              disabled={disabledUpdate}
              onClick={() => {
                Promise.all(
                  activeAssets.map(async (asset) => {
                    if (isPreview && asset.id !== previewAsset.id) return;
                    const updateAsset: IAsset = {
                      ...asset,
                      status:
                        asset.visibility === 'brand' ? 'activated' : 'private'
                    };
                    cacheUpdatedAsset(updateAsset, asset);
                    return handleUpdateAsset(updateAsset).then(() => {
                      deselectAssets([asset.id]);
                    });
                  })
                ).then(() => {
                  dispatch(
                    actionDeleteAssetFromStore(activeAssets.map((a) => a.id))
                  );
                });
              }}
            >
              Recovery
            </Button>
          )}
          <Button
            className="rounded-pill"
            variant="dark"
            style={{
              fontSize: 12,
              fontWeight: 600,
              minWidth: 100,
              marginRight: '10px',
              marginBottom: '10px'
            }}
            onClick={handleDownloadAsset}
            disabled={downloading || disabledUpdate}
          >
            {downloading ? (
              <Spinner size="sm" animation="border" />
            ) : (
              'Download'
            )}
          </Button>
          {!isInTrash && (
            <Button
              className="rounded-pill"
              variant={copiedText ? 'light' : 'dark'}
              style={{
                fontSize: 12,
                fontWeight: 600,
                minWidth: 100,
                border: '1px solid #000',
                marginRight: '10px',
                marginBottom: '10px'
              }}
              onClick={() => {
                if (selectedAssets.length > 1) return;
                const asset = assetLibrary.data.find((item) =>
                  selectedAssets.includes(item.id)
                );

                logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
                  button: 'Share Asset',
                  assetId: asset.id
                });

                handleShareAsset(asset);
              }}
              disabled={
                sharing ||
                selectedAssets.length > 1 ||
                selectedAssets.length === 0
              }
            >
              {sharing ? (
                <Spinner size="sm" animation="border" />
              ) : (
                <div style={{ display: 'flex' }}>
                  {copiedText ? (
                    <BsCheck size={18} style={{ marginRight: '10px' }} />
                  ) : (
                    <FiLink2 size={18} style={{ marginRight: '10px' }} />
                  )}
                  <div>{copiedText ? 'Copied!' : 'Share'}</div>
                </div>
              )}
            </Button>
          )}
          {!isInTrash && (
            <Button
              className="rounded-pill"
              variant={renderButtonStatus() === 'Activate' ? 'dark' : 'light'}
              style={{
                fontSize: 12,
                fontWeight: 600,
                marginRight: 10,
                border: '1px solid #000',
                marginBottom: '10px'
              }}
              disabled={disabledUpdate}
              onClick={() => {
                activeAssets
                  .filter((asset) =>
                    !isAllActivatedAssets
                      ? asset.visibility === 'private'
                      : asset.visibility === 'brand'
                  )
                  .map(async (asset) => {
                    if (isPreview && asset.id !== previewAsset.id) return;
                    const isActivatedStatus =
                      normalizeStatus(asset) === 'activated';
                    const updateAsset: IAsset = {
                      ...asset,
                      status: !isAllActivatedAssets ? 'activated' : 'private',
                      activatedBy: isActivatedStatus
                        ? user?.id
                        : asset.activatedBy,
                      activatedAt: isActivatedStatus
                        ? new Date().toISOString()
                        : asset.activatedAt,
                      visibility: isActivatedStatus ? 'private' : 'brand',
                      visibilityScope: isActivatedStatus ? [] : [brandId]
                    };
                    cacheUpdatedAsset(updateAsset, asset);
                    return handleUpdateAsset(updateAsset);
                  });
              }}
            >
              {renderButtonStatus()}
            </Button>
          )}
        </div>
        {!isInTrash && (
          <div className="mt-4 form-selected-asset">
            <Form.Group>
              <InsSelect<
                {
                  id: string;
                  key: string;
                  originalState?: {
                    isAllSelected?: boolean;
                    selected?: boolean;
                  };
                  isAllSelected?: boolean;
                  selected?: boolean;
                },
                true
              >
                aria-label="Categories"
                value={selectedCategories}
                placeholder=""
                name="categories"
                inputId="categories"
                isClearable={false}
                hideSelectedOptions={false}
                isMulti
                options={categoriesOptions}
                isDisabled={disabledUpdate}
                closeMenuOnSelect={false}
                isOptionSelected={() => false}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) =>
                  last(option.key?.replace(/\/$/, '').split('/'))
                }
                onChange={debounce((values, meta) => {
                  console.log(meta);
                  if (meta.action === 'remove-value') {
                    const removedOption = meta.removedValue;
                    // deselect all
                    setFormValues((prev) => ({ ...prev, loading: true }));
                    const updatePromises = activeAssets
                      .filter((asset) =>
                        asset.categories.includes(removedOption.id)
                      )
                      .map((asset) => {
                        const beforeUpdate = cloneDeep(asset);
                        asset.categories = asset.categories.filter(
                          (cate) =>
                            cate !== removedOption.id &&
                            isCategoryExisting(cate)
                        );
                        if (isEmpty(asset.categories)) {
                          asset.categories = [UNCATEGORIZED_FOLDER];
                        }
                        asset.modifiedAt = new Date().toISOString();
                        asset.modifiedBy = user?.id;
                        cacheUpdatedAsset(asset, beforeUpdate);
                        return saveAsset(asset).then(() => {
                          dispatch(actionUpdateLibraryAsset(asset));
                        });
                      });
                    Promise.all(updatePromises)
                      .then(() =>
                        setFormValues((prev) => ({
                          ...prev,
                          loading: false,
                          errorMessage: ''
                        }))
                      )
                      .catch(() => {
                        setFormValues((prev) => ({
                          ...prev,
                          loading: false,
                          errorMessage: 'Failed to update.'
                        }));
                      });
                    return;
                  }
                  if (meta.action === 'select-option') {
                    const selectedOption = selectedCategories.find(
                      (o) => o.id === meta.option.id
                    );
                    if (selectedOption && selectedOption.isAllSelected) {
                      // deselect all
                      setFormValues((prev) => ({ ...prev, loading: true }));
                      const updatePromises = activeAssets.map((asset) => {
                        const beforeAsset = cloneDeep(asset);
                        asset.categories = asset.categories.filter(
                          (cate) =>
                            cate !== selectedOption.id &&
                            isCategoryExisting(cate)
                        );

                        if (isEmpty(asset.categories)) {
                          asset.categories = [UNCATEGORIZED_FOLDER];
                        }
                        asset.modifiedAt = new Date().toISOString();
                        asset.modifiedBy = user?.id;
                        cacheUpdatedAsset(asset, beforeAsset);
                        return saveAsset(asset).then(() => {
                          dispatch(actionUpdateLibraryAsset(asset));
                        });
                      });
                      Promise.all(updatePromises)
                        .then(() =>
                          setFormValues((prev) => ({
                            ...prev,
                            loading: false,
                            errorMessage: ''
                          }))
                        )
                        .catch(() => {
                          setFormValues((prev) => ({
                            ...prev,
                            loading: false,
                            errorMessage: 'Failed to update.'
                          }));
                        });
                      return;
                    }
                    const optionToAdd = selectedOption || meta.option;
                    setFormValues((prev) => ({ ...prev, loading: true }));
                    const updatePromises = activeAssets
                      .filter(
                        (asset) => !asset.categories.includes(optionToAdd.id)
                      )
                      .map((asset) => {
                        const beforeAsset = cloneDeep(asset);
                        asset.categories = asset.categories
                          .concat(optionToAdd.id)
                          .filter(
                            (cate) =>
                              cate !== UNCATEGORIZED_FOLDER &&
                              isCategoryExisting(cate)
                          );
                        asset.modifiedAt = new Date().toISOString();
                        asset.modifiedBy = user?.id;
                        cacheUpdatedAsset(asset, beforeAsset);
                        return saveAsset(asset).then(() => {
                          dispatch(actionUpdateLibraryAsset(asset));
                        });
                      });
                    Promise.all(updatePromises)
                      .then(() =>
                        setFormValues((prev) => ({
                          ...prev,
                          loading: false,
                          errorMessage: ''
                        }))
                      )
                      .catch(() => {
                        setFormValues((prev) => ({
                          ...prev,
                          loading: false,
                          errorMessage: 'Failed to update.'
                        }));
                      });
                    return;
                  }
                }, 100)}
                components={{
                  Option: CategoryMultiSelectLevelOption
                }}
              />
            </Form.Group>
            <Form.Group style={{ marginTop: 30 }}>
              <InsCreatableSelect<
                {
                  label: string;
                  value: string;
                  isAllSelected?: boolean;
                  selected?: boolean;
                },
                true
              >
                isClearable={false}
                name="tags"
                aria-label="Tags"
                inputId="tags"
                className="tags-container"
                options={selectedTags}
                controlShouldRenderValue={false}
                value={selectedTags}
                isDisabled={disabledUpdate}
                hideSelectedOptions={false}
                placeholder=""
                onKeyDown={(e) => {
                  const focusedOption = selectedTags.find((tag) => {
                    const focused = document.querySelector(
                      '.tags-container .focused'
                    );
                    return focused?.id === tag.label;
                  });

                  if (
                    e.code === 'Enter' &&
                    focusedOption?.label
                      ?.toLowerCase()
                      .includes(removeExtraSpaces(tagInputVal?.toLowerCase()))
                  ) {
                    e.preventDefault();
                    return;
                  }
                }}
                createOptionPosition="first"
                isMulti
                maxMenuHeight={265}
                closeMenuOnSelect={false}
                inputValue={tagInputVal}
                formatCreateLabel={(inputValue: string) =>
                  `Create "${removeExtraSpaces(inputValue)}"`
                }
                backspaceRemovesValue
                isValidNewOption={() =>
                  tagInputVal?.trim() &&
                  !selectedTags.find(
                    (tag) => tag.value === removeExtraSpaces(tagInputVal)
                  )
                }
                filterOption={(option) =>
                  !tagInputVal ||
                  option.label
                    ?.toLowerCase()
                    .includes(removeExtraSpaces(tagInputVal?.toLowerCase()))
                }
                onInputChange={(val) => setTagInputVal(val)}
                onChange={debounce((values, actionMeta) => {
                  console.log(actionMeta);
                  if (actionMeta.action === 'deselect-option') {
                    const option = actionMeta.option;
                    if (option.isAllSelected) {
                      // deselect fall all selected assets
                      const updatePromises = activeAssets.map((asset) => {
                        const beforeAsset = cloneDeep(asset);

                        asset.tags = (asset.tags || []).filter(
                          (tag) => tag !== option.value
                        );
                        asset.modifiedAt = new Date().toISOString();
                        asset.modifiedBy = user?.id;
                        cacheUpdatedAsset(asset, beforeAsset);
                        return saveAsset(asset).then(() => {
                          dispatch(actionUpdateLibraryAsset(asset));
                        });
                      });
                      Promise.all(updatePromises)
                        .then(() =>
                          setFormValues((prev) => ({
                            ...prev,
                            loading: false,
                            errorMessage: ''
                          }))
                        )
                        .catch(() => {
                          setFormValues((prev) => ({
                            ...prev,
                            loading: false,
                            errorMessage: 'Failed to update.'
                          }));
                        });
                      return;
                    }
                    // add selected tag for assets that dont contain the tag
                    const updatePromises = activeAssets
                      .filter((asset) => !asset.tags.includes(option.value))
                      .map((asset) => {
                        const beforeAsset = cloneDeep(asset);
                        asset.tags = (asset.tags || []).concat(option.value);
                        asset.modifiedAt = new Date().toISOString();
                        asset.modifiedBy = user?.id;
                        cacheUpdatedAsset(asset, beforeAsset);
                        return saveAsset(asset).then(() => {
                          dispatch(actionUpdateLibraryAsset(asset));
                        });
                      });
                    Promise.all(updatePromises)
                      .then(() =>
                        setFormValues((prev) => ({
                          ...prev,
                          loading: false,
                          errorMessage: ''
                        }))
                      )
                      .catch(() => {
                        setFormValues((prev) => ({
                          ...prev,
                          loading: false,
                          errorMessage: 'Failed to update.'
                        }));
                      });
                    return;
                  }
                  if (actionMeta.action === 'create-option') {
                    const option = actionMeta.option;
                    const updatePromises = activeAssets.map((asset) => {
                      const beforeAsset = cloneDeep(asset);
                      asset.tags = (asset.tags || []).concat(
                        removeExtraSpaces(option.value)
                      );
                      asset.modifiedAt = new Date().toISOString();
                      asset.modifiedBy = user?.id;
                      cacheUpdatedAsset(asset, beforeAsset);
                      return saveAsset(asset).then(() => {
                        dispatch(actionUpdateLibraryAsset(asset));
                      });
                    });
                    Promise.all(updatePromises)
                      .then(() =>
                        setFormValues((prev) => ({
                          ...prev,
                          loading: false,
                          errorMessage: ''
                        }))
                      )
                      .catch(() => {
                        setFormValues((prev) => ({
                          ...prev,
                          loading: false,
                          errorMessage: 'Failed to update.'
                        }));
                      });
                  }
                  if (actionMeta.action === 'remove-value') {
                    const removedOption = actionMeta.removedValue;
                    const updatePromises = activeAssets
                      .filter((asset) =>
                        asset.tags.includes(removedOption.value)
                      )
                      .map((asset) => {
                        const beforeAsset = cloneDeep(asset);
                        asset.tags = (asset.tags || []).filter(
                          (tag) => tag !== removedOption.value
                        );
                        asset.modifiedAt = new Date().toISOString();
                        asset.modifiedBy = user?.id;
                        cacheUpdatedAsset(asset, beforeAsset);
                        return saveAsset(asset).then(() => {
                          dispatch(actionUpdateLibraryAsset(asset));
                        });
                      });
                    Promise.all(updatePromises)
                      .then(() =>
                        setFormValues((prev) => ({
                          ...prev,
                          loading: false,
                          errorMessage: ''
                        }))
                      )
                      .catch(() => {
                        setFormValues((prev) => ({
                          ...prev,
                          loading: false,
                          errorMessage: 'Failed to update.'
                        }));
                      });
                  }
                }, 100)}
                components={{
                  Option: TagOption
                }}
              />
              {!!selectedTags?.length && (
                <div
                  className="d-flex align-items-center flex-wrap w-100 mt-2"
                  style={{ marginTop: '-4px' }}
                >
                  {selectedTags?.map((tag, idx) => {
                    return (
                      <div
                        key={idx}
                        className="d-flex align-items-center pl-2 mr-1 mt-1 selected-tag"
                      >
                        <span>{tag.label}</span>
                        <div
                          onClick={() => {
                            handleRemoveTag(tag);
                          }}
                        >
                          <BsX color="#ffffff" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </Form.Group>
          </div>
        )}
      </div>

      <div className="update-error">
        {errorMessage && (
          <span className="text-danger text-small">{errorMessage}</span>
        )}
      </div>

      <style jsx>{`
        .selected-asset-container {
          padding: 0px 20px;
        }
        .selected-asset-container span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .selected-asset-container .group-btn {
          margin-top: 15px;
        }
        .remove-icon {
          cursor: pointer;
        }
        .selected-info {
          margin-top: 12px;
        }
        .selected-number {
          font-size: 12px;
          font-weight: 600;
        }
        .remove-icon > :global(svg) {
          width: 32px;
          height: 32px;
        }
        .btn-add-favorites {
          margin-bottom: 6px;
          padding-top: 4px;
          padding-bottom: 4px;
          cursor: pointer;
        }
        .btn-add-favorites span {
          margin-left: 6px;
          font-weight: 600;
          font-size: 12px;
          text-decoration: underline;
        }
        .form-selected-asset {
          font-weight: 400;
          font-size: 12px;
        }
        .file-info > span {
          margin-top: 6px;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          color: #606060;
        }
        .file-info > span > span {
          font-weight: 400;
          color: #000000;
        }
        .file-info .asset-status {
          color: #2c8800 !important;
        }
        .file-info .block-extension-file {
          width: 22px;
          height: 22px;
          background: #7ba97b;
          border-radius: 3px;
        }
        .file-info .block-extension-file span {
          font-weight: 600;
          font-size: 5px;
          line-height: 6px;
          color: #ffffff;
        }
        .bg-filename-extension-font {
          background: #a8a8a8 !important;
        }
        .bg-filename-extension-video {
          background: #000000 !important;
        }
        @keyframes fade-in {
          from {
            transform: translateX(100%);
          }
          to {
            transform: translateX(0);
          }
        }
        .fade-in {
          animation-name: fade-in;
          animation-duration: 0.3s;
          animation-iteration-count: 1;
        }
        .selected-tag {
          background: #8a8888;
          border-radius: 2px;
          padding: 5px 4px 5px 8px;
          color: #fff;
        }
        .selected-tag span {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          white-space: pre;
        }
        .selected-tag div {
          padding: 0px 4px;
          cursor: pointer;
        }
        .update-form.disabled {
          opacity: 0.4;
        }
      `}</style>
    </div>
  );
};
