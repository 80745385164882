import { useDispatch, useSelector } from 'react-redux';
import { IMainState, LoadingStatus } from '../../interfaces';
import {
  actionHubAlertError,
  actionHubSetLoadingStatus
} from '../../redux/actions';

export const useLoadingStatus = (): {
  isLoading: (key: string) => boolean;
  setLoadingStatus: (key: string, status: LoadingStatus) => void;
  getLoadingStatus: (key: string) => LoadingStatus;
} => {
  const dispatch = useDispatch();
  const loadingStatus = useSelector(
    (state: IMainState) => state.clientState.hub.loadingStatus || {}
  );

  return {
    isLoading: (key: string) => loadingStatus[key] === LoadingStatus.LOADING,
    setLoadingStatus: (key: string, status: LoadingStatus) => {
      dispatch(actionHubSetLoadingStatus(key, status));
      if (status === LoadingStatus.FAILED) {
        dispatch(
          actionHubAlertError(
            'Something went wrong, please check your internet connection.'
          )
        );
      }
    },
    getLoadingStatus: (key: string) => loadingStatus[key]
  };
};
