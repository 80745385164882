import React, { useEffect } from 'react';
import {
  getFileExtensionFromUrl,
  isImage,
  isPDF,
  isVideo,
  removeFileExtension
} from '../../../../utils/file';
import {
  cleanUpFileName,
  isAllowedForFileName
} from '../../../../components/VirtualBoutique/Appointment/inputValidator';
import PopupContainer from '../PopupContainer';
import { FaFile, FaFileImage, FaFilePdf, FaFileVideo } from 'react-icons/fa';
import { IconDelete } from '../HubIcons';
import DropZone from './DropZone';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { actionHubAlertError } from '../../../../redux/actions';

export interface FileToUpload {
  file: File;
  name: string;
  extension: string;
  error?: string;
}

const FileUpload = ({
  onClose,
  onUpload,
  files,
  rejectDuplicates,
  uploadedFiles,
  multipleUpload,
  acceptTypes = 'image/jpeg, image/png, application/pdf, video/mp4, video/quicktime',
  type = 'modal',
  showDialog,
  onDeleteFile,
  onUpdateFileList,
  hideCloseButton = false,
  dropZonePlaceholder,
  disabled,
  showFileListWhileDragging = false,
  allowEditFileName = true,
  showUploadList = true,
  controlled = false
}: {
  onClose?: () => void;
  onUpload?: (filesToUpload: { file: File; name: string }[]) => void;
  files?: File[];
  rejectDuplicates?: boolean;
  uploadedFiles?: string[];
  multipleUpload?: boolean;
  acceptTypes?: string;
  type?: 'modal' | 'inline';
  showDialog?: boolean;
  onDeleteFile?: (index: number) => void;
  onUpdateFileList?: (file: File[]) => void;
  hideCloseButton?: boolean;
  dropZonePlaceholder?: React.ReactNode;
  disabled?: boolean;
  showFileListWhileDragging?: boolean;
  allowEditFileName?: boolean;
  showUploadList?: boolean;
  controlled?: boolean;
}) => {

  const dispatch = useDispatch();

  const [fileList, setFileList] = React.useState<FileToUpload[]>([]);

  const hasFiles = !!fileList?.length;
  const hasError = fileList.some((f) => f.error);

  const isDuplicated = (name: string) => {
    return (
      rejectDuplicates &&
      (uploadedFiles?.includes(name) ||
        fileList.some((f) => `${f.name}.${f.extension}` === name))
    );
  };
  const onDrop = (files: File[], fileRejection?: any[]) => {
    if (fileRejection && !isEmpty(fileRejection)) {
      const err = fileRejection[0].errors?.[0];
      if(err) dispatch(actionHubAlertError(`Error: ${err.message}`));
    }
    const existingFiles = fileList.map((f) => f.file.name);
    const filteredFiles = files.filter((f) => !existingFiles.includes(f.name));
    if (files.length) {
      onUpdateFileList?.([
        ...fileList.map((file) => file.file),
        ...filteredFiles
      ]);

      setFileList([
        ...(multipleUpload ? fileList : []),
        ...filteredFiles.map((file) => ({
          file,
          name: removeFileExtension(cleanUpFileName(file.name)),
          extension: getFileExtensionFromUrl(file.name),
          error: isDuplicated(file.name)
            ? `File "${file.name}" has already been added.`
            : undefined
        }))
      ]);
    }
  };

  useEffect(() => {
    if (!controlled) {
      return;
    }

    setFileList((prev) => {
      return prev.filter((fUpload) =>
        files.find((f) => f.name === fUpload?.file?.name)
      );
    });
  }, [files]);

  const handleInputChange = (value: string, index: number) => {
    const currentFile = fileList[index];
    const error =
      value.trim() === ''
        ? 'Required'
        : !isAllowedForFileName(value)
        ? 'Invalid name'
        : isDuplicated(`${value}.${currentFile.extension}`)
        ? 'Duplicated'
        : '';

    setFileList(
      fileList.map((file, i) =>
        i === index ? { ...file, name: value, error } : file
      )
    );
  };

  const handleDelete = (index: number) => {
    setFileList(fileList.filter((_, i) => i !== index));
    onDeleteFile?.(index);
  };

  const getFileIcon = (name: string) => {
    if (isImage(name)) return <FaFileImage size={18} />;
    if (isPDF(name)) return <FaFilePdf size={18} />;
    if (isVideo(name)) return <FaFileVideo size={18} />;
    return <FaFile size={18} />;
  };

  const fileInput = (item: FileToUpload, index: number) => (
    <div className="file-item d-flex w-100" key={`${item.file.name}-${index}`}>
      <div className="input-container flex-grow-1">
        <input
          className={`form-control ${item.error ? 'is-invalid' : ''}`}
          type="text"
          name="name"
          data-lpignore="true"
          value={item.name || ''}
          placeholder="Enter a file name"
          onChange={(e) => {
            handleInputChange(e.target.value, index);
          }}
          disabled={!allowEditFileName}
        />
        {item.error && <span className="text-danger">{item.error}</span>}
        <div className="icon-container">{getFileIcon(item.file.name)}</div>
      </div>

      <button
        className="btn"
        type="button"
        disabled={disabled}
        onClick={() => handleDelete(index)}
      >
        <IconDelete />
      </button>
      <style jsx>{`
        .file-item {
          margin-bottom: 5px;
        }
        .file-item .text-danger {
          position: absolute;
          top: 0;
          transform: translateY(-50%);
          right: 10px;
          font-weight: 400;
        }
        .form-control {
          margin: 0;
          padding-left: 30px;
        }
        .btn {
          padding-left: 0;
          padding-right: 0;
        }

        .input-container {
          margin: 5px 0;
          position: relative;
        }
        .icon-container,
        .text-danger {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
        .icon-container {
          left: 5px;
        }
        .text-danger {
          right: 30px;
          background: #fff;
          font-size: 11px;
        }
      `}</style>
    </div>
  );

  const uploadList = (
    <div className="file-container d-flex flex-column shadow-box">
      <div className="file-list">
        {fileList.map((item, index) => fileInput(item, index))}
        {hasError && <span>Only accept letter and number for file name</span>}
      </div>

      <div className="text-center action w-100">
        {type === 'inline' && (
          <button
            className={`btn cancel-btn btn-round btn-outline-dark btn-sm`}
            onClick={() => {
              onClose?.();
              setFileList([]);
            }}
          >
            Cancel
          </button>
        )}
        <button
          disabled={hasError}
          className={`btn save-btn btn-round btn-dark btn-sm`}
          onClick={() => {
            onConfirm();
            onClose?.();
          }}
        >
          Upload
        </button>
      </div>

      <style jsx>{`
        .file-container {
          border-radius: 10px;
          background-color: #fff;
          margin: auto;
          width: 100%;
          max-height: 80%;
        }
        .cancel-btn {
          margin-right: 5px;
        }
        .file-list {
          height: 100%;
          overflow: auto;
          padding: 20px 10px 20px 20px;
          border-bottom: 1px solid #efefef;
        }
        span {
          font-size: 11px;
          display: block;
          margin-top: 5px;
          line-height: 1.2;
        }
        .action {
          padding: 15px 0;
        }
      `}</style>
    </div>
  );

  const clearForms = () => {
    setFileList([]);
  };

  const onConfirm = () => {
    const filesToUpload = fileList.map((f) => ({
      file: f.file,
      name: `${f.name}.${f.extension}`
    }));
    onUpload?.(filesToUpload);
    clearForms();
  };

  const dropZone = (
    <DropZone
      fileTypes={acceptTypes}
      multiple={multipleUpload}
      onDrop={onDrop}
      showDialog={showDialog}
      dropZonePlaceholder={dropZonePlaceholder}
      onClose={
        hideCloseButton ? undefined : type === 'inline' ? onClose : undefined
      }
      disabled={disabled}
      showFileListWhileDragging={showFileListWhileDragging}
    >
      {hasFiles && showUploadList ? uploadList : null}
    </DropZone>
  );

  React.useEffect(() => {
    if (files?.length) {
      onDrop(files);
    }
  }, [showDialog]);

  const uploadModal = (
    <PopupContainer
      onClose={() => {
        clearForms();
        onClose();
      }}
      height="75vh"
      maxWidth="1200px"
      className="UploadPopup"
    >
      <div className="upload-container w-100 h-100 d-flex flex-column">
        <div className="drop-container d-flex flex-column">{dropZone}</div>
      </div>
      <style jsx global>{`
        .UploadPopup .content-container {
          align-items: baseline !important;
        }
      `}</style>
      <style jsx>{`
        .drop-container {
          height: 100%;
        }
      `}</style>
    </PopupContainer>
  );

  return type === 'modal' ? uploadModal : dropZone;
};

export default FileUpload;
