import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AssetLibraryContext } from '.';
import SearchInput from '../Common/SearchInput';
import { logClickButton, logEvent } from '../../../analytics';
import {
  DID_CLICK_SORT_ASSETS_OPTION,
  DID_CLICK_UPLOAD_ASSET,
  HUB_ASSET_LIBRARY_EVENT
} from '../../../utils/constants';
import { components } from 'react-select';
import { SingleSelect } from '../Common/Select';
import { BsX } from 'react-icons/bs';
import { SetDefaultSelectIcon, DefaultSelectIcon } from '../Common/HubIcons';
import { HubContext } from '../HubContext';
import { isEmpty } from 'lodash';

export const sortDropdownOptions = [
  {
    value: 'title-asc',
    label: 'A to Z'
  },
  {
    value: 'title-desc',
    label: 'Z to A'
  },
  {
    value: 'createdat-desc',
    label: 'Newest'
  },
  {
    value: 'createdat-asc',
    label: 'Oldest'
  },
  {
    value: 'sizekb-desc',
    label: 'Largest'
  },
  {
    value: 'sizekb-asc',
    label: 'Smallest'
  }
];

export const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <div className="flex text-align-right">
        <span>{props.children}</span>
      </div>
    </components.SingleValue>
  );
};

export const Option = (props) => {
  const { selectProps, children } = props;
  const isOptionSelected = selectProps?.value?.label === children;
  const [hoverOption, setHoverOption] = useState(false);
  return (
    <components.Option {...props}>
      <div
        className="d-flex align-items-center px-2"
        onMouseMove={() => setHoverOption(true)}
        onMouseLeave={() => setHoverOption(false)}
      >
        <span className="label">{children}</span>
        {isOptionSelected && <DefaultSelectIcon />}
        {hoverOption && !isOptionSelected && <SetDefaultSelectIcon />}
        <div></div>
      </div>
      <style jsx>{`
        .label {
          width: 55px;
          font-weight: 300;
          font-size: 12px;
          color: #000000;
          margin-right: 10px;
        }
      `}</style>
    </components.Option>
  );
};

export const AssetLibraryHeader = () => {
  const {
    setUploadPopupVisibility,
    changeSearchOptions,
    searchOptions,
    deselectAssets,
    setRightMenuVisibility,
    displayRightMenu
  } = useContext(AssetLibraryContext);
  const sortValue = searchOptions.sortKey
    ? `${searchOptions.sortKey}-${searchOptions.sortOrder}`
    : '';
  const sortOption = sortDropdownOptions.find((op) => op.value === sortValue);
  const {guidedTour} = useContext(HubContext)

  return (
    <div className="asset-library-header d-flex flex-column flex-md-row border-bottom">
      <h2 className="title d-inline-block text-center text-md-left">
        Asset Library
      </h2>
      <div className="controls w-100 d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
        <div className="left d-inline-flex justify-content-between justify-content-sm-start mt-2">
          <Button
            variant="dark"
            className="mr-2 rounded-pill d-inline-block btn-upload"
            onClick={() => {
              logClickButton(DID_CLICK_UPLOAD_ASSET);
              setUploadPopupVisibility(true);
            }}
            style={{ fontSize: 12, fontWeight: 600, height: 30 }}
          >
            Upload
          </Button>
          <div className="d-flex align-items-center search-block">
            <SearchInput
              onSearch={(val) => {
                if (!val) return;
                const newKeywords = searchOptions?.keywords
                  ? [...searchOptions.keywords, val.trim()]
                  : [val.trim()];
                changeSearchOptions({ keywords: newKeywords });
                deselectAssets();
              }}
              rounded
              reset={true}
              placeHolder="Search Keywords"
              className="search-library-asset d-flex align-items-center"
            />
            { !searchOptions?.keywords?.length && !isEmpty(guidedTour) ? 
            <div className="guided-tour-search align-items-center ml-2">
            {['logo', 'white'].map((tag, idx) => {
              return (
                <div
                  key={idx}
                  className="d-flex align-items-center pl-2 py-1 mr-1 search-key"
                >
                  <span className="mr-1">{tag?.trim()}</span>
                  <div className="mr-1" >
                    <BsX color="#ffffff" />
                  </div>
                </div>
              );
            })}
          </div>
            :
            !!searchOptions?.keywords?.length && (
              <div className="d-flex align-items-center ml-2">
                {searchOptions.keywords?.map((tag, idx) => {
                  return (
                    <div
                      key={idx}
                      className="d-flex align-items-center pl-2 py-1 mr-1 search-key"
                    >
                      <span className="mr-1">{tag?.trim()}</span>
                      <div
                        onClick={() => {
                          const newKeywords = searchOptions.keywords
                          newKeywords.splice(idx, 1)
                          changeSearchOptions({
                            keywords: newKeywords
                          });
                        }}
                        className="mr-1"
                      >
                        <BsX color="#ffffff" />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="right mt-2 d-inline-flex">
          <label htmlFor="sortDropdown" className="sort-dropdown-label">
            Sort by
          </label>
          <SingleSelect
            id="sortDropdown"
            name="sortDropdown"
            isMulti={false}
            isClearable={false}
            isSearchable={false}
            inputId="sortDropdown"
            options={sortDropdownOptions}
            value={sortOption}
            onChange={(option) => {
              logEvent(HUB_ASSET_LIBRARY_EVENT, DID_CLICK_SORT_ASSETS_OPTION, {
                sortOption: option
              });
              const [key, order] = option.value.split('-');
              changeSearchOptions({
                sortKey: key as 'title' | 'sizekb' | 'createdat',
                sortOrder: order as 'asc' | 'desc'
              });
            }}
            components={{
              SingleValue: SingleValue,
              Option: Option
            }}
          />
          <div className="ml-3">
            <Button
              variant={displayRightMenu ? 'outline-dark' : 'dark'}
              onClick={() => {
                setRightMenuVisibility(!displayRightMenu);
              }}
              className="rounded-pill"
              style={{ fontSize: 12, fontWeight: 600, height: 30 }}
            >
              {displayRightMenu ? 'Hide Details' : 'Show Details'}
            </Button>
          </div>
        </div>
      </div>
      <style jsx>{`
        .asset-library-header {
          padding: 0px 20px 20px 30px;
        }
        :global(.guided-tour-search) {
          display: none;
        }
        .title {
          font-size: 24px;
          line-height: 40px;
          min-width: 250px;
          margin-right: 20px;
          margin-bottom: 0px !important;
        }
        .control {
          margin-top: -8px;
        }

        :global(.search-library-asset::placeholder) {
          color: #a8a8a8;
        }

        .controls :global(.btn-search svg) {
          fill: #a8a8a8;
        }

        @media screen and (max-width: 950px) {
          .asset-library-header {
            padding: 0px 15px 20px 15px;
          }
          .title {
            min-width: fit-content;
          }
          :global(.search-library-asset) {
            min-width: 180px !important;
          }
        }
        .search-key {
          background: #8a8888;
          border-radius: 22px;
          height: 20px;
        }
        .search-key span {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #fffcff;
        }
        .search-key div {
          cursor: pointer;
        }
        .sort-dropdown-label {
          font-weight: 400;
          margin-top: 6px;
          font-size: 12px;
          margin-bottom: 0;
          color: #a8a8a8;
        }
      `}</style>
    </div>
  );
};
