import CloseButton from './CloseButton';

const PopupContainer = ({
  onClose,
  children,
  width = '80%',
  height = 'auto',
  maxHeight = 'none',
  maxWidth = '1200px',
  zIndex = 5,
  className = ''
}: {
  onClose?: () => void;
  children: React.ReactNode;
  width?: string;
  maxWidth?: string;
  height?: string;
  maxHeight?: string;
  zIndex?: number;
  className?: string;
}) => {
  return (
    <div
      className={`PopupContainer fixed-full d-flex ${className}`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="content-container">
        {children}
        {onClose && (
          <div className="close-container">
            <CloseButton onClick={onClose} rounded={true} color="#fff" />
          </div>
        )}
      </div>

      <style jsx global>{`
        .SceneLibrary .content-wrapper,
        .StoryBook .content-wrapper {
          z-index: 2;
        }
      `}</style>

      <style jsx>{`
        .PopupContainer {
          background: rgba(0, 0, 0, 0.8) !important;
          display: flex;
          align-items: center;
          z-index: ${zIndex};
          padding-top: ${onClose ? '20px' : '0'};
          border-radius: 0 !important;
        }
        .content-container {
          width: ${width};
          max-width: ${maxWidth};
          height: ${height};
          max-height: ${maxHeight};
          padding: 15px;
          margin: auto;
          background: #fff;
          border-radius: 10px;
          position: relative;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }
        .close-container {
          position: absolute;
          top: -35px;
          right: -35px;
        }
      `}</style>
    </div>
  );
};

export default PopupContainer;
