import { IFileToUpload } from '../../../../interfaces';
import UploaderItem from './UploaderItem';

const Uploader = ({
  files,
  onDelete,
  onCompleted
}: {
  files: IFileToUpload[];
  onDelete: (key: string) => void;
  onCompleted?: (key: string) => void;
}) => {
  if (!files?.length) return null;
  return (
    <div className="uploading-container shadow-box">
      {files.map((f) => (
        <UploaderItem
          key={f.key}
          s3Key={f.key}
          bucket={f.bucket}
          name={f.name}
          file={f.file}
          onDelete={() => onDelete(f.key)}
          onCompleted={() => onCompleted(f.key)}
          metadata={f.metadata}
        />
      ))}
      <style jsx>{`
        .uploading-container {
          border-radius: 10px;
        }
      `}</style>
    </div>
  );
};

export default Uploader;
