import React from 'react';
import { useCollaborationPopup } from '../../../../components/hooks/collaborator';
import PopupContainer from '../PopupContainer';
import CollaboratorSelector from './CollaboratorSelector';
import CollaborationConflict from './CollaborationConflict';

const CollaboratorPopup = () => {
  const { collaborationPopup, conflictPopup, closePopup } =
    useCollaborationPopup();
  if (!collaborationPopup && !conflictPopup) return null;
  return (
    <PopupContainer
      width="600px"
      maxWidth="70%"
      className="HubCollaborator"
      zIndex={100}
    >
      {collaborationPopup && <CollaboratorSelector {...collaborationPopup} />}
      {conflictPopup && (
        <CollaborationConflict onClose={closePopup} {...conflictPopup} />
      )}

      {collaborationPopup && (
        <div className="popup-footer text-right w-100">
          <button
            className="btn btn-sm btn-round btn-dark"
            onClick={closePopup}
          >
            Close
          </button>
          <style jsx>{`
            .popup-footer {
              border-top: 1px solid #ccc;
              padding-top: 10px;
            }
          `}</style>
        </div>
      )}
    </PopupContainer>
  );
};

export default CollaboratorPopup;
