import React from 'react';
import { Spinner } from 'react-bootstrap';
import { logEvent } from '../../../analytics';
import { DID_CLICK_BUTTON } from '../../../utils/constants';
import { IconDelete } from './HubIcons';

const DeleteButton = ({
  label,
  onClick,
  color,
  size = 'xs',
  confirmation = 'Are you sure you want to remove this item?',
  xPosition = 'right',
  yPosition = 'bottom',
  indent = '-10px',
  popupWidth = 200,
  floatingPopup = false,
  okLabel = 'OK',
  cancelLabel = 'Cancel',
  isLoading = false,
  noConfirmation
}: {
  label?: string;
  onClick?: () => void;
  color?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  confirmation?: string;
  xPosition?: 'left' | 'right';
  yPosition?: 'top' | 'bottom';
  indent?: string;
  popupWidth?: number;
  floatingPopup?: boolean;
  okLabel?: string;
  cancelLabel?: string;
  isLoading?: boolean;
  noConfirmation?: boolean;
}) => {
  const [confirm, setConfirm] = React.useState(false);
  const isSmallPopup = popupWidth < 180;
  const btnSize = size === 'xs' ? 'sm' : size;
  const logClick = (name: string) => {
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
      button: 'Delete',
      label,
      action: name
    });
  };

  const confirmPopup = confirm && (
    <div
      className="confirm shadow-box text-center"
      onMouseLeave={() => setConfirm(false)}
    >
      <p className="text-semibold">{confirmation}</p>
      <div className="action-buttons w-100">
        <div className="w-100">
          <button
            className={`btn btn-${btnSize} btn-outline-dark keep-btn  btn-round`}
            onClick={(event) => {
              event.stopPropagation();
              setConfirm(false);
              logClick(cancelLabel);
            }}
          >
            {cancelLabel}
          </button>
        </div>
        <div>
          <button
            className={`btn btn-${btnSize} btn-dark btn-round remove-btn`}
            onClick={(event) => {
              event.stopPropagation();
              onClick();
              setConfirm(false);
              logClick(okLabel);
            }}
          >
            {isLoading ? <Spinner size="sm" animation="border" /> : okLabel}
          </button>
        </div>
      </div>
      <style jsx>{`
        .confirm {
          background: #fff;
          border-radius: 10px;
          padding: ${isSmallPopup ? '15px' : '20px'};
          position: absolute;
          right: ${xPosition === 'right' ? indent : 'auto'};
          left: ${xPosition === 'left' ? indent : 'auto'};
          top: ${yPosition === 'top' ? indent : 'auto'};
          bottom: ${yPosition === 'bottom' ? indent : 'auto'};
          width: ${popupWidth}px;
          z-index: 2;
        }
        .text-semibold {
          font-size: 12px;
        }
        .inner {
          padding: 10px;
          box-shadow: none;
        }
        .action-buttons {
          display: ${isSmallPopup ? 'block' : 'flex'};
        }
        .action-buttons div {
          width: ${isSmallPopup ? 'auto' : '100%'};
          padding: 2px;
        }
        .action-buttons .btn {
          width: 100%;
        }
        .remove-btn :global(svg) {
          width: 20px;
          height: auto;
        }
      `}</style>
    </div>
  );
  return (
    <>
      <span className="DeleteButton">
        <button
          className={`btn btn-del btn-${btnSize}`}
          onClick={(event) => {
            event.stopPropagation();
            if (noConfirmation) {
              onClick();
              logClick('Delete');
            } else {
              setConfirm(true);
              logClick('Ask For Delete');
            }
          }}
        >
          <IconDelete color={color || '#000'} />{' '}
          {label && <>{size === 'xs' ? label : <strong>{label}</strong>}</>}
        </button>

        {!floatingPopup && confirmPopup}
      </span>
      {floatingPopup && confirmPopup}
      <style jsx>{`
        .btn-del {
          padding: ${size === 'xs' ? 0 : 'inherit'};
        }

        span {
          position: relative;
        }
      `}</style>
    </>
  );
};

export default DeleteButton;
