import { flatten, sortBy } from 'lodash';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { ITeam, IMainState } from '../../../interfaces';
import {
  getUserTeams,
  getListTeams,
  getAllTeams
} from '../../clientSideServices/user';

export const fetchUserTeams = async (
  userId?: string,
  brandId?: string,
  batchSize?: number
): Promise<ITeam[]> => {
  const size = batchSize || 20;
  if (!userId || !brandId) {
    return Promise.resolve([]);
  }
  try {
    const result = await getUserTeams(userId, brandId, 0, size);
    const initialResult = result?.teams?.filter((t) => t) || [];
    const totalCount = result?.totalCount || 0;
    if (totalCount > initialResult.length) {
      const diff = totalCount - initialResult.length;
      const parallelRequestsSize = Math.ceil(diff / size);
      const parallelRequests = Array(parallelRequestsSize)
        .fill('')
        .map((_, index) => {
          const startIndex = size * (index + 1);
          return getUserTeams(userId, brandId, startIndex, size).then(
            (result) => result?.teams?.filter((t) => t) || []
          );
        });
      const subsequentResults = await Promise.all(parallelRequests);
      return sortBy([...initialResult, ...flatten(subsequentResults)], (team) =>
        team.name?.toLowerCase()
      );
    } else {
      return sortBy(initialResult, (team) => team.name?.toLowerCase());
    }
  } catch {
    return Promise.resolve([]);
  }
};

export const useUserTeams = (userId?: string, brandId?: string) => {
  const user = useSelector((state: IMainState) => state.clientState.hub?.user);
  return useQuery(
    ['user-teams', user?.id || userId, user?.brandId || brandId],
    () => fetchUserTeams(user?.id || userId, user?.brandId || brandId)
  );
};
export const useBrandTeams = (
  brandId: string,
  keywords: string,
  startIndex: number,
  batchSize: number
) => {
  return useQuery(['brand-teams', keywords, startIndex], () =>
    getListTeams(brandId, keywords, startIndex, batchSize)
  );
};

export const useAllTeams = () => {
  const user = useSelector((state: IMainState) => state.clientState.hub?.user);
  return useQuery(['all-teams', user?.brandId], () =>
    getAllTeams(user?.brandId)
  );
};
