import { Button } from "react-bootstrap";
import { IFolderKey } from "../../../interfaces";
import PopupContainer from "../Common/PopupContainer";

export const PopupConfirmDelete: React.FC<{
    open: boolean;
    onRequestClose?: () => void;
    label: string;
    handleDelete: (itemDelete: IFolderKey | string[]) => void;
    itemDelete: IFolderKey | string[];
		isPreview?: boolean
  }> = ({ open, onRequestClose, label, handleDelete, itemDelete, isPreview=false }) => {
    if (!open) return null;
  
    return (
      <PopupContainer maxWidth="300px" className={`${isPreview ? 'pt-0' : ''}`}>
        <div className={`d-flex flex-column ${!isPreview ? 'popup-confirm-delete' : 'popup-confirm-delete-preview'}`}>
          <div className="d-flex mb-3 w-100 justify-content-center">
            <span className="text-question">
							{label}
            </span>
          </div>
          <div className="d-flex mt-2 justify-content-between w-100 px-4">
            <Button
              className="rounded-pill"
              variant="outline-dark"
              style={{
                fontSize: 12,
                fontWeight: 600,
                height: 30,
                width: 'calc((100% - 10px) / 2)'
              }}
              onClick={() => {
                onRequestClose();
              }}
            >
              Cancel
            </Button>
            <Button
              className="rounded-pill"
              variant="dark"
              style={{
                fontSize: 12,
                fontWeight: 600,
                height: 30,
                width: 'calc((100% - 10px) / 2)'
              }}
              onClick={() => handleDelete(itemDelete)}
            >
              Confirm
            </Button>
          </div>
        </div>
        <style>{`
          .popup-confirm-delete {
            padding: 25px 20px;
          }
					.popup-confirm-delete-preview {
						width: 300px;
						padding: 40px 35px;
						background: #fff;
						margin: auto;
						border-radius: 10px;
					}
          .text-question {
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            ${isPreview ? 'width: 150px;' : ''}
          }
        `}</style>
      </PopupContainer>
    );
  };