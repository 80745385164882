import { useDispatch, useSelector } from 'react-redux';
import { IFileToUpload, IMainState } from '../../interfaces';
import {
  actionHubAddFileUploaded,
  actionHubClearFileUpload,
  actionHubAddConvertedFile,
  actionHubSetFileUpload,
  actionHubAbortFileUpload,
} from '../../redux/actions';

export const useUploadFile = () => {
  const dispatch = useDispatch();
  const state = useSelector(
    (state: IMainState) => state.clientState.hub?.fileUpload || {}
  );

  const addFilesToUpload = (files: IFileToUpload[]) => {
    dispatch(actionHubSetFileUpload([...(state?.files || []), ...files]));
  };

  const onUploadCompleted = (fileName: string) => {
    dispatch(actionHubAddFileUploaded(fileName));
  };
  const onFileConverted = (fileNames: string[]) => {
    dispatch(actionHubAddConvertedFile(fileNames));
  };

  const clearFileUpload = () => {
    dispatch(actionHubClearFileUpload());
  };

  const onAbortUpload = (key: string) => {
    dispatch(actionHubAbortFileUpload(key))
  };

  const uploadFileList =
    state.files
      ?.filter((file) => !state.uploaded?.includes(file.key))
      ?.filter((file2) => !state.converted?.includes(file2.key)) || [];

  const uploadedFiles =
    state.files?.filter((file) => state.uploaded?.includes(file.key)) || [];

  const unconvertedFiles =
    state.files
      ?.filter((file) => state.uploaded?.includes(file.key))
      ?.filter((file2) => !state.converted?.includes(file2.key)) || [];
  
  return {
    unconvertedFiles,
    uploadFileList,
    onAbortUpload,
    uploadedFiles,
    addFilesToUpload,
    onUploadCompleted,
    onFileConverted,
    clearFileUpload
  };
};
