import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { AssetCategories } from './AssetCategories';
import AssetThumbnail from './AssetThumbnail';
import PopupContainer from '../Common/PopupContainer';
import { SearchAssetOptions } from '../../clientSideServices/assetLibrary';
import { useAssetLibrary, useAssetLibraryFolder } from './hook';
import { HubContext } from '../HubContext';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import {
  FAVORITE_FOLDER,
  TRASH_FOLDER,
  UNCATEGORIZED_FOLDER
} from '../../utils/folders';
import { debounce, flatten, uniq } from 'lodash';
import { Spinner } from 'react-bootstrap';
import { actionSetPopupAssets } from '../../../redux/actions';
import { useBreakPointDetector, SCREEN_SIZE_LEVEL } from '../hooks/viewport';
import {
  EmptyPageIcon,
  IconOutlineArrow,
  RoundedCloseIcon
} from '../Common/HubIcons';
import SearchInput from '../Common/SearchInput';
import { BsX } from 'react-icons/bs';
import { sortDropdownOptions, SingleValue, Option } from './AssetLibraryHeader';
import { SingleSelect } from '../Common/Select';
import {
  DID_CLICK_SORT_ASSETS_OPTION,
  HUB_ASSET_LIBRARY_EVENT
} from '../../../utils/constants';
import { logEvent } from '../../../analytics';
import hotkeys from 'hotkeys-js';
import { isValidMimeByAcceptedType } from '../../../utils/file';
import { AssetPopupPreview } from './AssetPopupPreview';
import FileUpload from '../Common/FileUploader/FileUpload';

const DefaultSearchAssetOptions: SearchAssetOptions & { page?: number } = {
  userId: '',
  size: 20,
  startIndex: 0,
  page: 1,
  sortKey: 'createdat',
  sortOrder: 'desc'
};

export const AssetLibrarySelectPopup: React.FC = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<'asset-library' | 'upload-files'>(
    'asset-library'
  );
  const { brandId, user } = useContext(HubContext);

  const { assetLibrary, searchLibAssets, popup } = useAssetLibrary(
    brandId,
    user?.id,
    false
  );

  const { screenSizeLevel, screenSize } = useBreakPointDetector();
  const [showLeftMenu, setShowLeftMenu] = useState(true);

  const { folder: assetLibraryFolder } = useAssetLibraryFolder(
    brandId,
    user?.id,
    true
  );

  const handleClose = () => {
    dispatch(actionSetPopupAssets(undefined));
    closeButton?.onClose?.();
  };
  const {
    onSelectAsset,
    popupTitle,
    popupContext,
    closeButton,
    keywords,
    acceptTypes
  } = popup || {};

  const assetLibraryData = acceptTypes
    ? assetLibrary?.data?.filter((asset) =>
        isValidMimeByAcceptedType(asset.assetType, acceptTypes)
      )
    : assetLibrary?.data;

  const [
    {
      activeCategory,
      searchOptions,
      isMultipleSelectionEnabled,
      openPopupPreview,
      selectedAssets
    },
    setMenuState
  ] = useState<{
    activeCategory: string[];
    selectedAssets: string[];
    searchOptions: SearchAssetOptions & { page?: number };
    isMultipleSelectionEnabled: boolean;
    openPopupPreview: boolean;
  }>({
    activeCategory: [],
    searchOptions: DefaultSearchAssetOptions,
    isMultipleSelectionEnabled: false,
    selectedAssets: [],
    openPopupPreview: false
  });

  const sortValue = searchOptions.sortKey
    ? `${searchOptions.sortKey}-${searchOptions.sortOrder}`
    : '';
  const sortOption = sortDropdownOptions.find((op) => op.value === sortValue);
  const open = useMemo(() => {
    return !!popupContext;
  }, [popupContext]);

  const setActiveCategory = (id: string, singleOnly = false) => {
    setMenuState((prev) => {
      if (
        prev.isMultipleSelectionEnabled &&
        !singleOnly &&
        id !== UNCATEGORIZED_FOLDER &&
        !prev.activeCategory.includes(UNCATEGORIZED_FOLDER) && // can not multiple select with uncategorized category
        id !== TRASH_FOLDER &&
        !prev.activeCategory.includes(TRASH_FOLDER) &&
        id !== FAVORITE_FOLDER &&
        !prev.activeCategory.includes(FAVORITE_FOLDER)
      ) {
        if (prev.activeCategory.includes(id)) {
          return {
            ...prev,
            activeCategory: prev.activeCategory?.filter(
              (activeId) => id !== activeId
            )
          };
        }
        return {
          ...prev,
          activeCategory: prev.activeCategory?.concat(id)
        };
      }
      return {
        ...prev,
        activeCategory: prev.activeCategory.includes(id) ? [] : [id]
      };
    });
  };

  useEffect(() => {
    if (screenSizeLevel <= 1) {
      setShowLeftMenu(false);
    } else {
      setShowLeftMenu(true);
    }
  }, [screenSizeLevel]);

  const handleSearchAssets = useCallback(
    (
      options: Partial<SearchAssetOptions>,
      loadMore?: 'end' | 'start',
      onComplete?: () => void
    ) => {
      const newSearchOptions = Object.assign(searchOptions, options);
      newSearchOptions.assetTypes = acceptTypes? acceptTypes.split(",") : [];
      if (loadMore) {
        newSearchOptions.page = newSearchOptions.page + 1;
        newSearchOptions.startIndex =
          (newSearchOptions.page - 1) * newSearchOptions.size;
      } else {
        newSearchOptions.page = 1;
        newSearchOptions.startIndex = 0;
      }
      searchLibAssets(newSearchOptions, loadMore, onComplete);
      setMenuState((prev) => ({
        ...prev,
        searchOptions: { ...newSearchOptions }
      }));
    },
    [searchOptions, acceptTypes, user?.id]
  );

  useEffect(() => {
    if (!open) {
      setMenuState({
        activeCategory: [],
        searchOptions: DefaultSearchAssetOptions,
        isMultipleSelectionEnabled: false,
        selectedAssets: [],
        openPopupPreview: false
      });
      setActiveTab('asset-library');
      return;
    }
    handleSearchAssets({ keywords });
  }, [open]);

  const hasNextPage =
    assetLibrary?.total > 0 && assetLibrary?.total > assetLibrary?.data?.length;

  const scrollContainerRef = useInfiniteScroll<HTMLDivElement>({
    loading: assetLibrary?.loading,
    hasNextPage: hasNextPage,
    onLoadMore: debounce(() => {
      handleSearchAssets({}, 'end');
    }, 100)
  });

  useEffect(() => {
    hotkeys('*', { keyup: true, keydown: true }, (e) => {
      if (e.type === 'keydown') {
        if (e.ctrlKey || e.key === 'Meta' || e.key === 'Shift') {
          setMenuState((prev) => {
            return { ...prev, isMultipleSelectionEnabled: true };
          });
        }
      }
      if (e.type === 'keyup') {
        if (e.ctrlKey || e.key === 'Meta' || e.key === 'Shift') {
          setMenuState((prev) => {
            return { ...prev, isMultipleSelectionEnabled: false };
          });
        }
      }
    });

    return () => {
      hotkeys.unbind('*');
    };
  }, []);

  if (!open) {
    return null;
  }

  return (
    <PopupContainer
      maxWidth="1280px"
      maxHeight="calc(100vh - 90px)"
      zIndex={9999}
    >
      <span className="custom-close-btn" onClick={handleClose}>
        <RoundedCloseIcon />
      </span>
      <div className="popup-header d-flex w-100 row mx-0 px-0">
        <div className="integration-tab col-12 col-md-5 col-xl-3 col-lg-4 d-flex pr-0 pl-2">
          <span
            className={`integration-tab-item ${
              activeTab === 'asset-library' ? 'active' : ''
            }`}
            onClick={() => setActiveTab('asset-library')}
          >
            <h2 className="">{popupTitle}</h2>
          </span>
          {popup.onAddFileToUpload && (
            <>
              <span className="integration-tab-separator mx-2">
                <span>|</span>
              </span>
              <span
                className={`integration-tab-item ${
                  activeTab === 'upload-files' ? 'active' : ''
                }`}
                onClick={() => setActiveTab('upload-files')}
              >
                <h2>Upload File</h2>
              </span>
            </>
          )}
        </div>

        {activeTab === 'asset-library' && (
          <div
            className="d-flex align-items-center col-12 col-md-7 col-lg-8 col-xl-9 justify-content-end justify-content-lg-between flex-wrap content-right"
            style={{ marginTop: '-8px' }}
          >
            <div className="d-flex align-items-center mt-2 mr-3">
              <SearchInput
                onSearch={(val) => {
                  if (!val) return;
                  const newKeywords = searchOptions?.keywords
                    ? [...searchOptions.keywords, val.trim()]
                    : [val.trim()];
                  handleSearchAssets({ keywords: newKeywords });
                }}
                rounded
                reset={true}
                placeHolder="Search Keywords"
                className="search-library-asset d-flex align-items-center"
              />
              {!!searchOptions?.keywords?.length && (
                <div className="d-flex align-items-center ml-2 flex-wrap">
                  {searchOptions?.keywords?.map((tag, idx) => {
                    return (
                      <div
                        key={idx}
                        className="d-flex align-items-center pl-2 py-1 mr-1 search-key"
                      >
                        <span className="mr-1">{tag?.trim()}</span>
                        <div
                          onClick={() => {
                            const newKeywords = searchOptions.keywords.filter(
                              (item) => item !== tag
                            );
                            handleSearchAssets({
                              keywords: [...newKeywords]
                            });
                          }}
                          className="mr-1"
                        >
                          <BsX color="#ffffff" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="d-flex align-items-center mt-2 mr-3">
              <label htmlFor="sortDropdown" className="sort-dropdown-label">
                Sort by
              </label>
              <SingleSelect
                id="sortDropdown"
                name="sortDropdown"
                isMulti={false}
                isClearable={false}
                isSearchable={false}
                inputId="sortDropdown"
                options={sortDropdownOptions}
                value={sortOption}
                onChange={(option) => {
                  logEvent(
                    HUB_ASSET_LIBRARY_EVENT,
                    DID_CLICK_SORT_ASSETS_OPTION,
                    {
                      sortOption: option
                    }
                  );
                  const [key, order] = option.value.split('-');
                  handleSearchAssets({
                    sortKey: key as 'title' | 'sizekb' | 'createdat',
                    sortOrder: order as 'asc' | 'desc'
                  });
                }}
                components={{
                  SingleValue: SingleValue,
                  Option: Option
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="popup-content d-flex flex-md-row w-100 position-relative">
        {activeTab === 'asset-library' && (
          <>
            {showLeftMenu ? (
              <div
                className="left-menu w-25 d-flex pb-2"
                style={{ minWidth: 250 }}
              >
                {screenSizeLevel <= SCREEN_SIZE_LEVEL.sm && (
                  <div
                    className="position-absolute icon-close-category d-flex justify-content-center align-items-center"
                    style={{ right: 30 }}
                    onClick={() => {
                      setShowLeftMenu(false);
                    }}
                  >
                    <IconOutlineArrow direction="left" />
                  </div>
                )}
                <AssetCategories
                  editable={false}
                  activeKey={activeCategory}
                  showButtonCreateCategory={false}
                  isIntegrationModal
                  showTrashBin={false}
                  onClickMenu={(item) => {
                    setActiveCategory(item.id);

                    const isActivated = activeCategory.includes(item.id);
                    if (isActivated) {
                      // deactivate category
                      handleSearchAssets({
                        ...searchOptions,
                        categories: undefined,
                        status: undefined
                      });
                      return;
                    }

                    if (item.key === UNCATEGORIZED_FOLDER) {
                      handleSearchAssets({
                        ...searchOptions,
                        categories: [UNCATEGORIZED_FOLDER],
                        status: undefined
                      });
                      return;
                    }
                    const selectedCategories = isMultipleSelectionEnabled
                      ? uniq(activeCategory.concat(item.id))
                      : [item.id];

                    const relevantCategories = uniq(
                      flatten(
                        selectedCategories.map((cate) => {
                          const cateFolder = assetLibraryFolder?.keys?.find(
                            (k) => k.id === cate
                          );
                          return assetLibraryFolder.keys
                            ?.filter((k) => k.key.startsWith(cateFolder.key))
                            .map((k) => k.id);
                        })
                      )
                    );

                    handleSearchAssets({
                      ...searchOptions,
                      categories: relevantCategories,
                      status: undefined
                    });
                    if (screenSizeLevel <= SCREEN_SIZE_LEVEL.sm) {
                      setShowLeftMenu(false);
                    }
                  }}
                />
              </div>
            ) : (
              <div className="left-menu-hidden position-relative">
                <div
                  className="position-absolute icon-open-category d-flex justify-content-center align-items-center"
                  style={{ right: 8 }}
                  onClick={() => {
                    setShowLeftMenu(true);
                  }}
                >
                  <IconOutlineArrow direction="right" />
                </div>
              </div>
            )}

            <div
              className={`position-relative ${
                screenSizeLevel <= SCREEN_SIZE_LEVEL.sm ? 'w-100' : 'w-75'
              } ${
                screenSizeLevel <= SCREEN_SIZE_LEVEL.sm && showLeftMenu
                  ? 'list-asset'
                  : ''
              } right`}
            >
              {!assetLibrary?.loading &&
                (assetLibrary?.total === 0 ||
                  (assetLibraryData || [])?.length === 0) && (
                  <div className="empty-results">
                    <div className="d-flex justify-content-center mx-auto mb-2 empty-icon">
                      <EmptyPageIcon />
                    </div>
                    <div className="text-center title">
                      {(searchOptions.keywords || []).length === 0
                        ? 'This category is empty'
                        : 'Sorry, there are no results match your search criteria'}
                    </div>
                  </div>
                )}
              <div className="d-flex flex-wrap" ref={scrollContainerRef}>
                {assetLibraryData?.map((assetItem) => {
                  return (
                    <AssetThumbnail
                      key={assetItem.id}
                      item={assetItem}
                      onClick={(asset) => {
                        onSelectAsset(asset);
                      }}
                      onClickView={(item) => {
                        setMenuState((prev) => ({
                          ...prev,
                          openPopupPreview: true,
                          selectedAssets: [item.id]
                        }));
                      }}
                      selected={selectedAssets.includes(assetItem.id)}
                    />
                  );
                })}
              </div>
              <div className="d-flex justify-content-center p-3">
                {assetLibrary?.loading && (
                  <Spinner animation="border" variant="secondary" />
                )}
              </div>
            </div>
          </>
        )}
        {activeTab === 'upload-files' && (
          <div className="d-flex justify-content-center align-items-center w-100 file-upload">
            <FileUpload
              onUpdateFileList={(files) => {
                popup?.onAddFileToUpload?.(files?.[0]);
              }}
              multipleUpload={false}
              showUploadList={false}
              type="inline"
              acceptTypes={acceptTypes}
              dropZonePlaceholder="Drag ‘n’ drop or click
              here to select files"
            />
          </div>
        )}
      </div>
      <AssetPopupPreview
        open={openPopupPreview}
        onRequestClose={() => {
          setMenuState((prev) => ({ ...prev, openPopupPreview: false }));
        }}
        selectAsset={(item) => {
          setMenuState((prev) => ({ ...prev, selectedAssets: [item.id] }));
        }}
        screen={{ screenSize, screenSizeLevel }}
        selectedAssets={selectedAssets}
        updatable={false}
      />
      <style jsx>{`
        .popup-header {
          border-bottom: 1px solid #ccc;
          padding: 20px 16px 20px 12px;
        }
        .popup-header h2 {
          font-size: 18px !important;
        }
        .popup-footer {
          border-top: 1px solid #ccc;
          padding: 1rem;
          min-height: 50px;
        }
        .popup-content {
          padding: 0 15px 10px 15px !important;
        }
        .left-menu {
          border-right: 1px solid #d8d8d8;
          background: #ffffff;
        }
        .left-menu-hidden {
          width: 40px;
          border-right: 1px solid #ccc;
        }
        .list-asset {
          margin-left: 40px;
        }
        .empty-results {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          font-weight: 600;
          font-size: 20px;
          text-align: center;
        }

        .empty-results .empty-icon {
          width: 330px;
          height: 250px;
          padding-left: 40px;
        }
        .empty-results .title {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #bebebe;
        }

        .integration-tab .integration-tab-item {
          cursor: pointer;
        }
        .integration-tab-item {
          color: #a8a8a8;
          font-weight: 600;
        }
        .integration-tab-item h2 {
          font-size: 18px;
        }
        .integration-tab-item.active {
          color: #000;
        }
        .file-upload :global(.dropzone) {
          width: 80%;
          height: 80%;
        }
        .file-upload :global(.dropzone .info .small) {
          display: none;
        }
        .popup-header h2 {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 0;
          line-height: 22px;
        }

        @media screen and (max-width: 768px) {
          .left-menu {
            position: absolute !important;
            top: 0;
            left: 0;
            height: 100%;
            z-index: 9;
          }
          .popup-header {
            padding-top: 0px;
          }
          .popup-header .title,
          .content-right {
            width: 100% !important;
          }
          .popup-header .content-right {
            margin-top: 8px !important;
          }
          .popup-content {
            height: min(350px, 50vh) !important;
          }
        }
        .icon-open-category,
        .icon-close-category {
          width: 25px;
          height: 25px;
          top: 16px;
        }
        .popup-content .right {
          overflow: scroll;
        }

        .popup-content {
          overflow: auto;
          height: min(650px, 60vh);
          padding: 1rem 0rem 1rem 1rem;
        }

        :global(.PopupContainer .content-container) {
          padding: 10px;
          overflow: auto;
        }
        .category-overview-header > span {
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: #000000;
        }
        .menu-overview-item {
          cursor: pointer;
          height: 31px;
        }
        .menu-overview-item .menu-item--label {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
        }
        .menu-overview-item:hover {
          background-color: #f5f5f5;
        }
        .menu-overview-item.active {
          background-color: #d8d8d8;
        }
        .search-key {
          background: #8a8888;
          border-radius: 22px;
          height: 20px;
        }
        .search-key span {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #fffcff;
        }
        .search-key div {
          cursor: pointer;
        }
        .sort-dropdown-label {
          font-weight: 600;
          font-size: 12px;
          margin-bottom: 0;
        }
        .popup-content :global(.file-upload .dropzone) {
          width: 100%;
          height: 95%;
        }
        .custom-close-btn {
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
          z-index: 10;
        }
        @media screen and (max-width: 950px) {
          :global(.search-library-asset) {
            min-width: 180px !important;
          }
        }
        @media screen and (max-width: 1330px) {
          .popup-header h2 {
            font-size: 14.5px !important;
          }
          .popup-content {
            height: min(400px, 48vh) !important;
          }
        }
        :global(.content-wrapper) {
          z-index: 2 !important;
        }
        :global(.search-library-asset) {
          min-width: 140px !important;
          padding-right: 10px !important;
        }
      `}</style>
    </PopupContainer>
  );
};
