/* eslint-disable @typescript-eslint/no-empty-function */
import {
  cloneDeep,
  first,
  flatten,
  isEmpty,
  last,
  max,
  min,
  reverse,
  uniq
} from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { IAsset, IFolderKey } from '../../../interfaces';

import FileUploadProgressPanel from '../Common/FileUploader/FileUploadProgressPanel';
import { HubContext } from '../HubContext';
import Layout from '../Layout';
import { AssetCategories } from './AssetCategories';
import { AssetLibraryHeader } from './AssetLibraryHeader';
import { AssetUploadPopup } from './AssetUploadPopup';
import ListAssets from './ListAssets';
import { SelectedAsset } from './SelectedAsset';
import { AssetPopupPreview } from './AssetPopupPreview';
import {
  useBreakPointDetector,
  getScreenSize,
  SCREEN_SIZE_LEVEL
} from '../hooks/viewport';
import { IconOutlineArrow } from '../Common/HubIcons';
import { useAssetLibrary, useAssetLibraryFolder } from './hook';
import {
  cacheUpdatedAsset,
  saveAsset,
  SearchAssetOptions
} from '../../clientSideServices/assetLibrary';
import { TRASH_FOLDER, UNCATEGORIZED_FOLDER } from '../../utils/folders';
import hotkeys from 'hotkeys-js';
import { useDispatch } from 'react-redux';
import { actionDeleteAssetFromStore } from '../../../redux/actions';

type AssetLibraryContextState = {
  isMultipleSelectionEnabled: boolean;
  multipleSelectTrigger?: string;
  selectedAssets?: string[];
  openUploadPopup?: boolean;
  openPopupPreview?: boolean;
  displayRightMenu: boolean;
  searchOptions: SearchAssetOptions;
  screen?: {
    screenSize: ReturnType<typeof getScreenSize>;
    screenSizeLevel: number;
  };
  activeCategory?: string[];
  draggedFiles?: File[];
};
type AssetLibraryContextAction = {
  setUploadPopupVisibility: (isOpen: boolean, draggedFiles?: File[]) => void;
  setPopupPreviewVisibility: (isOpen: boolean) => void;
  setRightMenuVisibility: (isOpen: boolean) => void;
  selectAsset: (asset: IAsset, forceAdd?: boolean, multiple?: boolean) => void;
  deselectAssets: (ids?: string[]) => void;
  setActiveCategory: (id: string, singleOnly?: boolean) => void;
  changeSearchOptions: (
    options: Partial<AssetLibraryContextState['searchOptions']>,
    loadMore?: 'end' | 'start',
    onComplete?: () => void
  ) => void;
};

const DefaultSearchAssetOptions: AssetLibraryContextState['searchOptions'] = {
  userId: '',
  size: 20,
  startIndex: 0,
  page: 1,
  sortKey: 'createdat',
  sortOrder: 'desc'
};

export const AssetLibraryContext = React.createContext<
  AssetLibraryContextState & AssetLibraryContextAction
>({
  setUploadPopupVisibility: () => {},
  setPopupPreviewVisibility: () => {},
  setRightMenuVisibility: () => {},
  selectAsset: () => {},
  deselectAssets: () => {},
  changeSearchOptions: () => {},
  setActiveCategory: () => {},
  searchOptions: DefaultSearchAssetOptions,
  isMultipleSelectionEnabled: false,
  displayRightMenu: true
});

const AssetLibraryPage = () => {
  
  hotkeys.setScope(`asset-library-index`);

  const { brandId, user } = useContext(HubContext);
  const { screenSize, screenSizeLevel } = useBreakPointDetector();
  const [
    {
      openUploadPopup,
      selectedAssets,
      openPopupPreview,
      searchOptions,
      draggedFiles,
      isMultipleSelectionEnabled,
      activeCategory,
      displayRightMenu
    },
    setState
  ] = useState<AssetLibraryContextState>({
    selectedAssets: [],
    searchOptions: { ...DefaultSearchAssetOptions },
    isMultipleSelectionEnabled: false,
    activeCategory: [],
    displayRightMenu: true
  });

  const dispatch = useDispatch();
  const [showLeftMenu, setShowLeftMenu] = useState(true);
  const [showRightSidebar, setShowRightSidebar] = useState(true);

  useEffect(() => {
    if (screenSizeLevel <= 1) {
      setShowLeftMenu(false);
      setShowRightSidebar(false);
    } else {
      setShowLeftMenu(true);
      setShowRightSidebar(true);
    }
  }, [screenSizeLevel]);

  const { folder: assetLibraryFolder } = useAssetLibraryFolder(
    brandId,
    user?.id
  );
  const { searchLibAssets, assetLibrary } = useAssetLibrary(
    brandId,
    user?.id,
    true
  );

  const setUploadPopupVisibility = (isOpen: boolean, draggedFiles) => {
    setState((prev) => ({ ...prev, openUploadPopup: isOpen, draggedFiles }));
  };

  const setPopupPreviewVisibility = (isOpen: boolean) => {
    setState((prev) => ({ ...prev, openPopupPreview: isOpen }));
  };
  const setRightMenuVisibility = (isOpen: boolean) => {
    setState((prev) => ({ ...prev, displayRightMenu: isOpen }));
  };

  const changeSearchOptions = useCallback(
    (
      options: Partial<AssetLibraryContextState['searchOptions']>,
      loadMore?: 'end' | 'start',
      onComplete?: () => void,
      executeSearch = true
    ) => {
      const newSearchOptions = Object.assign(searchOptions, options);
      if (loadMore) {
        newSearchOptions.page = newSearchOptions.page + 1;
        newSearchOptions.startIndex =
          (newSearchOptions.page - 1) * newSearchOptions.size;
      } else {
        newSearchOptions.page = 1;
        newSearchOptions.startIndex = 0;
      }
      if (executeSearch) {
        searchLibAssets(newSearchOptions, loadMore, onComplete);
      }
      setState((prev) => ({ ...prev, searchOptions: { ...newSearchOptions } }));
    },
    [searchOptions, user?.id]
  );

  const selectAsset = (asset: IAsset, forceAdd?: boolean) => {
    setState((prev) => {
      if (!prev.isMultipleSelectionEnabled) {
        return {
          ...prev,
          selectedAssets: [asset.id]
        };
      }

      if (prev.multipleSelectTrigger === 'Shift') {
        const firstSelectedIndex = isEmpty(selectedAssets)
          ? 0
          : assetLibrary?.data?.findIndex(
              (a) => a.id === first(prev.selectedAssets)
            );
        const lastSelectedIndex = assetLibrary?.data?.findIndex(
          (a) => a.id === asset.id
        );
        const smallerIndex = min([firstSelectedIndex, lastSelectedIndex]);
        const biggerIndex = max([firstSelectedIndex, lastSelectedIndex]);
        let newSelectedAssetIds = assetLibrary?.data
          ?.slice(smallerIndex, biggerIndex + 1)
          ?.map((a) => a.id);
        if (firstSelectedIndex > lastSelectedIndex || isEmpty(selectedAssets)) {
          newSelectedAssetIds = reverse(newSelectedAssetIds);
        }
        return {
          ...prev,
          selectedAssets: newSelectedAssetIds
        };
      }

      if (prev.selectedAssets.includes(asset.id)) {
        return {
          ...prev,
          selectedAssets: forceAdd
            ? prev.selectedAssets
                .filter((id) => id !== asset.id)
                .concat([asset.id])
            : prev.selectedAssets.filter((k) => k !== asset.id)
        };
      }
      return {
        ...prev,
        selectedAssets: [...prev.selectedAssets, asset.id]
      };
    });
  };

  const deselectAssets = (ids?: string[]) => {
    setState((prev) => {
      let nextSelectedAssets = [];
      if (ids) {
        nextSelectedAssets = prev.selectedAssets?.filter(
          (id) => !ids.includes(id)
        );
      }
      return {
        ...prev,
        selectedAssets: nextSelectedAssets
      };
    });
  };

  const setActiveCategory = (id: string, singleOnly = false) => {
    setState((prev) => {
      if (
        prev.isMultipleSelectionEnabled &&
        !singleOnly &&
        id !== UNCATEGORIZED_FOLDER &&
        !prev.activeCategory.includes(UNCATEGORIZED_FOLDER) && // can not multiple select with uncategorized category
        id !== TRASH_FOLDER &&
        !prev.activeCategory.includes(TRASH_FOLDER)
      ) {
        if (prev.activeCategory.includes(id)) {
          return {
            ...prev,
            activeCategory: prev.activeCategory?.filter(
              (activeId) => id !== activeId
            )
          };
        }
        return {
          ...prev,
          activeCategory: prev.activeCategory?.concat(id)
        };
      }
      return {
        ...prev,
        activeCategory: prev.activeCategory.includes(id) ? [] : [id]
      };
    });
  };


  useEffect(() => {
    return () => {
      hotkeys.unbind('ctrl+a,cmd+a');
    }; 
  }, []);

  useEffect(() => {
    if (isEmpty(assetLibrary?.data)) {
      return;
    }
  }, [assetLibrary?.data]);

  hotkeys('ctrl+a,cmd+a', `asset-library-index`, (e) => {
    e.preventDefault();
    setState((prev) => ({
      ...prev,
      selectedAssets: assetLibrary?.data?.map((asset) => asset.id)
    }));
  });

  const previewAsset = useMemo(() => {
    return assetLibrary?.data?.find(
      (asset) => asset.id === last(selectedAssets)
    );
  }, [assetLibrary, selectedAssets]);

  const filterAssetsByCategory = (item: IFolderKey) => {
    const isActivated = activeCategory.includes(item.id);
    if (isActivated) {
      // deactivate category
      changeSearchOptions({
        categories: undefined,
        status: undefined
      });
      return;
    }

    if (item.id === TRASH_FOLDER) {
      changeSearchOptions({
        categories: undefined,
        status: 'deleted'
      });
      return;
    }

    if (item.key === UNCATEGORIZED_FOLDER) {
      changeSearchOptions({
        categories: [UNCATEGORIZED_FOLDER],
        status: undefined
      });
      return;
    }
    const selectedCategories = isMultipleSelectionEnabled
      ? uniq(activeCategory.concat(item.id))
      : [item.id];

    const relevantCategories = uniq(
      flatten(
        selectedCategories.map((cate) => {
          const cateFolder = assetLibraryFolder?.keys?.find(
            (k) => k.id === cate
          );
          return assetLibraryFolder.keys
            ?.filter((k) => k.key.startsWith(cateFolder?.key))
            .map((k) => k.id);
        })
      )
    );
    changeSearchOptions({
      categories: relevantCategories,
      status: undefined
    });
  };

  const updateMultipleMode = (e) => {
    if(e?.ctrlKey || e?.shiftKey || e?.metaKey) {
      setState((prev) => {
        return {
          ...prev,
          isMultipleSelectionEnabled: true,
          multipleSelectTrigger: e.ctrlKey? "Ctrl": e.shiftKey? "Shift" : e.metaKey ? "Meta" : null
        };
      });
    }
    else {
      setState((prev) => {
        if (prev.isMultipleSelectionEnabled) {
          return {
            ...prev,
            isMultipleSelectionEnabled: false
          };
        }
        return prev;
      })
    }
  }

  return (
    <Layout
      showNav={true}
      theme="dark-light"
      className="layoutSessions asset-library-page h-100"
    >
      <AssetLibraryContext.Provider
        value={{
          selectedAssets,
          openPopupPreview,
          setPopupPreviewVisibility,
          setUploadPopupVisibility,
          setRightMenuVisibility,
          selectAsset,
          deselectAssets,
          changeSearchOptions,
          setActiveCategory,
          screen: { screenSize, screenSizeLevel },
          searchOptions,
          activeCategory,
          draggedFiles: draggedFiles,
          isMultipleSelectionEnabled: isMultipleSelectionEnabled,
          displayRightMenu
        }}
      >
        <AssetLibraryHeader />
        <div className="main d-flex position-relative">
          {showLeftMenu ? (
            <div
              className={`left-menu position-relative ${
                screenSizeLevel <= SCREEN_SIZE_LEVEL.sm ? 'fade-in' : ''
              }`}
            >
              {screenSizeLevel <= SCREEN_SIZE_LEVEL.sm && (
                <div
                  className="position-absolute icon-close-category d-flex justify-content-center align-items-center"
                  style={{ right: 30 }}
                  onClick={() => {
                    setShowLeftMenu(false);
                  }}
                >
                  <IconOutlineArrow direction="left" />
                </div>
              )}
              <AssetCategories
                activeKey={activeCategory}
                showButtonCreateCategory={true}
                onClickMenu={(item, e) => {
                  updateMultipleMode(e)
                  if (!activeCategory.includes(item.id)) {
                    deselectAssets(selectedAssets);
                  }
                  setActiveCategory(item.id);
                  filterAssetsByCategory(item);
                }}
                onDropToItem={(item, transferData) => {
                  if (activeCategory.includes(item.id)) {
                    return;
                  }
                  const droppedAssetIds = transferData
                    .getData('assetIds')
                    .split(',');
                  const droppedAssets = assetLibrary?.data?.filter((a) =>
                    droppedAssetIds.includes(a.id)
                  );

                  droppedAssets.map((droppedAsset) => {
                    const beforeUpdateAsset = cloneDeep(droppedAsset);
                    if (
                      droppedAsset &&
                      !droppedAsset.categories.includes(item.id)
                    ) {

                      if (item.id === UNCATEGORIZED_FOLDER) droppedAsset.categories = [item.id]
                      else droppedAsset.categories = droppedAsset.categories
                        .filter((cate) => !activeCategory.includes(cate) && !(cate === UNCATEGORIZED_FOLDER))
                        .concat(item.id);
                      cacheUpdatedAsset(droppedAsset, beforeUpdateAsset);
                      if(!isEmpty(activeCategory)) {
                        const relevantCategories = uniq(
                          flatten(
                            activeCategory.map((cate) => {
                              const cateFolder = assetLibraryFolder?.keys?.find(
                                (k) => k.id === cate
                              );
                              return assetLibraryFolder.keys
                                ?.filter((k) => k.key.startsWith(cateFolder?.key))
                                .map((k) => k.id);
                            })
                          )
                        );
                        if(!relevantCategories.includes(item.id))
                        dispatch(actionDeleteAssetFromStore([droppedAsset.id]));
                      }

                      saveAsset(droppedAsset).then(() => {
                        deselectAssets();
                      }).catch((e) => {
                        console.log('Failed to  update', e);
                      });
                    }
                  });
                }}
                onClick={() => {
                  setState((prev) => ({ ...prev, activeCategory: [] }));
                  changeSearchOptions({
                    categories: [],
                    status: undefined
                  });
                }}
              />
            </div>
          ) : (
            <div
              style={{ paddingLeft: 40 }}
              className="left-menu-hidden position-relative"
            >
              <div
                className="position-absolute icon-open-category d-flex justify-content-center align-items-center"
                style={{ right: 8 }}
                onClick={() => {
                  setShowLeftMenu(true);
                  setShowRightSidebar(false);
                }}
              >
                <IconOutlineArrow direction="right" />
              </div>
            </div>
          )}
          <div
            className={`content position-relative ${
              showLeftMenu && screenSizeLevel <= 1
                ? 'content-open-menu-category'
                : ''
            }`}
          >
            <ListAssets onClickItem={updateMultipleMode}/>
          </div>
          {displayRightMenu && (
            <div
              className={`right-sidebar ${
                !showRightSidebar ? 'right-sidebar-close' : ''
              } ${screenSizeLevel <= 1 ? 'pl-3' : ''} `}
            >
              {showRightSidebar ? (
                <>
                  {screenSizeLevel <= 1 && (
                    <div
                      className="position-absolute icon-close-category d-flex justify-content-center align-items-center"
                      style={{ left: 6 }}
                      onClick={() => {
                        setShowRightSidebar(false);
                      }}
                    >
                      <IconOutlineArrow direction="right" />
                    </div>
                  )}
                  <SelectedAsset
                    isPreview={false}
                    previewAsset={previewAsset}
                    activeKey={activeCategory}
                  />
                </>
              ) : (
                <div
                  className="position-absolute icon-open-category d-flex justify-content-center align-items-center"
                  style={{ right: 6 }}
                  onClick={() => {
                    setShowRightSidebar(true);
                    setShowLeftMenu(false);
                  }}
                >
                  <IconOutlineArrow direction="left" />
                </div>
              )}
            </div>
          )}
        </div>
       {openUploadPopup && <AssetUploadPopup open={openUploadPopup} />}
       {openPopupPreview && <AssetPopupPreview
          open={openPopupPreview}
          onRequestClose={() => {
            deselectAssets(selectedAssets);
            setPopupPreviewVisibility(false);
          }}
          selectAsset={selectAsset}
          screen={{ screenSize, screenSizeLevel }}
          selectedAssets={selectedAssets}
        />}
        <FileUploadProgressPanel />
      </AssetLibraryContext.Provider>
      <style jsx>{`
        :global(.content-wrapper) { 
          height: calc(100% - 70px) !important;
        }
        .main {
          height: calc(100% - 62px);
          overflow: auto;
        }
        .right-sidebar {
          border-left: 1px solid #ccc;
          min-width: 266px;
          background: #ffffff;
        }
        .content {
          min-width: ${!displayRightMenu && assetLibrary?.total === 0
            ? screenSizeLevel <= 1
              ? 'calc(100% - 41px)'
              : 'calc(100% - 300px)'
            : 'calc(100% - 566px)'};
        }
        .content-open-menu-category {
          padding-left: 40px;
        }
        .left-menu {
          border-right: 1px solid #ccc;
          background: #ffffff;
          min-width: 300px;
          max-width: 300px;
          padding-left: 20px;
        }
        .left-menu-hidden {
          border-right: 1px solid #ccc;
        }
        @media screen and (max-width: 768px) {
          .left-menu {
            position: absolute !important;
            top: 0;
            left: 0;
            height: 100%;
            z-index: 9;
          }
          .right-sidebar {
            position: absolute !important;
            top: 0;
            right: 0;
            height: 100%;
          }
        }
        .icon-open-category,
        .icon-close-category {
          width: 25px;
          height: 25px;
          top: 16px;
        }
        .right-sidebar-close {
          min-width: 40px;
        }
        @keyframes fade-in {
          from {
            transform: translateX(-100%);
          }
          to {
            transform: translateX(0);
          }
        }
        .fade-in {
          animation-name: fade-in;
          animation-duration: 0.3s;
          animation-iteration-count: 1;
        }
      `}</style>
    </Layout>
  );
};

export default AssetLibraryPage;
