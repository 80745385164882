import { Auth } from 'aws-amplify';
import { orderBy } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { saveUser } from '../../../clientSideServices/users';
import {
  ICreateUserPayload,
  IEntitlement,
  INewInviteUser,
  InvitationStatus,
  IMainState,
  ISocialMedia,
  IStore,
  IUser,
  RecordingEntitlement,
  RoleEntitlement
} from '../../../interfaces';
import {
  actionAddUserInfo,
  actionHubSessionUpdateFileUploadProgress,
  actionUpdateUserAttr
} from '../../../redux/actions';
import { DID_DELETE_AVATAR, DID_UPLOAD_AVATAR } from '../../../utils/constants';
import { cleanLowerCase } from '../../../utils/string';
import { isOnboardingBrand } from '../../../utils/window';
import { uploadToS3 } from '../../clientSideServices/session';
import { getStoresByBrandId } from '../../clientSideServices/store';
import {
  createNewUser,
  deleteUserById,
  getListUsers,
  getListUsersByBrand,
  inviteNewUsers
} from '../../clientSideServices/user';
import { STREAM_TOKENS_ATTR } from '../../utils/amplify';
import {
  exchangeFacebookToken,
  mapSocialMediaContacsToStreamTokens
} from '../../utils/facebook';
import { MEET_ADMIN_USER, MEET_USER, SALES } from '../../utils/hubEntitlements';
import { HubContext } from '../HubContext';
export interface NewUser {
  first_name: string;
  last_name: string;
  email: string;
  mobilePhoneCountryCode?: string;
  mobileNumber: string;
  user_name: string;
  alias?: string;
  storeId: string;
  recordingOption?: RecordingEntitlement;
  roleOption?: RoleEntitlement;
}
export const HubUserContext = React.createContext<{
  editMode: boolean;
  setEditMode: (boolean) => void;
  updateUser: (identityId: string, user: IUser) => void;
  updating: boolean;
  fetchUsersByAdmin: () => void;
  users: IUser[];
  changePwdView: boolean;
  setChangePwdView: (boolean) => void;
  createUser: (user: NewUser) => Promise<any>;
  inviteUsers: (user: INewInviteUser[]) => Promise<any>;
  changePassword: (oldPwd: string, newPwd: string) => Promise<any>;
  stores: IStore[];
  isAdminPage: boolean;
  setIsAdminPage: (boolean) => void;
  deleteUser: (string) => Promise<any>;
  uploadAvatarTos3: (File: File, fileName: string) => Promise<any>;
  deleteAvatar: () => Promise<any>;
  usersByBrand: IUser[];
  fetchUsersByBrand: () => void;
  addSocialMediaContact: (contacts: ISocialMedia[]) => Promise<any>;
  removeSocialMediaContact: (uId: string) => Promise<any>;
}>(undefined);

const bucket = 'inspify-users';
const getAvatarUrl = (id: string) => {
  return `https://inspify-users.s3.ap-southeast-1.amazonaws.com/${id}/avatar.jpg?ts=${new Date().getTime()}`;
};
const HubUserContextContainer = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [users, setUsers] = React.useState<IUser[]>();
  const [usersByBrand, setUsersByBrand] = React.useState<IUser[]>();
  const [editMode, setEditMode] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const [isAdminPage, setIsAdminPage] = React.useState(false);
  const { storeId, user, setUser, brandId, userRoles } =
    React.useContext(HubContext);
  const [changePwdView, setChangePwdView] = React.useState(false);
  const [stores, setStores] = React.useState<IStore[]>([]);
  const dispatch = useDispatch();
  const socialMediaContacts =
    useSelector(
      (state: IMainState) => state?.clientState?.hub?.user?.socialMedia
    ) || [];

  const updateUser = async (identityId: string, _user: IUser) => {
    setUpdating(true);
    if (isOnboardingBrand()) {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        given_name: _user.first_name,
        family_name: _user.last_name
      });
      _user.role = userRoles?.[0]?.name;
    }

    return saveUser(_user)
      .then(({ data }) => {
        if (data) {
          if (user.id === _user.id) setUser(_user);
          dispatch(
            actionAddUserInfo({
              id: _user.id,
              alias: _user.alias,
              email: _user.email,
              firstName: _user.first_name,
              lastName: _user.last_name
            })
          );
          if (usersByBrand?.length > 1) {
            const usersOrder = orderBy(
              [
                ...usersByBrand.filter(
                  (brandUser) => brandUser.id !== _user.id
                ),
                _user
              ],
              [
                (user) => cleanLowerCase(user.first_name),
                (user) => cleanLowerCase(user.last_name)
              ],
              ['asc', 'asc']
            );
            setUsersByBrand(usersOrder);
          }
          setEditMode(false);
          setUpdating(false);
          users &&
            setUsers(
              users.map((user) => (user.id === _user.id ? _user : user))
            );
        }
      })
      .catch((err) => {
        console.log(err.message);
        setUpdating(false);
      });
  };

  const addSocialMediaContact = async (medias: ISocialMedia[]) => {
    const promises = medias.map((media) => exchangeFacebookToken(media));
    return Promise.all(promises).then(async (exchangeds) => {
      const socialMedias: ISocialMedia[] = [
        ...socialMediaContacts,
        ...exchangeds
      ];
      const tokens = mapSocialMediaContacsToStreamTokens(socialMedias);
      const user = await Auth.currentAuthenticatedUser();
      return Auth.updateUserAttributes(user, {
        [STREAM_TOKENS_ATTR]: tokens
      }).then(() => {
        dispatch(
          actionUpdateUserAttr({ id: user.id, socialMedia: socialMedias })
        );
      });
    });
  };

  const removeSocialMediaContact = async (id: string) => {
    const socialMedias = socialMediaContacts.filter((t) => t.id !== id);
    const tokens = mapSocialMediaContacsToStreamTokens(socialMedias);
    const user = await Auth.currentAuthenticatedUser();
    return Auth.updateUserAttributes(user, {
      [STREAM_TOKENS_ATTR]: tokens
    }).then(() => {
      dispatch(
        actionUpdateUserAttr({ id: user.id, socialMedia: socialMedias })
      );
    });
  };

  const fetchUsersByAdmin = () => {
    getListUsers(storeId)
      .then((_users) => {
        setUsers(_users);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchUsersByBrand = () => {
    getListUsersByBrand(brandId)
      .then((_users) => {
        const usersOrder = orderBy(
          _users,
          [
            (user) => cleanLowerCase(user.first_name),
            (user) => cleanLowerCase(user.last_name)
          ],
          ['asc', 'asc']
        );
        _users.forEach((user) => {
          dispatch(
            actionAddUserInfo({
              id: user.id,
              alias: user.alias,
              email: user.email,
              firstName: user.first_name,
              lastName: user.last_name
            })
          );
        });
        setUsersByBrand(usersOrder);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  React.useEffect(() => {
    fetchUsersByBrand();
    getStoresByBrandId(brandId)
      .then((result) => setStores(result))
      .catch((err) => {
        console.log('Error when fetching store by brandId ' + brandId);
        console.log(err);
      });
  }, []);

  const inviteUsers = async (_users: INewInviteUser[]): Promise<any> => {
    try {
      const _inviteUsersRes = await inviteNewUsers(_users);
      const _inviteSuccessfullyUsers: IUser[] = _inviteUsersRes
        .filter((usr) => usr.status === 'SUCCESS')
        ?.map((user) => {
          return {
            id: user.identityId,
            email: user.email,
            statusText: InvitationStatus.INVITATION_PENDING,
            userType: _users?.find((usr) => usr.email === user.email)?.role,
            role:
              'STORIEZ_' + _users?.find((usr) => usr.email === user.email)?.role
          };
        });
      if (_inviteSuccessfullyUsers?.length > 0)
        setUsers([...users, ..._inviteSuccessfullyUsers]);
      return _inviteUsersRes;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const createUser = async (_user: NewUser): Promise<any> => {
    try {
      const { user_name, email } = _user;

      const newUser: ICreateUserPayload = {
        username: user_name,
        storeId: _user.storeId,
        attributes: {
          email: email,
          email_verified: 'true'
        }
      };
      const createdUser = await createNewUser(newUser);
      const id = createdUser?.identityId;
      if (!id) throw new Error('Can not create user');
      const entitlements: IEntitlement[] = [
        {
          entitlementType: 'STORE_ID',
          uuid: _user.storeId
        },
        {
          entitlementType: 'BRAND_ID',
          uuid: brandId
        },
        ...(_user.recordingOption.length > 1
          ? [
              {
                entitlementType: 'RECORDING_VISIBILITY',
                uuid: _user.recordingOption.toUpperCase()
              }
            ]
          : [])
      ];
      const alias = _user.alias || _user.first_name + ' ' + _user.last_name;
      const newInspifyUser: IUser = {
        ..._user,
        alias: alias || 'Sales Advisor',
        id,
        entitlements,
        userType: user.userType === MEET_ADMIN_USER ? MEET_USER : SALES
      };
      setUsers([newInspifyUser, ...users]);
      return saveUser(newInspifyUser);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const deleteUser = async (id: string) => {
    try {
      const response = await deleteUserById(id);
      setUsers(users.filter((user) => user.id !== id));
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };
  const changePassword = async (oldPwd, newPwd): Promise<any> => {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.changePassword(user, oldPwd, newPwd);
  };
  const updateFileUploadProgress = (
    name: string,
    progress: number,
    error?: Error
  ) => {
    dispatch(actionHubSessionUpdateFileUploadProgress(name, progress, error));
  };

  const uploadAvatarTos3 = async (
    file: File,
    fileName: string
  ): Promise<any> => {
    logEvent(DID_UPLOAD_AVATAR, DID_UPLOAD_AVATAR, { identityId: user.id });
    const key = `${user.id}/${fileName}`;
    try {
      await uploadToS3(file, bucket, key, (progressPercentage) => {
        updateFileUploadProgress(fileName, progressPercentage);
      });
      const updatedUser: IUser = {
        ...user,
        avatar_picture: getAvatarUrl(user.id)
      };
      await saveUser(updatedUser);
      setEditMode(false);
      setUser(updatedUser);
    } catch (error) {
      console.error('Uploading file to S3 failed with error', error);
      updateFileUploadProgress(fileName, 0, error);
      return Promise.reject(error);
    }
  };

  const deleteAvatar = async () => {
    logEvent(DID_DELETE_AVATAR, DID_DELETE_AVATAR, { identityId: user.id });
    try {
      const updatedUser: IUser = {
        ...user,
        avatar_picture: ''
      };
      await saveUser(updatedUser);
      setEditMode(false);
      setUser(updatedUser);
    } catch (error) {
      console.error('Delete avatar fail with error', error);
      return Promise.reject(error);
    }
  };
  return (
    <HubUserContext.Provider
      value={{
        editMode,
        setEditMode,
        updateUser,
        updating,
        fetchUsersByAdmin,
        users,
        changePwdView,
        setChangePwdView,
        changePassword,
        createUser,
        inviteUsers,
        stores,
        isAdminPage,
        setIsAdminPage,
        deleteUser,
        uploadAvatarTos3,
        deleteAvatar,
        usersByBrand,
        fetchUsersByBrand,
        removeSocialMediaContact,
        addSocialMediaContact
      }}
    >
      {children}
    </HubUserContext.Provider>
  );
};

export default HubUserContextContainer;
