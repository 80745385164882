import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { AssetLibraryContext } from '.';
import { IAsset } from '../../../interfaces';
import { generateV4UUID } from '../../../utils/identityGenerator';
import FileUpload from '../Common/FileUploader/FileUpload';
import PopupContainer from '../Common/PopupContainer';
import { HubContext } from '../HubContext';
import AssetThumbnail from './AssetThumbnail';
import Mime from 'mime';
import { first, isEmpty, last, max, min, reverse, unionBy } from 'lodash';
import { uploadToS3 } from '../../clientSideServices/session';
import { logClickButton, logEvent } from '../../../analytics';
import {
  DID_CLICK_REMOVE_ASSET_BUTTON,
  HUB_ASSET_LIBRARY_EVENT,
  DID_UPLOAD_ASSET_SUCCESSFUL,
  DID_UPLOAD_ASSET_FAILED,
  DID_CLICK_CONFIRM_UPLOAD_ASSET,
  DID_CLICK_ASSET_THUMBNAIL
} from '../../../utils/constants';
import {
  deleteAsset,
  getAsset,
  getConvertingStatus,
  saveAsset,
  searchAssets
} from '../../clientSideServices/assetLibrary';
import { assetBaseUrl, videoBaseUrl } from '../../../config';
import { removeFileNameExtension } from '../../../utils/file';
import { TRASH_FOLDER, UNCATEGORIZED_FOLDER } from '../../utils/folders';
import hotkeys from 'hotkeys-js';
import Alert from '../Common/Alert';

const bucket = 'inspify-data';
const filePath = 'assets/uploaded';
const rawFilePath = 'assets/uploaded-raw';

export const AssetUploadPopup: React.FC<{ open: boolean }> = ({ open }) => {
  hotkeys.setScope('upload-popup');

  const { brandId, user } = useContext(HubContext);
  const {
    setUploadPopupVisibility,
    changeSearchOptions,
    activeCategory,
    setActiveCategory,
    draggedFiles
  } = useContext(AssetLibraryContext);
  const [files, setFiles] = React.useState<File[]>([]);
  const [confirmDelete, showConfirmDelete] = React.useState(false);
  const [selectedAssets, setSelectedAssets] = React.useState<string[]>([]);
  const [isMultipleSelectionEnabled, setIsMultipleSelectionEnabled] =
    React.useState(false);
  const [multipleSelectTrigger, setMultipleSelectTrigger] = React.useState<
    string | undefined
  >();
  const [uploadStatus, setUploadStatus] = React.useState<{
    [key: string]:
      | undefined
      | 'uploading'
      | 'uploaded'
      | 'converting'
      | 'converted'
      | 'synchronizing'
      | 'finished'
      | 'upload_error'
      | 'converted_error';
  }>({});

  useEffect(() => {
    if (open && !isEmpty(draggedFiles)) {
      setFiles(draggedFiles);
    }
  }, [open, draggedFiles]);

  const uploadAssets = useMemo<(IAsset & { key: string; file: File })[]>(() => {
    return files.map((f) => {
      const objectUrl = URL.createObjectURL(f);
      const id = generateV4UUID();
      const extension = Mime.getExtension(Mime.getType(f.name));
      const isPowerpoint = ['pptx', 'pptm', 'ppt'].includes(extension);
      const path = isPowerpoint ? rawFilePath : filePath;
      const assetPreviewURL = isPowerpoint
        ? `${objectUrl}/preview.jpg`
        : objectUrl;
      const assetType = isPowerpoint ? 'ppt' : Mime.getType(f.name);
      return {
        id: id,
        title: removeFileNameExtension(f.name),
        originalURL: `${objectUrl}/${f.name}`,
        assetPreviewURL,
        assetType,
        sizeKB: f.size,
        key: `${path}/${brandId}/${id}/${id}.${extension}`,
        file: f,
        isPowerpoint
      };
    });
  }, [files]);

  let initCategory = [TRASH_FOLDER, UNCATEGORIZED_FOLDER].includes(
    activeCategory[0]
  )
    ? [UNCATEGORIZED_FOLDER]
    : activeCategory;

  if (isEmpty(initCategory)) {
    initCategory = [UNCATEGORIZED_FOLDER];
  }
  // delete asset if failed to convert
  const deleteErrorAsset = (id) => {
    deleteAsset(id).catch((err) => {
      console.log('failed to delete error asset', err);
    });
  };

  const handleUpload = () => {
    uploadAssets
      .filter(
        (f) =>
          !uploadStatus[f.key] ||
          uploadStatus[f.key] === 'upload_error' ||
          uploadStatus[f.key] === 'converted_error'
      )
      .map((asset) => {
        const { file, key, assetType, sizeKB, id } = asset;
        setUploadStatus((prev) => ({ ...prev, [key]: 'uploading' }));
        if (assetType === 'ppt') {
          return saveRawAsset(asset);
        }
        return uploadToS3(file, bucket, key, undefined, false, file.type)
          .then((data) => {
            const uploadKey = data.key as string;
            logEvent(HUB_ASSET_LIBRARY_EVENT, DID_UPLOAD_ASSET_SUCCESSFUL, {
              fileName: file?.name,
              fileType: file?.type,
              fileSize: file?.size
            });
            setUploadStatus((prev) => ({ ...prev, [key]: 'converting' }));
            const isVideo = file?.type.includes('video');
            const baseCNDUrl = isVideo ? videoBaseUrl : assetBaseUrl;
            const assetData: IAsset = {
              id,
              title: removeFileNameExtension(file.name),
              originalURL: data.Location,
              convertedURL: '',
              assetPreviewURL: '',
              assetType: assetType,
              categories: initCategory,
              brand: brandId,
              visibility: 'brand',
              visibilityScope: [brandId],
              createdBy: user.id,
              createdAt: new Date().toISOString(),
              modifiedBy: '',
              modifiedAt: '',
              activatedAt: '',
              activatedBy: '',
              sizeKB,
              status: 'activated'
            };
            saveAsset(assetData)
              .then(() => {
                getAsset(assetData.id);
                setUploadStatus((prev) => ({ ...prev, [key]: 'converting' }));
                const checkAssetSynchronizingStatus = (done: () => void) => {
                  searchAssets({
                    userId: user?.id,
                    assetIds: [assetData.id]
                  }).then((data) => {
                    if (
                      data?.assets?.find(
                        (asset) =>
                          asset.id == assetData.id &&
                          asset.convertedURL === assetData.convertedURL
                      )
                    ) {
                      setUploadStatus((prev) => ({
                        ...prev,
                        [key]: 'finished'
                      }));
                      done();
                    }
                  });
                };

                const checkConvertingStatusJob = setInterval(() => {
                  getConvertingStatus(brandId, assetData.id).then(
                    async (result) => {
                      const convertedData = result?.[0];

                      if (!isEmpty(convertedData)) {
                        if (
                          !convertedData.metadata.converted ||
                          isEmpty(convertedData.pages)
                        ) {
                          clearInterval(checkConvertingStatusJob);
                          deleteErrorAsset(assetData.id);
                          setUploadStatus((prev) => ({
                            ...prev,
                            [key]: 'converted_error'
                          }));
                          return;
                        }
                        const previewKey = convertedData.pages.find(
                          (k) =>
                            /\.(jpg|jpeg|png|svg)$/.test(k) &&
                            last(k.split('/')).includes('preview')
                        );
                        const convertedKey =
                          convertedData.pages.find((k) =>
                            k.includes(
                              !isVideo ? convertedData?.pages[0] : 'index.m3u8'
                            )
                          ) ||
                          uploadKey
                            .replace('/uploaded/', '/converted/')
                            .replace(file.name, file.name.toLowerCase());
                        assetData.assetPreviewURL = `${baseCNDUrl}/${
                          previewKey || convertedKey
                        }`;
                        assetData.convertedURL = `${baseCNDUrl}/${convertedKey}`;
                        setUploadStatus((prev) => ({
                          ...prev,
                          [key]: 'synchronizing'
                        }));

                        await saveAsset(assetData).catch((e) => {
                          console.log('Failed to update asset:', e);
                        });

                        const checkAssetSynchronizingStatusJob = setInterval(
                          () => {
                            checkAssetSynchronizingStatus(() => {
                              clearInterval(checkAssetSynchronizingStatusJob);
                            });
                          },
                          2000
                        );
                        clearInterval(checkConvertingStatusJob);
                      }
                    }
                  );
                }, 2000);
                // end job
              })
              .catch((e) => {
                console.log('Failed to save asset', e);
                setUploadStatus((prev) => ({ ...prev, [key]: 'upload_error' }));
              });
          })
          .catch((e) => {
            logEvent(HUB_ASSET_LIBRARY_EVENT, DID_UPLOAD_ASSET_FAILED, {
              fileName: file?.name,
              fileType: file?.type,
              fileSize: file?.size
            });
            setUploadStatus((prev) => ({ ...prev, [key]: 'upload_error' }));
            console.log('Failed to upload asset', e);
          });
      });
  };

  const saveRawAsset = ({
    file,
    key,
    assetType,
    sizeKB,
    id
  }: IAsset & { key: string; file: File }) => {
    setUploadStatus((prev) => ({ ...prev, [key]: 'uploading' }));
    return uploadToS3(file, bucket, key, undefined, false, file.type)
      .then((data) => {
        logEvent(HUB_ASSET_LIBRARY_EVENT, DID_UPLOAD_ASSET_SUCCESSFUL, {
          fileName: file?.name,
          fileType: file?.type,
          fileSize: file?.size
        });
        const assetData: IAsset = {
          id,
          title: removeFileNameExtension(file.name),
          originalURL: data.Location,
          convertedURL: '',
          assetPreviewURL: '',
          assetType: assetType,
          categories: initCategory,
          brand: brandId,
          visibility: 'brand',
          visibilityScope: [brandId],
          createdBy: user.id,
          createdAt: new Date().toISOString(),
          modifiedBy: '',
          modifiedAt: '',
          activatedAt: '',
          activatedBy: '',
          sizeKB,
          status: 'activated'
        };
        return saveAsset(assetData)
          .then(() => {
            return 'synchronizing';
          })
          .catch((e) => {
            console.log('Failed to save asset', e);
            return 'upload_error';
          });
      })
      .then((status: 'synchronizing' | 'upload_error') => {
        if (status === 'upload_error') {
          return Promise.resolve(status);
        }
        setUploadStatus((prev) => ({
          ...prev,
          [key]: status
        }));
        return checkAssetSynchronizingStatus(user.id, id);
      })
      .then((status: any) => {
        setUploadStatus((prev) => ({
          ...prev,
          [key]: status
        }));
      })
      .catch((e) => {
        logEvent(HUB_ASSET_LIBRARY_EVENT, DID_UPLOAD_ASSET_FAILED, {
          fileName: file?.name,
          fileType: file?.type,
          fileSize: file?.size
        });
        setUploadStatus((prev) => ({ ...prev, [key]: 'upload_error' }));
        console.log('Failed to upload asset', e);
      });
  };

  const checkAssetSynchronizingStatus = useCallback(
    (userId: string, assetId: string) => {
      const check = (done: (status) => void) => {
        searchAssets({
          userId: userId,
          assetIds: [assetId]
        }).then((data) => {
          if (data?.assets?.find((asset) => asset.id == assetId)) {
            done('finished');
          }
        });
      };
      return new Promise((resolve, _) => {
        const checkAssetSynchronizingStatusJob = setInterval(() => {
          check((status) => {
            clearInterval(checkAssetSynchronizingStatusJob);
            resolve(status);
          });
        }, 2000);
      });
    },
    []
  );

  const hasUnUploadedFiles = uploadAssets.some(
    (asset) => !uploadStatus[asset.key]
  );

  const isUploadFinished = useMemo(() => {
    if (isEmpty(uploadStatus)) {
      return false;
    }

    return Object.values(uploadStatus).every((status) => status === 'finished');
  }, [uploadStatus]);

  const handleSelectAsset = (assetItem: IAsset) => {
    if (!isMultipleSelectionEnabled) {
      return setSelectedAssets([assetItem.id]);
    }
    setSelectedAssets((prev) => {
      if (multipleSelectTrigger === 'Shift') {
        const firstSelectedIndex = isEmpty(selectedAssets)
          ? 0
          : uploadAssets.findIndex((a) => a.id === first(selectedAssets));
        const lastSelectedIndex = uploadAssets?.findIndex(
          (a) => a.id === assetItem.id
        );
        const smallerIndex = min([firstSelectedIndex, lastSelectedIndex]);
        const biggerIndex = max([firstSelectedIndex, lastSelectedIndex]);
        let newSelectedAssetIds = uploadAssets
          ?.slice(smallerIndex, biggerIndex + 1)
          ?.map((a) => a.id);
        if (firstSelectedIndex > lastSelectedIndex || isEmpty(selectedAssets)) {
          newSelectedAssetIds = reverse(newSelectedAssetIds);
        }
        return [...newSelectedAssetIds];
      }
      if (prev.includes(assetItem.id)) {
        return prev.filter((k) => k !== assetItem.id);
      }
      return [...prev, assetItem.id];
    });
  };

  useEffect(() => {
    if (isUploadFinished) {
      if ([TRASH_FOLDER].includes(activeCategory[0])) {
        setActiveCategory(UNCATEGORIZED_FOLDER, true);
      }
      setFiles([]);
      changeSearchOptions({
        sortKey: 'createdat',
        sortOrder: 'desc',
        categories: activeCategory ? initCategory : undefined,
        status: undefined
      });
      setUploadPopupVisibility(false);
    }
  }, [isUploadFinished]);

  useEffect(() => {
    hotkeys('*', { keyup: true, keydown: true, scope: 'upload-popup' }, (e) => {
      if (e.type === 'keydown') {
        if (e.ctrlKey || e.key === 'Meta' || e.key === 'Shift') {
          setIsMultipleSelectionEnabled(true);
          setMultipleSelectTrigger(e.key);
        }
      }
      if (e.type === 'keyup') {
        if (e.key === 'Control' || e.key === 'Meta' || e.key === 'Shift') {
          setIsMultipleSelectionEnabled(false);
          setMultipleSelectTrigger(undefined);
        }
      }
    });
  }, [uploadAssets]);

  useEffect(() => {
    hotkeys('ctrl+a,cmd+a', 'upload-popup', (e) => {
      e.preventDefault();
      setSelectedAssets(uploadAssets.map((a) => a.id));
    });
  }, [uploadAssets]);

  if (!open) {
    return null;
  }

  return (
    <PopupContainer
      maxWidth="1280px"
      maxHeight="calc(100vh - 90px)"
      zIndex={9999}
      className={'asset-upload-popup'}
      onClose={() => {
        setUploadPopupVisibility(false);
        setFiles([]);
        setUploadStatus({});
        setSelectedAssets([]);
        setUploadPopupVisibility(false);
      }}
    >
      <div className="content-main">
        <div className="popup-header d-flex w-100 justify-content-between">
          <h2>Upload Files</h2>
          {!isEmpty(selectedAssets) && (
            <Button
              variant="dark"
              className="rounded-pill d-inline-block"
              disabled={!hasUnUploadedFiles}
              style={{ fontSize: 12, height: 30 }}
              onClick={() => {
                logClickButton(DID_CLICK_REMOVE_ASSET_BUTTON);
                showConfirmDelete(true);
              }}
            >
              Remove
            </Button>
          )}
          <Alert />
        </div>
        <div className="popup-content d-flex flex-column flex-md-row w-100">
          <div className="upload-zone w-100 pt-0 mt-2">
            <FileUpload
              onClose={() => {
                setFiles([]);
              }}
              onUpload={(f) => {
                console.log(f);
              }}
              files={files}
              type="inline"
              multipleUpload={true}
              onDeleteFile={(index) =>
                setFiles(files.filter((_, i) => i !== index))
              }
              controlled
              onUpdateFileList={(files) => {
                if (!isEmpty(draggedFiles)) {
                  setFiles(
                    unionBy(draggedFiles.concat(files), (file) => file.name)
                  );
                  return;
                }
                setFiles(files);
              }}
              rejectDuplicates={true}
              hideCloseButton={true}
              showUploadList={false}
              acceptTypes=".jpeg, .jpg, .png, .mp4, .mov, .qt, .svg, .ttf,.woff,.woff2, .pptx, .pptm, .ppt"
            />
          </div>
          <div
            className="upload-files w-100 pl-2"
            onClick={(e) => {
              setSelectedAssets([]);
              e.stopPropagation();
            }}
          >
            <div className="d-flex flex-wrap">
              {uploadAssets.map((assetItem) => {
                let uploadError = '';
                if (uploadStatus[assetItem.key] === 'converted_error') {
                  uploadError = 'Failed to convert the asset.';
                }
                if (uploadStatus[assetItem.key] === 'upload_error') {
                  uploadError = 'Failed to upload the asset.';
                }
                return (
                  <AssetThumbnail
                    key={assetItem.id}
                    item={assetItem}
                    onClick={() => {
                      if (!hasUnUploadedFiles) return;
                      logEvent(
                        HUB_ASSET_LIBRARY_EVENT,
                        DID_CLICK_ASSET_THUMBNAIL,
                        {
                          item: {
                            id: assetItem.id,
                            originalURL: assetItem.originalURL,
                            assetType: assetItem.assetType
                          }
                        }
                      );
                      handleSelectAsset(assetItem);
                    }}
                    local
                    selected={selectedAssets.includes(assetItem.id)}
                    status={uploadStatus[assetItem.key]}
                    uploadedError={uploadError}
                    viewable={false}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div
          className="popup-footer w-100 d-flex justify-content-end"
          style={{ paddingBottom: 0, paddingRight: 0 }}
          onClick={() => {
            if (isEmpty(selectedAssets)) {
              return;
            }
            setSelectedAssets([]);
          }}
        >
          {!isEmpty(selectedAssets) ? (
            <span className="text-count-selected my-auto">
              {selectedAssets.length} Files Selected
            </span>
          ) : (
            <div>
              <Button
                variant="outline-dark"
                className="mr-2 rounded-pill d-inline-block"
                style={{
                  fontSize: 12,
                  fontWeight: 600
                }}
                disabled={!hasUnUploadedFiles && files.length !== 0}
                onClick={() => {
                  setUploadPopupVisibility(false);
                  setFiles([]);
                  setSelectedAssets([]);
                  setUploadPopupVisibility(false);
                }}
              >
                Cancel
              </Button>

              <Button
                variant="dark"
                className="rounded-pill d-inline-block btn-confirm-upload"
                onClick={() => {
                  logClickButton(DID_CLICK_CONFIRM_UPLOAD_ASSET);
                  handleUpload();
                }}
                disabled={!hasUnUploadedFiles}
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  background: !hasUnUploadedFiles && '#E6E6E6'
                }}
              >
                Confirm
              </Button>
            </div>
          )}
        </div>
      </div>

      {confirmDelete && (
        <PopupContainer maxWidth="300px">
          <div className={`d-flex flex-column popup-confirm-delete`}>
            <div className="d-flex mb-3 w-100 justify-content-center">
              <span className="text-question">
                Are you sure you want to remove your selected uploads?
              </span>
            </div>
            <div className="d-flex mt-2 justify-content-between w-100 px-4">
              <Button
                className="rounded-pill"
                variant="outline-dark"
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  height: 30,
                  width: 'calc((100% - 10px) / 2)'
                }}
                onClick={() => {
                  showConfirmDelete(false);
                  setSelectedAssets([]);
                }}
              >
                Cancel
              </Button>
              <Button
                className="rounded-pill"
                variant="dark"
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  height: 30,
                  width: 'calc((100% - 10px) / 2)'
                }}
                onClick={() => {
                  const selectedAssetObjects = uploadAssets.filter((asset) =>
                    selectedAssets.includes(asset.id)
                  );
                  setFiles((prev) => {
                    return prev.filter(
                      (f) =>
                        !selectedAssetObjects.find((asset) => asset.file === f)
                    );
                  });
                  setSelectedAssets([]);
                  showConfirmDelete(false);
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </PopupContainer>
      )}
      <style jsx>{`
        :global(.content-wrapper) {
          z-index: unset;
        }
        .popup-confirm-delete {
          padding: 25px 20px;
        }
        .popup-confirm-delete-preview {
          width: 300px;
          padding: 40px 35px;
          background: #fff;
          margin: auto;
          border-radius: 10px;
        }
        .text-question {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
        }
        .popup-header {
          border-bottom: 1px solid #ccc;
          padding: 1rem;
        }
        .popup-footer {
          border-top: 1px solid #ccc;
          padding: 1rem;
          min-height: 50px;
        }
        .upload-zone :global(.info .small) {
          display: none !important;
        }
        .popup-header h2 {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 0;
        }
        .popup-content {
          height: calc(100vh - 250px);
          padding: 0;
        }
        .popup-content .upload-files {
          overflow-y: auto;
        }
        :global(.PopupContainer .content-container) {
          flex-direction: column;
        }
        .text-count-selected {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          text-align: right;
          color: #000000;
        }

        .content-main {
          height: auto;
          max-height: calc(100vh - 90px);
          width: 100%;
          overflow: auto;
        }

        @media screen and (min-width: 768px) {
          .popup-content .upload-zone {
            width: 220px !important;
          }
          .popup-content .upload-files {
            width: calc(100% - 220px);
            padding-left: 0px;
          }
          .popup-content {
            padding: 1rem 0rem 1rem 1rem;
          }
        }
        @media screen and (min-height: 1000px) {
          .popup-content {
            max-height: 65vh;
          }
        }
        @media screen and (max-height: 530px) and (max-width: 768px) {
          .popup-content {
            height: auto;
          }
        }
      `}</style>
    </PopupContainer>
  );
};
